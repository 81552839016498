import React , { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class ListTeachingRequests extends Component {
    state = {
        alertError: false,
        alertSuccess: false,
        openCertificationModal: null
    }

    render() {
        // console.log(this.props)
        
        return (
            <React.Fragment>
            <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
            <div className="resent-homework-wrapper">
                <ul className="resent-homework list-unstyled" style={{borderBottom: "black 1px solid"}}>
                    <div>
                        <span className="hw-type">#</span>
                        <span className="hw-type">Name</span>
                        <span className="hw-st-data">
                            <span className="st-name">Email</span>
                        </span>
                        <span className="st-name">Teach</span>
                    </div>
                </ul>
                <ul className="resent-homework list-unstyled">
                    {this.props.teachingRequeasts
                        ? this.props.teachingRequeasts.map(req =>
                            <Link key={req.uid} to={`/admin/teaching-requests/${req.uid}`}>
                                <span className="hw-type">{this.props.teachingRequeasts.indexOf(req)+1}</span>
                                <span className="hw-type">{req.displayName}</span>
                                <span className="hw-st-data">
                                    <span className="st-name">{req.email}</span>
                                </span>
                                <span className="st-name">{req.learn.map(l => <li key={l}>{l}</li>)}</span>
                            </Link>
                        ) : null
                    }
                </ul>
            </div>
            </React.Fragment>
        )
    }
}

function mapDispatchToProps ({teachingRequeasts}) {
	return {
		teachingRequeasts
	};
}

export default connect(mapDispatchToProps) (ListTeachingRequests);