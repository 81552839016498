import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { getOneHomework, getHomeworkQuestions, getMyHomeworkAnswers } from '../utils/firebase';

export const GET_HOMEWORK = 'GET_HOMEWORK';

function receiveHomework (homework, error=null) {
    return {
        type: GET_HOMEWORK,
        homework,
        error
    }
}

export function handleReceiveHomework (classId,homeworkId) {
    return (dispatch) => {
        dispatch(showLoading())
        return getOneHomework(classId,homeworkId)
        .then((hw) => {
            if (hw.exists) {
                let home = {id: hw.id, ...hw.data(), questions:[]}
                getHomeworkQuestions(classId,hw.id)
                .then((questions) => {
                    let questionsArray = []
                    questions.forEach(qu => {
                        questionsArray.push({id: qu.id, ...qu.data()})
                    })
                    home.questions = questionsArray
                })
                getMyHomeworkAnswers(classId,hw.id)
                .then((ans) => {
                    if (ans.exists) {
                        home.myAnswers = {...ans.data()}
                    } else {
                        home.myAnswers = {}
                    }
                })
                dispatch(hideLoading())
                return dispatch(receiveHomework(home,null))
            } else {
                dispatch(hideLoading())
                return dispatch(receiveHomework(null))
            }
        }).catch((error) => {
            dispatch(hideLoading())
            return dispatch(receiveHomework(null,error))
        })
    }
}