// External dependencies
import React from 'react';

// import Header from './../../components/Header';
import FindTeacherPage from './../../components/FindTeacherPage';

const FindTeacher = () => (
    <FindTeacherPage />
);

export default FindTeacher;