import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Modal, Alert, Form } from 'react-bootstrap';

import { handleSubmitUserToHonorBoard, handleSubmitDeleteUserFromHonorBoard } from '../../store/actionCreators/honorBoard';
import { handleSubmitUserToOurTeachers, handleSubmitDeleteUserFromOurTeachers } from '../../store/actionCreators/ourTeachers';

class HomePage extends Component {
    state = {
        error: "",
        alertError: false,
        openHonorModal: false,
        openOurTeachersModal: false,
    }

    setOpenHonorModal = value => this.setState({openHonorModal: value})
    setOpenOurTeachersModal = value => this.setState({openOurTeachersModal: value})

    render() {
        // console.log(this.props)
        return (       
            <>
            <div className="row mt-2">
                <div className="col-md-6 mt-2">
                    <Card>
                        <h2 className="m-1">
                            <button className="btn btn-block text-left" onClick={() => this.setOpenHonorModal(true)}>
                                <h4 className="text-center">Honor Board</h4>
                            </button>
                        </h2>
                    </Card>
                </div>
                <div className="col-md-6 mt-2">
                    <Card>
                        <h2 className="m-1">
                            <button className="btn btn-block text-left" onClick={() => this.setOpenOurTeachersModal(true)}>
                                <h4 className="text-center">Our Teachers</h4>
                            </button>
                        </h2>
                    </Card>
                </div>

                <HonorModal
                    honorModal={this.state.openHonorModal}
                    closeHonorModal={() => this.setOpenHonorModal(false)}
                    dispatch={this.props.dispatch}
                    honorBoardUsers={this.props.honorBoardUsers}
                    users={this.props.users}
                /> 
                <OurTeachersModal
                    ourTeachersModal={this.state.openOurTeachersModal}
                    closeOurTeachersModal={() => this.setOpenOurTeachersModal(false)}
                    dispatch={this.props.dispatch}
                    ourTeachers={this.props.ourTeachers}
                    users={this.props.users}
                />
            </div>
            </>
        )
    }
}
function mapStateToProps ({ users, honorBoardUsers, ourTeachers }) {
	return {
		users, honorBoardUsers, ourTeachers
	}
}

export default connect(mapStateToProps)(HomePage);

class HonorModal extends Component {
    state = {
        error: '',
        alertError: false,
        input: "",
        points: "",
        students: [],
        uid: ""
    }

    changePoints = e => {
        this.setState({ points: Number(e.target.value) })
    }
  
    handleSearch = (e) => {
        this.setState(() => ({
            alertError: false,
            error: "",
        }))
        let { students } = this.state;
        let input = e.target.value.toLowerCase();
        this.setState({ input:  e.target.value.toLowerCase()});
        const filteredData = this.props.users.filter(value => {
            const nameMatches = value.email.toLowerCase().includes(input);
            const daysMatches = value.displayName && value.displayName.toString().includes(input);
        
            return nameMatches || daysMatches;
        });
        // console.log(filteredData);
        if (input === "") {
            students = []
        } else {
            students = filteredData;
            this.renderSearchList()
        }
        this.setState({ students });
    }

    renderSearchList = () => {
        return this.state.students.map(stu => {
            const filter = this.props.honorBoardUsers.find(i => i.uid === stu.uid)
            
            if (filter) {
                return null
            } else {
                return (
                    <div key={stu.uid} className="upc-st-box" onClick={() => this.chooseStudent(stu.uid, stu.displayName, stu.email)}>
                        <div className="upc-st-info">
                            <div className="st-vector">
                                <img className="img-fluid" src={stu.photoURL} alt="Student Vector"/>
                            </div>
                            <div className="upc-details">
                                <h5 className="mb-0">
                                    {stu.displayName}<br/>
                                    <small>{stu.email}</small>
                                </h5>
                            </div>
                        </div>
                    </div>
                )
            }
        })
    }

    chooseStudent = (uid,displayName,email) => {
        let { input, students } = this.state;
        students = []
        input = `${displayName} (${email})`;
        this.setState({ input,students, uid });
    }

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeHonorModal()
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState(() => ({
            alertError: false,
            error: "",
        }))
        const { uid, points } = this.state;
        this.props.dispatch(handleSubmitUserToHonorBoard(uid,points))
        .then((result) => {
            if(result) {
                if (result.error===null) {
                    let { input, students } = this.state;
                    students = []
                    input = "";
                    this.setState({ input,students });
                } else {
                    this.setState(() => ({
                        error: result.error.message,
                        alertError: true,
                    }))
                }
            } else {
                let { input, students } = this.state;
                students = []
                input = "";
                this.setState({ input,students });
            }
            
        })
    }

    handleDeleteStudent = uid => {
        this.setState(() => ({
            error: "",
            alertError: false,
        }))
        this.props.dispatch(handleSubmitDeleteUserFromHonorBoard(uid))
        .then((result) => {
            if (result.error===null) {
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }
  
    render() {
        // console.log(this.state)
        const { honorModal } = this.props;
        return (
            <Modal
                show={honorModal}
                onHide={this.closeModal}
                className="signup"
                size="lg"
                aria-labelledby="honor-modal"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Honor Board Students
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="upcoming-classes">
                    <Form className="" onSubmit={this.handleSubmit}>
                        <div className="input-group mt-2">
                            <Form.Control type="text" className="form-control" value={this.state.input || ""} name="search" placeholder="Search users by Email or Name to add to this class" aria-label="Search" aria-describedby="basic-addon2" onChange={this.handleSearch} required />
                            <Form.Group className="input-group-append">
                                <button type="submit" className="input-group-text">Add</button>
                            </Form.Group>
                            <div className="list">
                                {this.renderSearchList()}
                            </div>
                        </div>
                        <Form.Group controlId="points">
                            <Form.Control style={{border: "1px solid #ced4da"}} type="number" name="points" value={this.state.points} placeholder="Points" onChange={this.changePoints} required />
                        </Form.Group>
                    </Form>
                    {this.props.honorBoardUsers !== null && this.props.honorBoardUsers.map(stu => 
                        <div key={stu.uid} className="upc-st-box">
                            <div className="upc-st-info">
                                <div className="st-vector">
                                    <img className="img-fluid" src={stu.photoURL} alt="Student Vector"/>
                                </div>
                                <div className="upc-details">
                                    <h5 className="mb-0">
                                        {stu.displayName}<br/>
                                        <small>(Points: {stu.points})</small>
                                    </h5>
                                </div>
                            </div>
                            <div className="upc-actions">
                                <Button variant="danger" size="sm" className="" onClick={() => this.handleDeleteStudent(stu.uid)}>Delete Student</Button>
                            </div>
                        </div>
                    )} <hr/>
                    <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

class OurTeachersModal extends Component {
    state = {
        error: '',
        alertError: false,
        input: "",
        teachers: [],
        uid: ""
    }
  
    handleSearch = (e) => {
        this.setState(() => ({
            alertError: false,
            error: "",
        }))
        let { teachers } = this.state;
        let input = e.target.value.toLowerCase();
        this.setState({ input:  e.target.value.toLowerCase()});
        const filteredData = this.props.users.filter(value => {
            const nameMatches = value.email.toLowerCase().includes(input);
            const daysMatches = value.displayName && value.displayName.toString().includes(input);
        
            return nameMatches || daysMatches;
        });
        // console.log(filteredData);
        if (input === "") {
            teachers = []
        } else {
            teachers = filteredData;
            this.renderSearchList()
        }
        this.setState({ teachers });
    }

    renderSearchList = () => {
        return this.state.teachers.map(stu => {
            const filter = this.props.ourTeachers.find(i => i.uid === stu.uid)
            
            if (filter) {
                return null
            } else {
                return (
                    <div key={stu.uid} className="upc-st-box" onClick={() => this.chooseTeacher(stu.uid, stu.displayName, stu.email)}>
                        <div className="upc-st-info">
                            <div className="st-vector">
                                <img className="img-fluid" src={stu.photoURL} alt="Teacher Vector"/>
                            </div>
                            <div className="upc-details">
                                <h5 className="mb-0">
                                    {stu.displayName}<br/>
                                    <small>{stu.email}</small>
                                </h5>
                            </div>
                        </div>
                    </div>
                )
            }
        })
    }

    chooseTeacher = (uid,displayName,email) => {
        let { input, teachers } = this.state;
        teachers = []
        input = `${displayName} (${email})`;
        this.setState({ input,teachers, uid });
    }

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeOurTeachersModal()
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState(() => ({
            alertError: false,
            error: "",
        }))
        const uid = this.state.uid
        this.props.dispatch(handleSubmitUserToOurTeachers(uid))
        .then((result) => {
            if(result) {
                if (result.error===null) {
                    let { input, teachers } = this.state;
                    teachers = []
                    input = "";
                    this.setState({ input,teachers });
                } else {
                    this.setState(() => ({
                        error: result.error.message,
                        alertError: true,
                    }))
                }
            } else {
                let { input, teachers } = this.state;
                teachers = []
                input = "";
                this.setState({ input,teachers });
            }
            
        })
    }

    handleDeleteTeacher = uid => {
        this.setState(() => ({
            error: "",
            alertError: false,
        }))
        this.props.dispatch(handleSubmitDeleteUserFromOurTeachers(uid))
        .then((result) => {
            if (result.error===null) {
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }
  
    render() {
        // console.log(this.state)
        const { ourTeachersModal } = this.props;
        return (
            <Modal
                show={ourTeachersModal}
                onHide={this.closeModal}
                className="signup"
                size="lg"
                aria-labelledby="our-teachers-modal"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Our Teachers
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="upcoming-classes">
                    <Form className="input-group mt-2" onSubmit={this.handleSubmit}>
                        <Form.Control type="text" className="form-control" value={this.state.input || ""} name="search" placeholder="Search users by Email or Name to add to this class" aria-label="Search" aria-describedby="basic-addon2" onChange={this.handleSearch} />
                        <Form.Group className="input-group-append">
                            <button type="submit" className="input-group-text">Add</button>
                        </Form.Group>
                        <div className="list">
                            {this.renderSearchList()}
                        </div>
                    </Form>
                    {this.props.ourTeachers !== null && this.props.ourTeachers.map(stu => 
                        <div key={stu.uid} className="upc-st-box">
                            <div className="upc-st-info">
                                <div className="st-vector">
                                    <img className="img-fluid" src={stu.photoURL} alt="Teacher Vector"/>
                                </div>
                                <div className="upc-details">
                                    <h5 className="mb-0">
                                        {stu.displayName}
                                    </h5>
                                </div>
                            </div>
                            <div className="upc-actions">
                                <Button variant="danger" size="sm" className="" onClick={() => this.handleDeleteTeacher(stu.uid)}>Delete Teacher</Button>
                            </div>
                        </div>
                    )} <hr/>
                    <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
