import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import Routes from './routes';
import configureStore from './store';
import i18n from './i18n';
import './App.scss';

// Configure redux store
const { store } = configureStore();

const App = () => {
	return (
		<Provider store={store}>
			<I18nextProvider i18n={i18n}>
				<BrowserRouter>
					<Routes />
				</BrowserRouter>
			</I18nextProvider>
		</Provider>
		
	);
}

export default App;