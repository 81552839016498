import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const PricingPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${t('header.Pricing')} - ${t('home.Hafs Quran')}`;
        // getAllArticles();
    }, [t]);
        // console.log(this.state)
    return (
        <div className="pricing">
            <div className="main-hafs-header pricing-header">
                <div className="hafs-header-overlay">
                    <div className="hafs-header-content">
                        <h1>Hafs pricing plans</h1>
                        <p>Select  from our plans what reasonable for you</p>
                    </div>
                </div>
            </div>
            <div className="pricing-table-wrapper">
                <div className="container-xxl d-flex align-items-center justify-content-center">
                    <div className="row w-100 pricing-table-details">
                        <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl plan-col">
                            <div className="plan-price">
                                <p><span>A</span></p>
                            </div>
                            <div className="plan-cells">
                                <div className="plan-cell">
                                    <ul className="plan-col-price">
                                        <li className="colored">Certified Teachers</li>
                                        <li className="colored">Native Arab Teachers</li>
                                        <li className="colored">High-Quality Video Streaming</li>
                                        <li className="colored">Reschedule Lessons</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="subscribe-plan">
                                <a className="main-btn border-btn-primary" href="mailto:hafsquraqy@gmail.com">Subscribe</a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl plan-col">
                            <div className="plan-price">
                                <p className="yellow-txt"><span>B</span></p>
                            </div>
                            <div className="plan-cells">
                                <div className="plan-cell">
                                    <ul className="plan-col-price">
                                        <li className="colored">Certified Teachers</li>
                                        <li className="colored">Native Arab Teachers</li>
                                        <li className="colored">High-Quality Video Streaming</li>
                                        <li className="colored">1-on-1 Lessons</li>
                                        <li className="colored">Reschedule Lessons</li>
                                        <li className="colored">Teacher with Ijazaah</li>
                                        <li className="colored">Access to Electronic Material</li>
                                        <li className="colored">Homework Assignments</li>
                                        <li className="colored">Video/Audio Recordings</li>
                                        <li className="colored">Cancel Lessons* 25%</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="subscribe-plan">
                                <a className="main-btn main-btn-primary" href="mailto:hafsquraqy@gmail.com">Subscribe</a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl plan-col">
                            <div className="plan-price">
                                <p className="blue-txt"><span>C</span></p>
                            </div>
                            <div className="plan-cells">
                                <div className="plan-cell">
                                    <ul className="plan-col-price">
                                        <li className="colored">Certified Teachers</li>
                                        <li className="colored">Native Arab Teachers</li>
                                        <li className="colored">High-Quality Video Streaming</li>
                                        <li className="colored">1-on-1 Lessons</li>
                                        <li className="colored">Reschedule Lessons</li>
                                        <li className="colored">Teacher with Ijazaah</li>
                                        <li className="colored">Access to Electronic Material</li>
                                        <li className="colored">Homework Assignments</li>
                                        <li className="colored">Video/Audio Recordings</li>
                                        <li className="colored">Academic Advisor</li>
                                        <li className="colored">15% Family Discount</li>
                                        <li className="colored">Cancel Lessons* 35%</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="subscribe-plan">
                                <a className="main-btn border-btn-primary" href="mailto:hafsquraqy@gmail.com">Subscribe</a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl plan-col">
                            <div className="plan-price">
                                <p className="yellow-txt"><span>D</span></p>
                            </div>
                            <div className="plan-cells">
                                <div className="plan-cell">
                                    <div className="plan-price">
                                        <p><span>Customize your package as you wish</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="subscribe-plan">
                                <a className="main-btn border-btn-primary" href="mailto:hafsquraqy@gmail.com">Subscribe</a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl plan-col">
                            <div className="plan-price">
                                <p className="blue-txt"><span>E</span></p>
                            </div>
                            <div className="plan-cells">
                                <div className="plan-cell">
                                    <div className="plan-price">
                                        <p><span>Scholarship students</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="subscribe-plan">
                                <a className="main-btn border-btn-primary" href="mailto:hafsquraqy@gmail.com">Subscribe</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingPage;