import React from 'react';
import { useTranslation } from 'react-i18next';
import ArticlesContainer from './ArticlesContainer';

const ArticlesPage = () => {
    const { t } = useTranslation();

    return (
        <div className="articles__wrapper">
            <div className="main-hafs-header articles-header">
                <div className="hafs-header-overlay">
                    <div className="hafs-header-content">
                        <h1>{t('header.Articles')}</h1>
                    </div>
                </div>
            </div>
            <ArticlesContainer />
        </div>  
    );
}

export default ArticlesPage;