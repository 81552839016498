import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getUserByUid, addUserToHonorBoard, deleteUserFromHonorBoard, getHonorBoardUsers } from '../utils/firebase';

export const GET_HONOR_BOARD_USERS = 'GET_HONOR_BOARD_USERS';
export const ADD_USER_TO_HONOR_BOARD = 'ADD_USER_TO_HONOR_BOARD';
export const DELETE_USER_FROM_HONOR_BOARD = 'DELETE_USER_FROM_HONOR_BOARD';

function getHonorBoardStudents (users, error=null) {
    return {
        type: GET_HONOR_BOARD_USERS,
        users,
        error
    }
}

export function handleGetHonorBoardStudents() {
    return (dispatch) => {
        dispatch(showLoading())
        return getHonorBoardUsers()
        .then(result => {
            dispatch(hideLoading())
            return dispatch(getHonorBoardStudents(result))
        })
    } 
}

function submitUserToHonorBoard(user, error=null) {
	return {
        type: ADD_USER_TO_HONOR_BOARD,
        user,
        error
	};
}

export function handleSubmitUserToHonorBoard(uid,points) {
    return (dispatch) => {
        dispatch(showLoading())
        return addUserToHonorBoard(uid,points)
        .then(() => {
            getUserByUid(uid).then(doc => {
                dispatch(hideLoading())
                return dispatch(submitUserToHonorBoard(doc.data()))
            })
        }).catch((error) => {
            console.error("Error adding document: ", error);
            dispatch(hideLoading())
            return dispatch(submitUserToHonorBoard(null,error))
        })
    } 
}

function submitDeleteUserFromHonorBoard(uid, error=null) {
	return {
        type: DELETE_USER_FROM_HONOR_BOARD,
        uid,
        error
	};
}

export function handleSubmitDeleteUserFromHonorBoard(uid) {
    return (dispatch) => {
        dispatch(showLoading())
        return deleteUserFromHonorBoard(uid)
        .then(() => {
            dispatch(hideLoading())
            return dispatch(submitDeleteUserFromHonorBoard(uid))
        }).catch((error) => {
            dispatch(hideLoading())
            return dispatch(submitDeleteUserFromHonorBoard(null,error))
        })
    } 
}