import React, { Component } from 'react';
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import HomeWorkRecent from "../HomeWorkRecent";
import HomeWorkSinglePage from "../HomeWorkSinglePage";
import HomeWorkSinglePageStudent from "../HomeWorkSinglePage/HomeWorkSinglePageStudent"

class HomeWorkPage extends Component {
    
    render() {
        return (
            <div className="dashboard-content-page">
                <Switch>
                    <Route path='/dashboard/homework/home' component={HomeWorkRecent} />
                    {/* <Route path='/dashboard/homework/:type' component={HomeWorkTypePage} /> */}
                    {this.props.authedUser && this.props.authedUser!==null && this.props.authedUser.state==="teacher"
                        ? <React.Fragment>
                                {/* <Route path='/dashboard/homework/new/:classId' component={HomeWorkNewPage} /> */}

                                <Route path='/dashboard/homework/:classId/:homeworkId' component={HomeWorkSinglePage} />
                            </React.Fragment>
                        : <Route path='/dashboard/homework/:classId/:homeworkId' component={HomeWorkSinglePageStudent} />
                    }
                    <Redirect from="/dashboard/homework" to="/dashboard/homework/home"></Redirect>
                </Switch>
            </div>
        )
    }
}

function mapStateToProps ({ authedUser,classes }) {
	return {
        authedUser,
        classes
	}
}

export default connect(mapStateToProps)(HomeWorkPage);