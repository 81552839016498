import React from 'react';
import { useTranslation } from "react-i18next";

const TeamCard = props => {
    const { displayName, gender, photoURL, learn, timeZone } = props.teacher;
    const { t } = useTranslation();
    const typeTitle = type => {
        switch (type) {
            case "tajweed" :
                return "Tajweed"
            case "memorization" :
                return "Quran memorization"
            case "arabic" :
                return "Arabic Language"
            case "pronunciation" :
                return "Quran correct pronunciation"
            default :
                return ""
        }
    }

    return (
        <div className="team__card flex-column">
            <div className="team__card--image">
                <img src={photoURL} alt="user avatar"/>
            </div>
            <div className="team__card--text">
                <span className="team__card--name">{displayName}</span>
                <span className="team__card--gender"> - {gender==="male" ? "Male" : "Female"}</span>
                {/* <p className="team__card--description">
                    * Assalamu Alikum. I'm a professional Quran teacher. I have been teaching Quran since my student life. I can teach Recitation, Hifz, Tajweed and Arabic Language
                </p> */}
                <ul className="team__card--list">
                    <span className="team__card--title">{t('home.I Can Teach')}: </span>
                    {learn.map(l => 
                        <li key={l}>{typeTitle(l)}</li>
                    )}
                </ul>
                {/* <ul className="team__card--list">
                    <span className="team__card--title">Languages: </span>
                    <li>Arabic</li>
                    <li>English</li>
                    <li>Hindi</li>
                </ul> */}
                {/* <div className="team__card--group">
                    <span className="team__card--title">Hourly Rate: </span>
                    <span className="team__card--detail">10.00$</span>
                </div>
                <div className="team__card--group">
                    <span className="team__card--title">Conducted: </span>
                    <span className="team__card--detail">3350 Sessions</span>
                </div>
                <div className="team__card--group">
                    <span className="team__card--title">Last online: </span>
                    <span className="team__card--detail">1 day ago</span>
                </div>
                <div className="team__card--group">
                    <span className="team__card--title">Taught: </span>
                    <span className="team__card--detail">20 Students</span>
                </div> */}
                {timeZone &&
                    <div className="team__card--group">
                        <span className="team__card--title">{t('Time zone')}: </span>
                        <span className="team__card--detail">{timeZone}</span>
                    </div>
                }
                
            </div>
        </div>
    )
}

export default TeamCard;