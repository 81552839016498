import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Component from './Component';


const Signup = props => {
    const { t } = useTranslation();
    const { signupModal, closeSignupModal } = props;
    
    return (
        <Modal
            show={signupModal}
            onHide={() => {
                closeSignupModal();
            }}
            aria-labelledby="signup-modal"
            className="signup"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
                {t('auth.Sign up as student')}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Component closeSignupModal={closeSignupModal} />
            </Modal.Body>
        </Modal>
    )
}

export default Signup;