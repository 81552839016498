// External dependencies
import React from 'react';

// import Header from './../../components/Header';
import GamesPage from './../../components/GamesPage';

const Games = () => (
    <GamesPage />
);

export default Games;