import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import HomeWorkTypePage from "../HomeWorkTypePage";


const HomeWorkSinglePage = props => {
    const { t } = useTranslation();
    const homework = props.homework
    console.log(props)
    return (
        <React.Fragment>
            <div className="dashboard-content-page">
                <div className="dsh-head-with-btn">
                    <h2>{homework && homework.title}</h2>
                    {/* <Nav className="dsh-head-links">
                        <Nav.Link href="#" className="main-btn main-btn-primary">Assign to</Nav.Link>
                        <Link to="/dashboard/homework/new" className="main-btn border-btn-primary">Edit this home work</Link>
                    </Nav> */}
                </div>
                <div className="dsh-body">
                    <div className="hwq-items">
                        <div className="hwq-item">
                            {homework && homework.questions.map(qu =>
                                <React.Fragment key={qu.id}>
                                    <p className="hwq-title">{qu.question}</p>
                                    {qu.image !== "" && <img className="hwq-img" src={qu.image} alt="Question" />}
                                    <ul className="hwq-answers list-unstyled">
                                        {qu.answers.map(ans => 
                                            <li key={ans}>{ans}</li>
                                        )}
                                    </ul>
                                    <p className="hwq-correct-answer">{t('dashboard.Right answer')}:  {qu.answers[qu.rightAnswer]}</p>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function mapStateToProps ({ classes }, props) {
	const homework = classes!==null && classes.find(cls => cls.id===props.match.params.classId).homeworks.find(hw => hw.id===props.match.params.homeworkId)
    return {
        homework,
        // homework
	}
}

export default connect(mapStateToProps)(HomeWorkSinglePage);