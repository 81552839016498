import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy';

import { handleDeleteVideo } from '../../../store/actionCreators/videos';

class ListVideos extends Component {
    state = {
        error: "",
        alertError: false,
        openDeleteModal: null
    }

    setOpenDeleteModal = value => this.setState({openDeleteModal: value})

    render() {
        // console.log(this.props)
        return (
            <div className="tab-video">
                <Link to="/admin/videos/new" className="main-btn btn--primary mb-2">Add New Video</Link>
                <div className="row">
                    {this.props.videos!==null && this.props.videos.map(video => (
                        <div key={video.id} className="col-lg-4">
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        <strong>Delete</strong>
                                    </Tooltip>
                                }
                            >
                                <i className="delete-article far fa-times-circle" style={{background: "#fff", top: "10px"}} title="Delete this article" onClick={() => this.setOpenDeleteModal(video.id)}></i>
                            </OverlayTrigger>
                            <div className="video__card m-2">
                                <ReactPlayer url={video.src} controls width="100%" height="100%" />
                                <DeleteModal
                                    video={video}
                                    deleteModal={this.state.openDeleteModal===video.id}
                                    closeDeleteModal={() => this.setOpenDeleteModal(null)}
                                    dispatch={this.props.dispatch}
                                /> 
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

function mapStateToProps ({ videos }) {
    return {
        videos
    }
}
    
export default connect(mapStateToProps)(ListVideos);

class DeleteModal extends Component {
    state = {
        error: '',
        alertError: false
    }
  
    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeDeleteModal()
    }

    handleDelete = id => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.dispatch(handleDeleteVideo(id))
        .then((result) => {
            if (result.error===null) {
                this.props.closeDeleteModal()
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }
  
    render() {
        // console.log(this.props)
        const { deleteModal, video } = this.props;
        return (
            <Modal
                show={deleteModal}
                onHide={this.closeModal}
                aria-labelledby="delete-modal"
                className="signup"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">Delete video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4><Alert variant="warning">Are you sure you want to delete this video?</Alert></h4>
                    <ReactPlayer url={video.src} controls width="100%" height="100%" />
                    <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Cancel</Button>
                    <Button variant="danger" onClick={() => this.handleDelete(video.id)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
