import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {Form, Nav, Alert} from "react-bootstrap";
import { Link } from "react-router-dom";
import { handleSubmitNewHomework } from '../../../../store/actionCreators/classes';

class HomeWorkNewPage extends Component {
    state = {
        title: "",
        questions: [
            {
                question: "",
                image: "",
                imageD: "",
                0: "",
                1: "",
                2: "",
                3: "",
                rightAnswer:""
            }
        ],
        alertError: false,
        error:""
    }

    handleChangeTitle = (e) => {
        this.setState({title: e.target.value})
    }

    handleChange = (e,ww) => {
        let questions = this.state.questions;
        questions[ww][e.target.name] = e.target.value
        this.setState({questions})
    }

    onImageChange = (event,ww) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            let questions = this.state.questions
            questions[ww].image = event.target.files[0]
            reader.onload = (e) => {
                questions[ww].imageD= e.target.result;
                
                this.setState({questions})
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    addQuestion = () => {
        let questions = this.state.questions;
        questions.push({
            question: "",
            0: "",
            1: "",
            2: "",
            3: "",
            rightAnswer:"",
            image: "",
            imageD: ""
        })
        this.setState({questions})
    }

    deleteQuestion = (index) => {
        let questions = this.state.questions;
        if (questions.length > 1) {
            questions.splice(index, 1);
            this.setState({questions})
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            alertError: false,
            error: ""
        })
       this.props.dispatch(handleSubmitNewHomework(this.props.match.params.classId,this.state.title,this.state.questions))
       .then(result => {
            if (result.error!== null) {
                this.setState({
                    error: result.error.message,
                    alertError: true
                })
            } else {
                this.props.history.push('/dashboard/homework/home')
            }
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="dashboard-content-page p-0">
                <div className="hw-questions-control">
                    <div className="hw-questions-list">
                        <div className="hw-questions-item">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Group controlId="exampleForm.ControlInput1" className="hw-the-question">
                                    <Form.Label>{t('dashboard.Title')}</Form.Label>
                                    <Form.Control type="text" name="title" value={this.state.title} placeholder="Type Homework Title here" onChange={this.handleChangeTitle} required />
                                </Form.Group>
                                {this.state.questions.map(qu => 
                                    <React.Fragment key={this.state.questions.indexOf(qu)}>
                                        <div className="d-flex align-items-baseline justify-content-between">
                                            <Form.Group controlId="exampleForm.ControlInput1" className="hw-the-question">
                                                <Form.Label>{t('dashboard.Question')} {this.state.questions.indexOf(qu)+1}</Form.Label>
                                                <Form.Control type="text" name="question" value={this.state.questions[this.state.questions.indexOf(qu).question]} placeholder="Type question here" onChange={(e) => this.handleChange(e,this.state.questions.indexOf(qu))} required />
                                                {this.state.questions[this.state.questions.indexOf(qu)].imageD !== "" && <img className="hwq-img" src={this.state.questions[this.state.questions.indexOf(qu)].imageD} alt="question" />}
                                                <Form.Group>
                                                    <Form.File accept="image/*" type="file" id="exampleFormControlFile1" label={t('dashboard.Upload Image')} name="image" onChange={(e) => this.onImageChange(e,this.state.questions.indexOf(qu))} />
                                                </Form.Group>
                                            </Form.Group>
                                            <button type="button" onClick={() => this.deleteQuestion(this.state.questions.indexOf(qu))} title="Delete this question" className="hwq-delete main-btn danger-btn">{t('Delete')}</button>
                                        </div> 
                                        <ul className="hw-question-answers list-unstyled">
                                            <li className="hw-question-answer">
                                                <Form.Group controlId="exampleForm.ControlInput11">
                                                    <Form.Label>{t('dashboard.Choice')} 1</Form.Label>
                                                    <Form.Control type="text" name={0} value={this.state.questions[this.state.questions.indexOf(qu)][0]} placeholder={t('Question Answer')} className="hw-question-input" onChange={(e) => this.handleChange(e,this.state.questions.indexOf(qu))} required />
                                                </Form.Group>
                                                <Form.Group controlId="exampleForm.ControlInput21" className="hw-correct-answer-check">
                                                    <Form.Check type="radio" name="rightAnswer" id="autoSizingCheck21" value={0} label={t('dashboard.Right answer')} onChange={(e) => this.handleChange(e,this.state.questions.indexOf(qu))} required />
                                                </Form.Group>
                                            </li>
                                            <li className="hw-question-answer">
                                                <Form.Group controlId="exampleForm.ControlInput12">
                                                    <Form.Label>{t('dashboard.Choice')} 2</Form.Label>
                                                    <Form.Control type="text" name={1} placeholder={t('Question Answer')} value={this.state.questions[this.state.questions.indexOf(qu)][1]} className="hw-question-input" onChange={(e) => this.handleChange(e,this.state.questions.indexOf(qu))} required />
                                                </Form.Group>
                                                <Form.Group controlId="exampleForm.ControlInput22" className="hw-correct-answer-check">
                                                    <Form.Check type="radio" name="rightAnswer" id="autoSizingCheck22" value={1} label={t('dashboard.Right answer')} onChange={(e) => this.handleChange(e,this.state.questions.indexOf(qu))} required />
                                                </Form.Group>
                                            </li>
                                            <li className="hw-question-answer">
                                                <Form.Group controlId="exampleForm.ControlInput13">
                                                    <Form.Label>{t('dashboard.Choice')} 3</Form.Label>
                                                    <Form.Control type="text" name={2} placeholder={t('Question Answer')} value={this.state.questions[this.state.questions.indexOf(qu)][2]} className="hw-question-input" onChange={(e) => this.handleChange(e,this.state.questions.indexOf(qu))} required />
                                                </Form.Group>
                                                <Form.Group controlId="exampleForm.ControlInput23" className="hw-correct-answer-check">
                                                    <Form.Check type="radio" name="rightAnswer" id="autoSizingCheck23" value={2} label={t('dashboard.Right answer')} onChange={(e) => this.handleChange(e,this.state.questions.indexOf(qu))} required />
                                                </Form.Group>
                                            </li>
                                            <li className="hw-question-answer">
                                                <Form.Group controlId="exampleForm.ControlInput14">
                                                    <Form.Label>{t('dashboard.Choice')} 4</Form.Label>
                                                    <Form.Control type="text" name={3} placeholder={t('Question Answer')} value={this.state.questions[this.state.questions.indexOf(qu)][3]} className="hw-question-input" onChange={(e) => this.handleChange(e,this.state.questions.indexOf(qu))} required />
                                                </Form.Group>
                                                <Form.Group controlId="exampleForm.ControlInput24" className="hw-correct-answer-check">
                                                    <Form.Check type="radio" name="rightAnswer" id="autoSizingCheck24" value={3} label={t('dashboard.Right answer')} onChange={(e) => this.handleChange(e,this.state.questions.indexOf(qu))} required />
                                                </Form.Group>
                                            </li>
                                        </ul>
                                    </React.Fragment>   
                                )}
                            
                                <Nav className="hw-questions-actions">
                                    <button type="button" onClick={this.addQuestion} className="main-btn border-btn-primary">{t('Add another question')}</button>
                                    <Link to="/dashboard/main" className="main-btn border-btn-primary ml-auto">{t('Cancel')}</Link>
                                    <button type="submit" className="main-btn main-btn-primary ml-2">{t('Save')}</button>
                                </Nav>
                            </Form>
                            <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps ({ classes }) {
	return {
        classes
	}
}

export default withTranslation()(connect(mapStateToProps)(HomeWorkNewPage));