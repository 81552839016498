import { SET_AUTHED_USER, NEW_USER, UPDATE_USER, UPLOAD_CERTIFICATION, DELETE_CERTIFICATION, DELETE_APPOINMENT } from '../actionCreators/authedUser';

export default function authedUser (state = null, action) {
    switch (action.type) {
        case SET_AUTHED_USER :
            return action.user
        case NEW_USER :
            return action
        case UPDATE_USER :
            return action
        case UPLOAD_CERTIFICATION :
            if(action.url !==null) state.certifications.push(action.url)
            return state
        case DELETE_CERTIFICATION :
            const certfs = state.certifications.filter(crtf => crtf !== action.url)
            if(action.url !==null) state.certifications = certfs
            return state
        case DELETE_APPOINMENT :
            const apps = state.appointments.filter(ap => ap.id !== action.id)
            if(action.id !==null) state.appointments = apps
            return state
        // case CHANGE_APPOINMENT :
        //     if(action.appointment !== null) {
        //         state.appointments[action.appointment.id] = action.appointment
        //     }
        //     return state
        default :
            return state
    }
} 