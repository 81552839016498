import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import authedUser from './authedUser';
import freeSession from './freeSession';
import articles from './articles';
import videos from './videos';
import contactUs from './contactUs';
import classes from './classes';
import homework from './homework';
import users from './users';
import invoices from './invoices';
import teachingRequeasts from './teachingRequests';
import audios from './audios';
import honorBoardUsers from './honorBoard';
import ourTeachers from './ourTeachers';
// Quran API Reducers:
import ChapterReducer from "./quranReducers/ChapterReducer";
import EditionReducer from "./quranReducers/EditionReducer";
import SurahReducer from "./quranReducers/SurahReducer";
import TranslationReducer from "./quranReducers/TranslationReducer";
import ReciterReducer from "./quranReducers/ReciterReducer";
import VerseRangeReducer from "./quranReducers/VerseRangeReducer";
import HighlightReducer from "./quranReducers/HighlightReducer";
import SurahListReducer from "./quranReducers/SurahListReducer";
import TranslationListReducer from "./quranReducers/TranslationListReducer";
import EditionListReducer from "./quranReducers/EditionListReducer";
import ReciterListReducer from "./quranReducers/ReciterListReducer";
import SelectedSurahReducer from "./quranReducers/SelectedSurahReducer";
import SearchReducer from "./quranReducers/SearchReducer";

export default combineReducers({
    authedUser,
    freeSession,
    articles,
    videos,
    audios,
    contactUs,
    classes,
    homework,
    users,
    invoices,
    teachingRequeasts,
    loadingBar: loadingBarReducer,
    honorBoardUsers,
    ourTeachers,
    // Quran API Reducers:
    surahList: SurahListReducer,
    translationList: TranslationListReducer,
    editionList: EditionListReducer,
    reciterList: ReciterListReducer,
    selectedSurah: SelectedSurahReducer,
    searchText: SearchReducer,
    chapter: ChapterReducer,
    edition: EditionReducer,
    surah: SurahReducer,
    translation: TranslationReducer,
    audio: ReciterReducer,
    verseRange: VerseRangeReducer,
    highlight: HighlightReducer
}) 
