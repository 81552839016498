import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { adminAddClass, userDoc, getMyClasses, getClassStudents, getStudentClasses, getClassHomeworks, getHomeworkQuestions, getMyHomeworkAnswers, getClassTeacher, answerHomework, addHomework, addQuestionsToHomework, addClass, getAllClasses, getUserByEmail, addStudentToClass, deleteClass, getUserByUid, removeStudentFromClass, editClass } from '../utils/firebase';

export const GET_MY_CLASSES = 'GET_MY_CLASSES';
export const SUBMIT_HOMEWORK_ANSWER = 'SUBMIT_HOMEWORK_ANSWER';
export const ADD_HOMEWORK = 'ADD_HOMEWORK';
export const ADD_CLASS = 'ADD_CLASS';
export const EDIT_CLASS = 'EDIT_CLASS';
export const GET_All_CLASSES = 'GET_All_CLASSES';
export const ADD_STUDENT_TO_CLASS = 'ADD_STUDENT_TO_CLASS';
export const REMOVE_STUDENT_FROM_CLASS = 'REMOVE_STUDENT_FROM_CLASS';
export const DELETE_CLASS = 'DELETE_CLASS';

function generateUID () {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

export function receiveMyclasses (classes, error=null) {
    return {
        type: GET_MY_CLASSES,
        classes,
        error
    }
}

export function handleReceiveMyclasses() {
    return (dispatch) => {
        dispatch(showLoading())
        return firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                return userDoc(user).then((result) =>{
                    if (result.state ==="teacher") {
                        return getMyClasses(result.uid)
                        .then((classes) => {
                            let result = [];
                            classes.forEach((doc) => {
                                const { title, learn, start_at, zoomNumber, teacher } = doc.data()
                                let clas = {id: doc.id, title, learn, start_at, zoomNumber, students: [], homeworks:[], teacher: {}}
                                getClassTeacher(teacher)
                                .then((te) => {
                                    clas.teacher = {...te.data()}
                                })
                                getClassStudents(doc.id)
                                .then(async (students) => {
                                    let studentsArray = []
                                    for (let student of students) {
                                        await student.get().then((dos) => studentsArray.push(dos.data()))
                                    }
                                    clas.students = studentsArray
                                }).catch(error => {
                                    clas.students = []
                                })
                                getClassHomeworks(doc.id)
                                .then((homeworks) => {
                                    let homeworksArray = []
                                    homeworks.forEach(hw => {
                                        let home = {id: hw.id, ...hw.data()}
                                        homeworksArray.push(home)
                                        getHomeworkQuestions(doc.id,hw.id)
                                        .then((questions) => {
                                            let questionsArray = []
                                            questions.forEach(qu => {
                                                questionsArray.push({id: qu.id, ...qu.data()})
                                            })
                                            home.questions = questionsArray
                                        })
                                        getMyHomeworkAnswers(doc.id,hw.id)
                                        .then((ans) => {
                                            if (ans.exists) {
                                                home.myAnswers = {...ans.data()}
                                            } else {
                                                home.myAnswers = null
                                            }
                                        })
                                    })
                                    clas.homeworks = homeworksArray
                                }).catch(error => {
                                    clas.homeworks = []
                                })
                                result.push(clas)
                            })
                            dispatch(hideLoading())
                            return dispatch(receiveMyclasses(result))
                        })
                        .catch((error) => {
                            console.log('handleReceiveMyclasses error', error)
                            dispatch(hideLoading())
                            return dispatch(receiveMyclasses(null,error))
                        })
                    } else {
                        return getStudentClasses()
                        .then((classes) => {
                            let result = [];
                            classes.forEach((doc) => {
                                const { title, learn, start_at, teacher } = doc.data()
                                let clas = { id: doc.id, title, learn, start_at, teacher: {} }
                                getClassTeacher(teacher)
                                .then((te) => {
                                    clas.teacher = {...te.data()}
                                })
                                getClassHomeworks(doc.id)
                                .then((homeworks) => {
                                    let homeworksArray = []
                                    homeworks.forEach(hw => {
                                        let home = {id: hw.id, ...hw.data()}
                                        homeworksArray.push(home)
                                        getHomeworkQuestions(doc.id,hw.id)
                                        .then((questions) => {
                                            let questionsArray = []
                                            questions.forEach(qu => {
                                                questionsArray.push({id: qu.id, ...qu.data()})
                                            })
                                            home.questions = questionsArray
                                        })
                                        getMyHomeworkAnswers(doc.id,hw.id)
                                        .then((ans) => {
                                            if (ans.exists) {
                                                home.myAnswers = {...ans.data()}
                                            } else {
                                                home.myAnswers = null
                                            }
                                        })
                                    })
                                    clas.homeworks = homeworksArray
                                })
                                result.push(clas)
                            })
                            dispatch(hideLoading())
                            return dispatch(receiveMyclasses(result))
                        }).catch((error) => {
                            dispatch(hideLoading())
                            return dispatch(receiveMyclasses(null,error))
                        })
                    }
                })
            } else {
                dispatch(hideLoading())
                return dispatch(receiveMyclasses(null))
            }
        })
    }
}

function submitHomeworkAnswer({classId,homeworkId,studentAnswer},error=null) {
    return {
        type: SUBMIT_HOMEWORK_ANSWER,
        classId,
        homeworkId,
        studentAnswer,
		error
	};
}

export function handleSubmitHomeworkAnswer(classId,homeworkId,marks,answers) {
	return (dispatch) => {
        const user = firebase.auth().currentUser
        return answerHomework(classId,homeworkId,marks,answers)
        .then(() => {
            return dispatch(submitHomeworkAnswer({
                classId,
                homeworkId,
                studentAnswer: {
                    marks,
                    answers,
                    uid: user.uid,
                    student: firebase.firestore().doc(`users/${user.uid}`)
                }
            }))
        })
		.catch((error) => dispatch(submitHomeworkAnswer({classId,homeworkId,studentAnswer:null},error)))
    }
}

function submitNewHomework(classId,homework,error=null) {
    return {
        type: ADD_HOMEWORK,
        classId,
        homework,
		error
	};
}

export function handleSubmitNewHomework(classId,title,questions) {
	return (dispatch) => {
        return addHomework(classId,title)
        .then((hw) => {
            addQuestionsToHomework(classId,hw.id,questions)
            let questionsArray = []
            questions.forEach(qu => {
                let qus = {
                    id: generateUID(),  
                    question: qu.question,
                    rightAnswer: Number(qu.rightAnswer),
                    answers: [
                        qu[0],
                        qu[1],
                        qu[2],
                        qu[3],
                    ]
                }
                questionsArray.push(qus)
            })
            const homework = {
                id: hw.id,
                title,
                questions: questionsArray
            }
            return dispatch(submitNewHomework(
                classId,homework
            ))
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
            return dispatch(submitNewHomework(
                classId,null,error))
        });
    }
}

function submitNewClass(clas,error=null) {
    return {
        type: ADD_CLASS,
        clas,
		error
	};
}

export function handleSubmitNewClass(learn,title,description,start_at) {
	return (dispatch) => {
        return addClass(learn,title,description,start_at)
        .then(cls => {
            let clas = {
                id: cls.id,
                learn,
                start_at: firebase.firestore.Timestamp.fromDate(new Date(start_at)),
                title,
                description,
                students:[],
                homeworks:[],
                teacher: {zoomNumber: ""}
            }
            const user = firebase.auth().currentUser
            getUserByUid(user.uid)
            .then(user => {
                clas.teacher.zoomNumber = user.data().zoomNumber
            })
            return dispatch(submitNewClass(clas))
        }).catch((error) => {
            console.error("Error adding document: ", error);
            return dispatch(submitNewClass(null,error))
        })
    }
}

export function handleSubmitAdminNewClass(obj) {
    return async (dispatch) => {
        const response = await adminAddClass(obj);
        const clas = {
            id: response.id,
            ...obj,
            start_at: firebase.firestore.Timestamp.fromDate(new Date(obj.start_at)),
            students:[],
            homeworks:[],
            teacher: { zoomNumber: obj.zoomNumber }
        }
        return dispatch(submitNewClass(clas));
    }
}

function submitEditClass(clas,error=null) {
    return {
        type: EDIT_CLASS,
        clas,
		error
	};
}

export function handleSubmitEditClass(obj) {
	return async (dispatch) => {
        const clas = await editClass(obj);
        return dispatch(submitEditClass(clas))
    }
}

export function receiveAllClasses (classes, error=null) {
    return {
        type: GET_All_CLASSES,
        classes,
        error
    }
}

export function handleGetAllClasses() {
    return (dispatch) => {
        return getAllClasses()
        .then(classes => {
            let result = [];
            classes.forEach((doc) => {
                const { title, learn, start_at, teacher } = doc.data()
                let clas = { id: doc.id, title, learn, start_at, teacher: {}, students:[] }
                getClassTeacher(teacher)
                .then((te) => {
                    clas.teacher = {...te.data()}
                })
                getClassStudents(doc.id)
                .then((students) => {
                    let studentsArray = []
                    students.forEach(student => student.get().then((dos) => {
                        studentsArray.push(dos.data())
                    }))
                    clas.students = studentsArray
                }).catch(error => {
                    clas.students = []
                })
                result.push(clas)
            })
            dispatch(receiveAllClasses(result))
        }).catch(error => {
            dispatch(receiveAllClasses([], error))
        })
    }
}

function submitAddStudentToClass (email,classId, student, error=null) {
    return {
        type: ADD_STUDENT_TO_CLASS,
        email,
        classId,
        student,
        error
    }
}

export function handleSubmitAddStudentToClass(classId,email) {
    return (dispatch) => {
        // getClassStudents(classId)
        // .then((students) => {
        //     students.forEach(student => student.get().then((dos) => {
        //         if (dos.data().email === email) return dispatch(submitAddStudentToClass(email,classId,null, {message: `User "${dos.data().displayName}" with Email" ${email}" is already in this class!`}))
        //     }))
        // })
        return getUserByEmail(email)
        .then((querySnapshot) => {
            if (querySnapshot.empty) {
                return dispatch(submitAddStudentToClass(email,classId,null, {message: `No User registered with "${email}"`}))
            } else {
                let student = {}
                querySnapshot.forEach(async (doc) => {
                    student = {...doc.data()}
                    const uid = doc.data().uid;
                    await addStudentToClass(classId,uid)
                });
                return dispatch(submitAddStudentToClass(email,classId,student))
            }
            
            
        })
        .catch((error) => {
            return dispatch(submitAddStudentToClass(email,classId,null,error))
        });
    }
}

function submitRemoveStudentFromClass (classId, uid, error=null) {
    return {
        type: REMOVE_STUDENT_FROM_CLASS,
        classId,
        uid,
        error
    }
}

export function handleSubmitRemoveStudentFromClass(classId,uid) {
    return (dispatch) => {         
        return removeStudentFromClass(classId,uid)
        .then(() => {
            return dispatch(submitRemoveStudentFromClass(classId,uid))
        }).catch(error => {
            return dispatch(submitRemoveStudentFromClass(null,null, error))
        })
    }
}

function removeClass(clas,error=null) {
    return {
        type: DELETE_CLASS,
        clas,
		error
	};
}

export function handleDeleteClass(clas) {
	return (dispatch) => {
        dispatch(showLoading())
        return deleteClass(clas.id)
        .then(() => {
            dispatch(hideLoading())
            return dispatch(removeClass(clas))
        })
		.catch((error) => {
            dispatch(hideLoading())
            return dispatch(removeClass(null,error)) 
        })
    }
}

/*
Old functions may be wanted :)
export function handleReceiveTeacherClasses () {
    return (dispatch) => {
        dispatch(showLoading())
        return firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                return userDoc(user).then((result) => getMyClasses(result.uid)
                .then((classes) => {
                    let result = [];
                    classes.forEach((doc) => {
                        const { title, learn, start_at } = doc.data()
                        let clas = {id: doc.id, title, learn, start_at, students: [], homeworks:[]}
                        getClassStudents(doc.id)
                        .then((students) => {
                            let studentsArray = []
                            students.forEach(student => student.get().then((dos) => {
                                studentsArray.push(dos.data())
                            }))
                            clas.students = studentsArray
                        }).catch(error => {
                            clas.students = []
                        })
                        getClassHomeworks(doc.id)
                        .then((homeworks) => {
                            let homeworksArray = []
                            homeworks.forEach(hw => {
                                let home = {id: hw.id, ...hw.data()}
                                homeworksArray.push(home)
                                getHomeworkQuestions(doc.id,hw.id)
                                .then((questions) => {
                                    let questionsArray = []
                                    questions.forEach(qu => {
                                        questionsArray.push({id: qu.id, ...qu.data()})
                                    })
                                    home.questions = questionsArray
                                })
                                getMyHomeworkAnswers(doc.id,hw.id)
                                .then((ans) => {
                                    if (ans.exists) {
                                        home.myAnswers = {...ans.data()}
                                    } else {
                                        home.myAnswers = null
                                    }
                                })
                            })
                            clas.homeworks = homeworksArray
                        }).catch(error => {
                            clas.homeworks = []
                        })
                        result.push(clas)
                    })
                    dispatch(hideLoading())
                    return dispatch(receiveMyclasses(result))
                })
                .catch((error) => {
                    dispatch(hideLoading())
                    return dispatch(receiveMyclasses(null,error))
                }))
            } else {
                dispatch(hideLoading())
                return dispatch(receiveMyclasses(null))
            }
        })
    }
}

export function handleReceiveStudentClasses () {
    return (dispatch) => {
        dispatch(showLoading())
        return firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                return getStudentClasses()
                .then((classes) => {
                    let result = [];
                    classes.forEach((doc) => {
                        const { title, learn, start_at, teacher } = doc.data()
                        let clas = { id: doc.id, title, learn, start_at, teacher: {} }
                        getClassTeacher(teacher)
                        .then((te) => {
                            clas.teacher = {...te.data()}
                        })
                        getClassHomeworks(doc.id)
                        .then((homeworks) => {
                            let homeworksArray = []
                            homeworks.forEach(hw => {
                                let home = {id: hw.id, ...hw.data()}
                                homeworksArray.push(home)
                                getHomeworkQuestions(doc.id,hw.id)
                                .then((questions) => {
                                    let questionsArray = []
                                    questions.forEach(qu => {
                                        questionsArray.push({id: qu.id, ...qu.data()})
                                    })
                                    home.questions = questionsArray
                                })
                                getMyHomeworkAnswers(doc.id,hw.id)
                                .then((ans) => {
                                    if (ans.exists) {
                                        home.myAnswers = {...ans.data()}
                                    } else {
                                        home.myAnswers = null
                                    }
                                })
                            })
                            clas.homeworks = homeworksArray
                        })
                        result.push(clas)
                    })
                    dispatch(hideLoading())
                    return dispatch(receiveMyclasses(result))
                }).catch((error) => {
                    dispatch(hideLoading())
                    return dispatch(receiveMyclasses(null,error))
                })
            } else {
                dispatch(hideLoading())
                return dispatch(receiveMyclasses(null))
            }
        })
    }
}

*/