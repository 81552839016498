import emailjs from '@emailjs/browser';
import { freeSession, getFreeSessions, deleteFreeSession } from '../utils/firebase';

export const SUBMIT_FREE_SESSION = 'SUBMIT_FREE_SESSION';
export const RECEIVE_FREE_SESSIONS = 'RECEIVE_FREE_SESSIONS';
export const DELETE_FREE_SESSION = 'DELETE_FREE_SESSION';

function receiveFreeSessions (freeSessions, error=null) {
    return {
        type: RECEIVE_FREE_SESSIONS,
        freeSessions,
        error
    }
}

export function handleReceiveFreeSessions () {
    return (dispatch) => {
        return getFreeSessions()
          .then((freeSessions) => {
            let result = [];
            freeSessions.forEach((doc) => {
                let goal = {id: doc.id, ...doc.data()}
                result.push(goal)
            })
            dispatch(receiveFreeSessions(result))
        }).catch((error) => {
            dispatch(receiveFreeSessions(null,error))
        })
    }
} 

function submitFreeSession(payload,error=null) {
    return {
        type: SUBMIT_FREE_SESSION,
        payload,
		error
	};
}

export function handleSubmitFreeSession({name,email,timeZone,availability,learn,send, whatsapp}) {
	return (dispatch) => {
        return freeSession({name,email,timeZone,availability,learn,send, whatsapp})
        .then((doc) => {
            let ref = {
                id: doc.id,
                name, email, timeZone, availability, learn, send, whatsapp
            }
            emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID,process.env.REACT_APP_EMAILJS_TEMPLATE_ID, {name,email,timeZone,availability,learn,send}, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
            }, (err) => {
                console.log('FAILED...', err);
            });
            return dispatch(submitFreeSession(ref))
        })
		.catch((error) => dispatch(submitFreeSession(null,error)))
    }
}

function removeFreeSession(id,error=null) {
    return {
        type: DELETE_FREE_SESSION,
        id,
		error
	};
}

export function handleDeleteFreeSession(id) {
	return (dispatch) => {
        return deleteFreeSession(id)
        .then(() => dispatch(removeFreeSession(id)))
		.catch((error) => {
            dispatch(removeFreeSession(null,error))
            return getFreeSessions()
            .then((freeSessions) => {
                let result = [];
                freeSessions.forEach((doc) => {
                    let goal = {id: doc.id, ...doc.data()}
                    result.push(goal)
                })
                return dispatch(receiveFreeSessions(result,error)) 
            }) 
        })
        
    }
}