import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Component from './Component';


const SignIn = ({ loginModal, closeLoginModal }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={loginModal}
      onHide={closeLoginModal}
      aria-labelledby="login-modal"
      className="signup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          {t('header.Login')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <Component closeLoginModal={closeLoginModal} />
      </Modal.Body>
    </Modal>
  )
}

export default SignIn;