import React , { Component } from 'react';
import { Table, Button, Modal, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { handleDeleteFreeSession } from '../../store/actionCreators/freeSession';

class FreeSessions extends Component {
    state = {
        openDeleteModal: null
    }

    setOpenDeleteModal = value => this.setState({openDeleteModal: value})

    openModal = (value) => this.setState({modalShow: value})

    render() {
        // console.log(this.props)
        return (
            <React.Fragment>
            <Table className="free-session" bordered responsive>
                <thead style={{textAlign:"center"}}>
                    <tr>
                        <th>#</th>
                        <th>Delete</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>whatsapp</th>
                        <th>Learn</th>
                        <th>Time Zone</th>
                        <th>Availability</th>
                        <th>Send Emails</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.freeSession
                        ? this.props.freeSession.map(free =>
                            <tr key={free.id}>
                                <td>{this.props.freeSession.indexOf(free)+1}</td>
                                <td><Button variant="danger" onClick={() => this.setOpenDeleteModal(free.id)}>Delete</Button></td>
                                <td>{free.name}</td>
                                <td>{free.email}</td>
                                <td>
                                    <span className='phone-number'>{free.whatsapp}</span>
                                </td>
                                <td><ul>{free.learn.map(l => <li key={l}>{l}</li>)}</ul></td>
                                <td>{free.timeZone}</td>
                                <td>{free.availability}</td>
                                <td>{free.send===undefined ? "" : free.send ? "Yes" : "No"}</td>
                                <td>{free.created_at}</td>
                                <DeleteModal
                                    free={free}
                                    deleteModal={this.state.openDeleteModal===free.id}
                                    closeDeleteModal={() => this.setOpenDeleteModal(null)}
                                    dispatch={this.props.dispatch}
                                /> 
                            </tr>
                        ) : null
                    }
                </tbody>
            </Table>
            </React.Fragment>
        )
    }
}

function mapDispatchToProps ({freeSession}) {
	return {
		freeSession
	};
}

export default connect(mapDispatchToProps) (FreeSessions);

class DeleteModal extends Component {
    state = {
        error: '',
        alertError: false
    }

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeDeleteModal()
    }

    deleteFree = (id) => {
        this.setState({error: '', alertError: false})
        this.props.dispatch(handleDeleteFreeSession(id))
        .then((result) => {
            if (result.error !== null) {
                this.setState({error: result.error.message, alertError: true})
            }
        })
    }
  
    render() {
        // console.log(this.props)
        const { free, deleteModal } = this.props;
        return (
            <Modal
                show={deleteModal}
                onHide={this.closeModal}
                aria-labelledby="delete-modal"
                className="signup"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Delete "{free.email}" article?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="text-center"><Alert variant="warning">Are you sure you want to delete "{free.name}" <br/> "{free.email}"</Alert></h5>
                    <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Cancel</Button>
                    <Button variant="danger" onClick={() => this.deleteFree(free.id)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}