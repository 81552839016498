import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
// import ReactPlayer from 'react-player/lazy';

import TeamCard from './TeamCard';
import ArticlesContainer from '../ArticlesPage/ArticlesContainer';
// import Games from '../GamesPage';
import ContactUs from '../ContactusPage';
import CountUp from 'react-countup';

class HomeContent extends Component {
    render() {
        const { t } = this.props;
        document.title = `${t('home.Hafs Quran')}`;
        
        return (
            <div>
                <div className="slider-social d-md-block d-none">
                    <ul className="list-unstyled" style={{ paddingInlineStart: 0 }}>
                        <li className="animated-wrap"><a href="https://www.facebook.com/hafsquran.co" target="_blank" rel="noreferrer"><img src="img/fb.svg" alt="facebook"/></a></li>
                        <li className="animated-wrap"> <a href="https://www.youtube.com/channel/UCLYsjjY5WWTouDgDMvo9sRQ" target="_blank" rel="noreferrer"><img src="img/yt.svg" alt="youtube"/></a></li>
                        <li className="animated-wrap"><a href="https://twitter.com/Hafsquran" target="_blank" rel="noreferrer"><img src="img/tw.svg" alt="twitter"/></a></li>
                        <li className="animated-wrap"><a href="https://www.instagram.com/hafsquran.co" target="_blank" rel="noreferrer"><img src="img/ins.svg" alt="instgram"/></a></li>
                        <li className="animated-wrap"><a href="https://wa.me/201003439443" target="_blank" rel="noreferrer"><img src="img/whatsapp2.svg" alt="whatsapp"/></a></li>
                        <li className="animated-wrap"><a href="https://www.tiktok.com/@hafsquran.co" target="_blank" rel="noreferrer"><img src="img/tiktok-logo.svg" alt="TikTok"/></a></li>
                        <li className="animated-wrap"><a href="https://www.linkedin.com/company/hafsquran-co/" target="_blank" rel="noreferrer"><img src="img/linkedin.svg" alt="LinkedIn"/></a></li>
                    </ul>
                </div>
                
                <section className="header" style={{ direction: 'ltr' }}>
                    {/* <div className="header__content1">
                        <ReactPlayer url="https://www.youtube.com/watch?v=ZAyz9FcyOnA" className='react-player' controls playing width="100%" height="70vh" style={{textAlign: "left", borderRadius: "50px", overflow: "hidden", marginTop: "70px"}} />
                    </div> */}
                    <div className="header__content2">
                        <h1 className="header__content2--title">
                            {t('home.Hafs Quran')}
                        </h1>
                        <h3 className="header__content2--think">
                            {t('home.Think Quran, Think Hafs')}
                        </h3>
                        {/* <p className="header__content2--paragraph">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Optio eligendi qui amet quibusdam modi et distinctio soluta.
                        </p> */}
                        <div className="header__content2--links">
                            <Link to="/free-session" className='btn btn--primary mr-3 header__content2--session'>{t('home.Free Session')}</Link>
                            <Link to="/signup-teacher" className='btn btn--view header__content2--teacher'>{t('home.Sign up as a teacher')}</Link>
                        </div>
                    </div>
                    
                </section>
                {this.props.honorBoardUsers && this.props.honorBoardUsers !== null && this.props.honorBoardUsers.length && this.props.honorBoardUsers.length !== 0 &&
                    <section className="board">
                        <div className="board__content">
                            <h2 className="board__title">
                                {t('home.HONOR BOARD')}
                            </h2>
                            <div className="board__card">
                                {this.props.honorBoardUsers.map((student, i) => 
                                    <div className="board__first row align-items-center" key={student.uid} style={{textAlign: "start"}}>
                                        <span className="col-2 col-lg-1 board__index text-center">{i+1}</span>
                                        <div className="col-8 col-lg-6">
                                            <img className="board__avatar ml-2 mr-2" src={student.photoURL} alt={`${student.displayName} avatar`}/>
                                            <span>{student.displayName}</span>
                                        </div>
                                        <span className="col-2 col-lg-2 text-center">{student.points}</span>
                                        <div className="d-none d-lg-block col-lg-3 text-center"><img src="img/Polygon 26.svg" alt="1st place"/></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                }
                
                <section className="services">
                    <div className="services__content">
                        <h2 className="services__title">
                            {t('home.Hafs Services')}
                        </h2>
                        <div className="services__cardContainer">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="services__card">
                                        <img src="img/Reading-Only-300x300.png" alt="read only"/>
                                        <h3 className="services__card--title">
                                            {t('home.Read Only')}
                                        </h3>
                                        <p className="services__card--paragraph">
                                            {t('home.You can if you wish only recite with a specialist')}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="services__card">
                                        <img src="img/Tajweed-learning-300x300.png" alt="tajweed"/>
                                        <h3 className="services__card--title">
                                            {t('home.Tajweed')}
                                        </h3>
                                        <p className="services__card--paragraph">
                                            {t("home.Learn pronunciation's rulings used by the holders of Ijazah")}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="services__card">
                                        <img src="img/group-learning-300x300.png" alt="memorization"/>
                                        <h3 className="services__card--title">
                                            {t('home.Memorization')}
                                        </h3>
                                        <p className="services__card--paragraph">
                                            {t('home.Memorization sessions of the Noble Qur’an with proper rulings and pronunciation.')}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="services__card">
                                        <img src="img/arabic-language-300x300.png" alt="arabic language"/>
                                        <h3 className="services__card--title">
                                            {t('home.Arabic language')}
                                        </h3>
                                        <p className="services__card--paragraph">
                                            {t('home.Learn Arabic starting from the alphabet through the grammatical rules To end up speaking Arabic fluently.')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="statistics">
                    <div className="statistics__container">
                        <h2 className="statistics__title">
                            {t('home.Statistics')}
                        </h2>
                        <div className="row">
                            <div className="col-lg-4">
                                <img src="img/teacher.svg" alt="teacher"/>
                                <span><CountUp end={10} /></span>
                                <p>{t('home.Teacher')}</p>
                            </div>
                            <div className="col-lg-4">
                                <img src="img/muslim.svg" alt="muslim"/>
                                <span><CountUp end={50} /></span>
                                <p>{t('home.Student')}</p>
                            </div>
                            <div className="col-lg-4">
                                <img src="img/teaching.svg" alt="teaching"/>
                                <span><CountUp end={300} /></span>
                                <p>{t('home.Classroom')}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="articles">
                    <h2 className="articles__title">
                        {t('header.Articles')}
                    </h2>
                    <ArticlesContainer view={'short'} />
                    <Link to="/articles" className="btn--view">{t('home.View all')}</Link>
                </section>
                {/* <Games /> */}
                {this.props.ourTeachers && this.props.ourTeachers !== null && this.props.ourTeachers.length && this.props.ourTeachers.length !== 0 &&
                    <section className="team">
                        <h2 className="team__title">
                            {t('home.Our Teachers')}
                        </h2>
                        <div className="team__container">
                            <div className="row">
                                {this.props.ourTeachers.map(teacher => 
                                    <div className="col-lg-4" key={teacher.uid}>
                                        <TeamCard teacher={teacher} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                }
                <ContactUs />
            </div>
        )
    }
}

function mapStateToProps ({ honorBoardUsers, ourTeachers }) {
	return {
		honorBoardUsers, ourTeachers
	}
}

export default withTranslation()(connect(mapStateToProps)(HomeContent));