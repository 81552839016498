import { GET_OUR_TEACHERS_USERS, ADD_USER_TO_OUR_TEACHERS, DELETE_USER_FROM_OUR_TEACHERS } from '../actionCreators/ourTeachers';

export default function ourTeachers (state = null, action) {
    switch (action.type) {
        case GET_OUR_TEACHERS_USERS :
            return action.users
        case ADD_USER_TO_OUR_TEACHERS :
            return action.user===null ? state : state.concat(action.user);
        case DELETE_USER_FROM_OUR_TEACHERS :
            return action.uid===null ? state : state.filter(stu => stu.uid !== action.uid)
        default :
            return state
    }
} 

