import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Button, Alert } from 'react-bootstrap';
import { AdBlockDetectedWrapper, useDetectAdBlock  } from "adblock-detect-react";

const StartMeeting = props => {

    const [passWord, setPassWord] = useState('');

    const clas = props.classes && props.classes.find(cls => cls.id === props.match.params.classId);

    const handleChange = (e) => {
        setPassWord(e.target.value)
    }
  
    const handleSubmit = e => {
        e.preventDefault()
        props.history.push(`/dashboard/${clas.id}/meeting/${passWord}`)
    }

    return (
        <div className="App m-auto text-center">
            <AdBlockDetectedWrapper>
                <Alert variant='danger'>
                    <i className="fas fa-exclamation-circle"></i>&nbsp;
                    You are using ad blocking plugin!<br/> please disable it to be able to join meeting and reload the page.
                </Alert>
            </AdBlockDetectedWrapper>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="m-auto">
                    <Form.Control type="passWord" name="passWord" placeholder="password" value={passWord} onChange={handleChange} required />
                    <span className="required-indicator" role="presentation"></span>
                </Form.Group>
                <Button type="submit" className="mt-4" disabled={useDetectAdBlock()}>Join Meeting</Button>
            </Form>
        </div>
    )
    
}

function mapStateToProps ({ classes, authedUser }) {
	return {
        classes, authedUser
	}
}

export default withRouter(connect(mapStateToProps)(StartMeeting));