// External dependencies
import React from 'react';

// import Header from './../../components/Header';
import PricingPage from '../../components/PricingPage';

const Pricing = () => (
    <PricingPage />
);

export default Pricing;