import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Alert } from 'react-bootstrap';
import { handleAddVideo } from '../../../store/actionCreators/videos';

class AddVideo extends Component {
    state = {
        alertError: false,
        error: '',
        src: ''
    }

    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState(() => ({
            alertError: false,
            error: '',
            src: ''
        }))
        const src = this.state.src;
        this.props.dispatch(handleAddVideo(src))
        .then((result) => {
            if (result.error===null) {
                this.props.history.push('/admin/videos')
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <h2>Adding new video</h2>
                <Form onSubmit={this.handleSubmit} style={{textAlign: "center"}}>
                    <Form.Group>
                        <Form.Control type="text" name="src" value={this.state.src} placeholder="Link for Youtube video" onChange={this.handleChange} required />
                        <span className="required-indicator" role="presentation"></span>
                    </Form.Group>
                    <Button className="btn btn--primary mt-2" type="submit" >Submit</Button>
                </Form>
                <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
            </React.Fragment>
        )
    }
}

function mapStateToProps ({ videos }) {
	return {
		videos
	}
}

export default connect(mapStateToProps)(AddVideo);