import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Alert, Form, InputGroup, Row } from 'react-bootstrap';
import { handleAddUserTeacher } from '../../../store/actionCreators/users';
import { handleDeleteRequest } from '../../../store/actionCreators/teachingRequeasts';

class Requeast extends Component {
    state = {
        openCertificationModal: null,
        openAcceptModal: false,
        alertSuccess: false,
        number: "",
        password: "",
        error: "",
        alertError: false,
        disabled: false,
    }

    formatDate = time => {
        const date = time.toDate();
        return (date.toLocaleString('default', { month: 'long' })) + " " + date.getDate()+ ", " +  date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
    }

    setOpenCertificationModal = value => this.setState({openCertificationModal: value})
    
    setOpenAcceptModal = value => this.setState({openAcceptModal: value})

    addTeacher = (uid, zoomNu, zoomPass) => {
        this.setState({
            number: "",
            password: "",
            error: "",
            alertError: false,
            alertSuccess: false,
            disabled: false
        })
        this.props.dispatch(handleAddUserTeacher(uid, zoomNu, zoomPass))
        .then(result => {
            if (result.error === null) {
                this.setState({
                    number: result.zoomNumber,
                    password: result.zoomPassword,
                    alertSuccess: true,
                    disabled: true
                })
                this.props.dispatch(handleDeleteRequest(uid))
            } else {
                this.setState({error: result.error.message, alertError: true})
            }
        })
    }

    render() {
        // console.log(this.state)
        const { timestamp, displayName, email, gender, learn, certifications, clip } = this.props.request
        const { openCertificationModal, openAcceptModal, alertSuccess, number, password, error, alertError, disabled } = this.state
        return (
            <div className="dashboard-content-page p-0">
                <div className="profile__main">
                    <div className="form-group">
                        <div className="info-group">
                            <h3>Request Time</h3>
                            <div className="form-group">
                                <span className="form-control d-inline">{timestamp && this.formatDate(timestamp)}</span>
                            </div>
                        </div>
                        <div className="info-group">
                            <h3>Personal Info</h3>
                            <div className="form-group">
                                <label className="mr-5 form-label">Name</label>
                                <span className="form-control d-inline">{displayName}</span>
                            </div>
                            <div className="form-group">
                                <label className="mr-5 form-label">Email</label>
                                <span className="form-control d-inline">{email}</span>
                            </div>
                            <div className="form-group">
                                <label className="mr-5 form-label">Gender</label>
                                <span className="form-control d-inline">{gender}</span>
                            </div>
                        </div>
                    </div>
                    <div className="teaching-info">
                        <h3>Teaching Info</h3>
                        <div className="form-group">
                            <label className="form-label">I Can Teach:</label>
                            <div>
                                <div className="form-check" style={{width: "auto"}}>
                                    {learn && learn.map(l => 
                                        <label key={l} className="form-check-label">{l}</label>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="certifications">
                        <h3>Certifications</h3>
                        {!certifications.length && <p>s/he didn't add certifications yet.</p>}
                        <div className="gallery">
                            {certifications && certifications.map(cert => 
                                <React.Fragment key={cert}>
                                <button onClick={() => this.setOpenCertificationModal(cert)}>
                                    <img src={cert} alt="certification" />
                                </button>
                                <CertificationModal
                                    certification={cert}
                                    certificationModal={openCertificationModal===cert}
                                    closeCertificationModal={() => this.setOpenCertificationModal(null)}
                                    dispatch={this.props.dispatch}
                                />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div className="teaching-info">
                        <h3>Audio or Video Clip</h3>
                        <div className="form-group">
                            <a target="_blank" href={clip} rel="noreferrer">{clip}</a>
                        </div>
                    </div>
                    <Alert show={alertError} className="m-2" variant='danger'>{error}</Alert>
                    <Alert show={alertSuccess} className="m-2" variant='success'>
                        <h4>User added to teachers successfully </h4>
                        <div className="info-group">
                            <h3>Zoom Room</h3>
                            <div className="form-group">
                                <label className="mr-5 form-label">Zoom meeting number</label>
                                <span className="form-control d-inline">{number}</span>
                            </div>
                            <div className="form-group">
                                <label className="mr-5 form-label">Room Password</label>
                                <span className="form-control d-inline">{password}</span>
                            </div>
                        </div>
                    </Alert>
                    <Button disabled={disabled} type="submit" className="btn btn--primary"  onClick={() => this.setOpenAcceptModal(true)}>Accept</Button>
                    <AcceptModal
                        request= {this.props.request}
                        acceptModal={openAcceptModal}
                        closeAcceptModal={() => this.setOpenAcceptModal(false)}
                        addTeacher={this.addTeacher}
                    />
                </div>
            </div>
        )
    }
}
function mapStateToProps ({ teachingRequeasts }, props) {
    return {
        request: teachingRequeasts!== null && teachingRequeasts.find(req => req.uid===props.match.params.uid)
    }
}
    
export default connect(mapStateToProps)(Requeast);

class CertificationModal extends Component {

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeCertificationModal()
    }
  
    render() {
        // console.log(this.props)
        const { certificationModal, certification } = this.props;
        return (
            <Modal
                show={certificationModal}
                onHide={this.closeModal}
                aria-labelledby="certification-modal"
                className="text-center signup"
                size="xl"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <img src={certification} alt="certification" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

class AcceptModal extends Component {

    state = {
        zoomNumber: "",
        zoomPassword: "",
        error: '',
        alertError: false
    }
    
    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeAcceptModal()
    }

    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    addTeacher = (uid, zoomNu, zoomPass) => {
        this.props.addTeacher(uid, zoomNu, zoomPass)
        this.props.closeAcceptModal()
    }
  
    render() {
        // console.log(this.props)
        const { acceptModal, request } = this.props;
        return (
            <Modal
                show={acceptModal}
                onHide={this.closeModal}
                aria-labelledby="accept-modal"
                className="text-center signup"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Adding "{request.displayName}" to the teachers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Are you sure you want to add "{request.displayName}" to the teachers?</h6>
                    <Form>
                        <Form.Group as={Row} controlId="validationFormikUsername">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">Zoom Number</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    style={{border: "1px solid #ced4da"}}
                                    type="text"
                                    placeholder="Zoom Number"
                                    aria-describedby="inputGroupPrepend"
                                    name="zoomNumber"
                                    value={this.state.zoomNumber}
                                    onChange={this.handleChange}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Row} controlId="validationFormikUsername">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">Zoom Password</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    style={{border: "1px solid #ced4da"}}
                                    type="text"
                                    placeholder="Zoom Password"
                                    aria-describedby="inputGroupPrepend"
                                    name="zoomPassword"
                                    value={this.state.zoomPassword}
                                    onChange={this.handleChange}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Close</Button>
                    <Button variant="success" onClick={() => this.addTeacher(request.uid,this.state.zoomNumber,this.state.zoomPassword)}>Accept</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}