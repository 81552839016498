import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form, Modal, Alert, Button } from 'react-bootstrap';
import { handleSubmitDeleteCertification, handleSubmitUploadCertification } from '../store/actionCreators/authedUser'
import { handleSubmitNewRequest } from '../store/actionCreators/teachingRequeasts';

class SignUpTeacher extends Component {
    state = {
        learn: [],
        aboutMe: "",
        certifications: [],
        clip: "",
        validated: false,
        error: "",
        alertError: false,
        alertSuccess: false,
        openSignModal: true,
        openTeacherModal: true,
        openCertificationModal: null
    }

    componentDidMount() {
        if(this.props.authedUser !== null) {
            this.setState({
                learn: this.props.authedUser.learn === undefined ? [] : this.props.authedUser.learn,
                certifications: this.props.authedUser.certifications === undefined ? [] : this.props.authedUser.certifications,
                aboutMe: this.props.authedUser.aboutMe === undefined ? [] : this.props.authedUser.aboutMe,
            }); 
        }
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render   
        if (nextProps.authedUser !== null) {
            this.setState({
                learn: nextProps.authedUser.learn === undefined ? [] : nextProps.authedUser.learn,
                aboutMe: nextProps.authedUser.aboutMe === undefined ? [] : nextProps.authedUser.aboutMe,
                certifications: nextProps.authedUser.certifications === undefined ? [] : nextProps.authedUser.certifications,
            });   
        }   
    }

    toggleChecked = e => {
        const value = e.target.value;
        const checked = e.target.checked;

        if (checked) {
            this.setState(() => ({
                learn: [...this.state.learn, value]
            }))
        } else {
            let learn = this.state.learn
            let newLearn = learn.filter(i => i!==value)
            this.setState(() => ({
                learn: newLearn
            }))
        }
    }

    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    upload = e => {
        this.setState({alertUploadError: false, uploadError: ""})
        const file = e.target.files[0];
        this.props.dispatch(handleSubmitUploadCertification(file))
        .then((result) => {
            if(!result.error) {
                let certifications = this.state.certifications;
                certifications.push(result.url)
                this.setState({certifications})
            } else {
                this.setState({error: result.error.customData.serverResponse, alertError: true})
            }
        })
    }

    handleDeleteCertification = url => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.dispatch(handleSubmitDeleteCertification(url))
        .then((result) => {
            if (result.error===null) {
                this.setOpenCertificationModal(null)
            } else {
                let certifications = this.state.certifications;
                certifications.filter(crt =>crt !== result.url)
                this.setState(() => ({
                    certifications,
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }

    checkForURL = inputURL => {
        if (inputURL === "") {
            return true
        } else {
            // console.log("::: Running check for URL :::", inputURL);

            var expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            return inputURL.match(regex)
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({alertError: false, error: "", alertSuccess: false, validated: true})
        if (this.checkForURL(this.state.clip)) {
            const { aboutMe, learn, certifications, clip } = this.state;
            // console.log(displayName,email,gender,country,timeZone,avatarURL,aboutMe)
            this.props.dispatch(handleSubmitNewRequest({aboutMe,learn,certifications,clip}))
            .then((result) => {
                if (result.error === null) {
                    this.setState({alertSuccess: true})
                } else {
                    this.setState({error: result.error.message, alertError: true})
                }
            }) 
        }
        
    }

    setOpenCertificationModal = value => this.setState({openCertificationModal: value})
    
    setOpenSignModal = value => this.setState({openSignModal: value})

    setOpenTeacherModal = value => this.setState({openTeacherModal: value})

    render() {
        // console.log(this.state)
        const { t } = this.props;
        document.title = `${t('home.Sign up as a teacher')} - ${t('home.Hafs Quran')}`;

        if (this.props.authedUser !== null) {
            if (this.props.authedUser.state === "teacher") {
                const closeTeacherModal = () => {
                    this.setOpenTeacherModal(false);
                    this.props.history.goBack();
                }
                return (
                    <TeacherModal
                        teacherModal={this.state.openTeacherModal}
                        closeTeacherModal={closeTeacherModal}
                    />
                )
            } else {
                const { learn, aboutMe, alertError, alertSuccess, error, clip, validated } = this.state;
                return (
                    <div className="profile__main">
                        <Form validated={validated} onSubmit={this.handleSubmit}>
                            <div className="teaching-info">
                                <h3>Teaching Info</h3>
                                <Form.Group>
                                        <Form.Label>I want to teach: </Form.Label>
                                    <div>
                                        <Form.Check 
                                            name="learn"
                                            type='checkbox'
                                            value='pronunciation'
                                            label={t('dashboard.Quran correct pronunciation')}
                                            checked={learn.includes("pronunciation")}
                                            onChange={this.toggleChecked}
                                        />
                                        <Form.Check 
                                            name="learn"
                                            type='checkbox'
                                            value='arabic'
                                            label={t('home.Arabic Language')}
                                            checked={learn.includes("arabic")}
                                            onChange={this.toggleChecked}
                                        />
                                        <Form.Check
                                            name="learn"
                                            type='checkbox'
                                            value='memorization'
                                            label={t('dashboard.Quran memorization')}
                                            checked={learn.includes("memorization")}
                                            onChange={this.toggleChecked}
                                        />
                                        <Form.Check 
                                            name="learn"
                                            type='checkbox'
                                            value='tajweed'
                                            label={t('home.Tajweed')}
                                            checked={learn.includes("tajweed")}
                                            onChange={this.toggleChecked}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="certifications">
                                <h3>Certifications</h3>
                                {(!this.props.authedUser.certifications|| !this.props.authedUser.certifications.length) && <p>{t("dashboard.You didn't add certifications yet")}</p>}
                                
                                <div className="">
                                    <label htmlFor="file-input">
                                        <img src="/img/cert.svg" alt=""/>
                                    </label>
                                    <input name="certification" id="file-input" style={{display: "none"}} type="file" accept="image/*" onChange={this.upload} />
                                </div>
                                <div className="gallery">
                                    {this.props.authedUser.certifications && this.props.authedUser.certifications.map(cert => 
                                        <React.Fragment key={cert}>
                                        <button type="button" onClick={() => this.setOpenCertificationModal(cert)}>
                                            <img alt="certification" src={cert} />
                                        </button>
                                        <CertificationModal
                                            certification={cert}
                                            certificationModal={this.state.openCertificationModal===cert}
                                            closeCertificationModal={() => this.setOpenCertificationModal(null)}
                                            handleDeleteCertification={this.handleDeleteCertification}
                                        />
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                            <div className="about-me">
                                <h3>{t('dashboard.About me')}</h3>
                                <Form.Group controlId="formBasicAboutme">
                                    <Form.Control 
                                        as="textarea" 
                                        name="aboutMe"
                                        placeholder={t('dashboard.About me')}
                                        rows={5} 
                                        value={aboutMe}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                            </div>
                            <div className="about-me">
                                <h3>{t('auth.Link for an audio or video clip for you')}</h3>
                                <Form.Group controlId="formBasicClip">
                                    <Form.Control 
                                        type="text" 
                                        name="clip"
                                        placeholder={t('auth.Link for an audio or video clip for you')}
                                        value={clip}
                                        onChange={this.handleChange}
                                        isInvalid={validated && !this.checkForURL(clip)} 
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t('auth.Invalid Link')}.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <Button type="submit" className="btn btn--primary">{t('dashboard.Submit')}</Button>
                        </Form>
                        <Alert show={alertError} className="m-2" variant='danger'>{error}</Alert>
                        <Alert show={alertSuccess} className="m-2" variant='success'>{t('auth.Your request has been successfully registered')}</Alert>
                    </div>
                )
            }
        } else {
            if (!this.props.loadingBar.default) {
                const closeSignModal = () => {
                    this.setOpenSignModal(false);
                    this.props.history.goBack()
                }
                return (
                    <SignModal
                        signModal={this.state.openSignModal}
                        closeSignModal={closeSignModal}
                    />
                )
            } else {
                return <img alt="loading" src="./img/45.svg" />
            }
        }
    }
}

function mapStateToProps ({ authedUser, loadingBar, teachingRequeasts }) {
	return {
		authedUser,
        loadingBar,
        teachingRequeasts
	}
}

export default withTranslation()(connect(mapStateToProps)(SignUpTeacher));

const SignModal = props => {
    const { t } = useTranslation();
    const closeModal = () => props.closeSignModal()

    return (
        <Modal
            show={props.signModal}
            onHide={closeModal}
            aria-labelledby="sign-modal"
            className="signup"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <h6 className="text-center"><Alert variant="warning">{t('auth.You have to register a user first')}<br/>
                    {t('auth.Or login if you are already user')}.
                </Alert></h6>
            </Modal.Body>
        </Modal>
    )
}

const TeacherModal = ({ teacherModal, closeTeacherModal }) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={teacherModal}
            onHide={closeTeacherModal}
            aria-labelledby="teacher-modal"
            className="teacher-modal"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <h6 className="text-center"><Alert variant="warning">{t('auth.You are already Teacher!.')}</Alert></h6>
            </Modal.Body>
        </Modal>
    )
}

const CertificationModal = (props) => {
    const { t } = useTranslation();
    const { certificationModal, handleDeleteCertification, certification, closeCertificationModal } = props;

    return (
        <Modal
            show={certificationModal}
            onHide={closeCertificationModal}
            aria-labelledby="certification-modal"
            className="text-center signup"
            size="xl"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <img alt="certification" src={certification} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeCertificationModal}>{t('Close')}</Button>
                <Button variant="danger" onClick={() => handleDeleteCertification(certification)}>{t('Delete')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

