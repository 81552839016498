import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getUserByUid, addUserToOurTeachers, deleteUserFromOurTeachers, getOurTeachers } from '../utils/firebase';

export const GET_OUR_TEACHERS_USERS = 'GET_OUR_TEACHERS_USERS';
export const ADD_USER_TO_OUR_TEACHERS = 'ADD_USER_TO_OUR_TEACHERS';
export const DELETE_USER_FROM_OUR_TEACHERS = 'DELETE_USER_FROM_OUR_TEACHERS';

function getHomeTeachers (users, error=null) {
    return {
        type: GET_OUR_TEACHERS_USERS,
        users,
        error
    }
}

export function handleGetOurTeachers() {
    return (dispatch) => {
        dispatch(showLoading())
        return getOurTeachers()
        .then(result => {
            dispatch(hideLoading())
            return dispatch(getHomeTeachers(result))
        })
    } 
}

function submitUserToOurTeachers(user, error=null) {
	return {
        type: ADD_USER_TO_OUR_TEACHERS,
        user,
        error
	};
}

export function handleSubmitUserToOurTeachers(uid) {
    return (dispatch) => {
        dispatch(showLoading())
        return addUserToOurTeachers(uid)
        .then(() => {
            getUserByUid(uid).then(doc => {
                dispatch(hideLoading())
                return dispatch(submitUserToOurTeachers(doc.data()))
            })
        }).catch((error) => {
            console.error("Error adding document: ", error);
            dispatch(hideLoading())
            return dispatch(submitUserToOurTeachers(null,error))
        })
    } 
}

function submitDeleteUserFromOurTeachers(uid, error=null) {
	return {
        type: DELETE_USER_FROM_OUR_TEACHERS,
        uid,
        error
	};
}

export function handleSubmitDeleteUserFromOurTeachers(uid) {
    return (dispatch) => {
        dispatch(showLoading())
        return deleteUserFromOurTeachers(uid)
        .then(() => {
            dispatch(hideLoading())
            return dispatch(submitDeleteUserFromOurTeachers(uid))
        }).catch((error) => {
            dispatch(hideLoading())
            return dispatch(submitDeleteUserFromOurTeachers(null,error))
        })
    } 
}