import React , { Component } from 'react';
import { Table, Button, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { handleReceiveContactUsMessages, handleDeleteContactUsMessage } from '../../store/actionCreators/conactUs';

class ContactUs extends Component {
    state = {
        modalShow: false,
        alertError: false,
        error: ''
    }
    
    componentDidMount() {
        this.props.dispatch(handleReceiveContactUsMessages())
  	}

    deleteFree = (id) => {
        this.setState({alertError: false, error: ''})
        this.props.dispatch(handleDeleteContactUsMessage(id))
        .then((result) => {
            if (result.error !== null) {
                this.setState({error: result.error.message, alertError: true})
            }
        })
    }

    formatDate = time => {
        const date = time.toDate();
        return (date.toLocaleString('default', { month: 'long' })) + " " + date.getDate()+ ", " +  date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
    }

    render() {
        // console.log(this.props)
        
        return (
            <React.Fragment>
            <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
            {this.props.contactUs
                ? this.props.contactUs.map(message =>
                    <Table key={message.id} className="free-session" bordered responsive>
                        <tbody style={{textAlign:"center", verticalAlign:"middle"}}>
                            <tr>
                                <th style={{verticalAlign:"middle"}}>{this.props.contactUs.indexOf(message)+1}</th>
                                {/* {console.log(this.formatDate(message.timestamp))} */}
                                <th style={{verticalAlign:"middle"}}>{this.formatDate(message.timestamp)}</th>
                                <th style={{verticalAlign:"middle"}}><Button variant="danger" onClick={() => this.deleteFree(message.id)}>Delete</Button></th>
                            </tr>
                            <tr>
                                <th style={{verticalAlign:"middle"}}>{message.name}</th>
                                <th colSpan="2" style={{verticalAlign:"middle"}}>{message.email}</th>
                            </tr> 
                            <tr><td style={{whiteSpace: "initial"}} colSpan="4">{message.subject}</td></tr>
                            <tr><td style={{textAlign: "left", whiteSpace: "initial"}} colSpan="4">{message.message}</td></tr>
                        </tbody>
                    </Table>
                ) : null
            }
            </React.Fragment>
        )
    }
}

function mapDispatchToProps ({contactUs}) {
	return {
		contactUs
	};
}

export default connect(mapDispatchToProps) (ContactUs);