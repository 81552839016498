import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { deleteAudio, addAudio, uploadAudio } from '../utils/firebase';

export const GET_AUDIOS = 'GET_AUDIOS';
export const DELETE_AUDIO = 'DELETE_AUDIO';
export const ADD_AUDIO = 'ADD_AUDIO';

export function getAudios (audios, error=null) {
    return {
        type: GET_AUDIOS,
        audios,
        error
    }
}

function removeAudio (id, error=null) {
    return {
        type: DELETE_AUDIO,
        id,
        error
    }
}

export function handleDeleteAudio(id,src) {
    return (dispatch) => {
        dispatch(showLoading())
        return deleteAudio(id,src)
        .then(() => {
            dispatch(hideLoading())
            return dispatch(removeAudio(id))
        }).catch(error => {
            dispatch(hideLoading())
            return dispatch(removeAudio(null,error))
        })
    }
}

function newAudio (audio, error=null) {
    return {
        type: ADD_AUDIO,
        audio,
        error
    }
}

export function handleAddAudio(src,title) {
    return (dispatch) => {
        dispatch(showLoading())
        return addAudio(src,title)
        .then(doc => {
            let aud = {
                id: doc.id,
                src, title
            }
            dispatch(hideLoading())
            return dispatch(newAudio(aud))
        }).catch(error => {
            dispatch(hideLoading())
            return dispatch(newAudio(null,error))
        })
    }
}

export function handleUploadAudio(file,title) {
    return (dispatch) => {
        dispatch(showLoading())
        return uploadAudio(file,title)
        .then(result => {
           if (result.id) {
               dispatch(hideLoading())
               return dispatch(newAudio(result))
           } else {
                dispatch(newAudio(null,result.error))
                dispatch(hideLoading())
                return new Promise((res, rej) => res(result))
           }
        }).catch(error => {
            console.log(error)
        })
    }
}

/*
export function handleReceiveAudios () {
    return (dispatch) => {
        dispatch(showLoading())
        return getAudios()
        .then((audios) => {
            let result = [];
			audios.forEach((doc) => {
				let vid = {id: doc.id, ...doc.data()}
				result.push(vid)
			})
			dispatch(hideLoading())
            return dispatch(getArticles(result))
        })
        .catch((error) => {
            dispatch(hideLoading())
            return dispatch(getArticles(null,error))
        })
    }
}
*/