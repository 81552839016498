import React , { Component } from 'react';
import { Table, Button, NavDropdown, Modal, Form, InputGroup, Row, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { handleSubmitSetZoom } from '../../store/actionCreators/users';

class Users extends Component {
    state = {
        openZoomModal: null,
        openDetailsModal: null
    }
    
    componentDidMount() {
    	// this.props.dispatch(handleGetAllUsers())
  	}

    setOpenZoomModal = value => this.setState({openZoomModal: value})
    setOpenDetailsModal = value => this.setState({openDetailsModal: value})
    
    render() {
        // console.log(this.props)
        if (this.props.loadingBar.default) {
            return (
                <div className="m-auto text-center">
                    <img src='/img/45.svg' alt="loading" />
                </div>
            ) 
        } else {
        
        return (
            <Table className="admin-users p-2" responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Gender</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody><tr><td colSpan="5" className="text-center" style={{backgroundColor: "#004E6E", color: "#fff"}}>Admins</td></tr></tbody>
                <tbody>
                    {this.props.users && this.props.users.filter(u => u.customClaims && u.customClaims.admin).map((user, index) =>
                            <tr key={user.uid}>
                                <td>{index+1}</td>
                                <td>{user.displayName}</td>
                                <td>{user.email}</td>
                                <td>{user.gender}</td>
                                <td>
                                    <NavDropdown title="" id="collasible-nav-dropdown">
                                        <Button variant="info" className="dropdown-item" onClick={() => this.setOpenDetailsModal(user.uid)}>Details</Button>
                                    </NavDropdown>
                                </td>
                            </tr>
                    )}
                </tbody>
                <tbody><tr><td colSpan="5" style={{backgroundColor: "#004E6E", color: "#fff"}} className="text-center">Teachers</td></tr></tbody>
                <tbody>
                    {this.props.users && this.props.users.filter(u => u.state === "teacher").map((user, index) =>
                            <tr key={user.uid}>
                                <td>{index+1}</td>
                                <td>{user.displayName}</td>
                                <td>{user.email}</td>
                                <td>{user.gender}</td>
                                <td>
                                    <NavDropdown title="" id="collasible-nav-dropdown">
                                        <Button variant="info" className="dropdown-item" onClick={() => this.setOpenZoomModal(user.uid)}>Zoom Number</Button>
                                        <Button variant="info" className="dropdown-item" onClick={() => this.setOpenDetailsModal(user.uid)}>Details</Button>
                                    </NavDropdown>
                                </td>
                                <td>
                                    <DetailsModal
                                        user={user}
                                        DetailsModal={this.state.openDetailsModal===user.uid}
                                        closeDetailsModal={() => this.setOpenDetailsModal(false)}
                                        dispatch={this.props.dispatch}
                                    />
                                </td>
                                <td>
                                    <ZoomModal
                                        user={user}
                                        zoomModal={this.state.openZoomModal===user.uid}
                                        closeZoomModal={() => this.setOpenZoomModal(false)}
                                        dispatch={this.props.dispatch}
                                    />
                                    <DetailsModal
                                        user={user}
                                        DetailsModal={this.state.openDetailsModal===user.uid}
                                        closeDetailsModal={() => this.setOpenDetailsModal(false)}
                                        dispatch={this.props.dispatch}
                                    />
                                </td>
                            </tr>
                    )}
                </tbody>
                <tbody><tr><td colSpan="5" style={{backgroundColor: "#004E6E", color: "#fff"}} className="text-center">Users</td></tr></tbody>
                <tbody>
                    {this.props.users && this.props.users.filter(u => u.state === "student" && (!u.customClaims || !u.customClaims.admin)).map((user, index) =>
                            <tr key={user.uid}>
                                <td>{index+1}</td>
                                <td>{user.displayName}</td>
                                <td>{user.email}</td>
                                <td>{user.gender}</td>
                                <td>
                                    <NavDropdown title="" id="collasible-nav-dropdown">
                                        <Button variant="info" className="dropdown-item" onClick={() => this.setOpenDetailsModal(user.uid)}>Details</Button>
                                    </NavDropdown>
                                </td>
                                <td>
                                    <DetailsModal
                                        user={user}
                                        DetailsModal={this.state.openDetailsModal===user.uid}
                                        closeDetailsModal={() => this.setOpenDetailsModal(false)}
                                        dispatch={this.props.dispatch}
                                    />
                                </td>
                            </tr>
                    )}
                </tbody>
            </Table>
        )
                    }
    }
}

function mapDispatchToProps ({users, loadingBar}) {
	return {
		users, loadingBar
	};
}

export default connect(mapDispatchToProps) (Users);

class ZoomModal extends Component {
    state = {
        zoomNumber: "",
        zoomPassword: "",
        error: '',
        alertError: false
    }

    componentDidMount() {
        this.setState({
            zoomNumber: this.props.user.zoomNumber,
            zoomPassword: this.props.user.zoomPassword
        })
    }

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeZoomModal()
    }

    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    handleSubmit = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.dispatch(handleSubmitSetZoom(this.props.user.uid,this.state.zoomNumber,this.state.zoomPassword))
        .then((result) => {
            if (result.error===null) {
                this.props.closeZoomModal()
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }
  
    render() {
        // console.log(this.props)
        const { zoomModal } = this.props;
        return (
            <Modal
                show={zoomModal}
                onHide={this.closeModal}
                aria-labelledby="zoom-modal"
                className="signup"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Zoom Number
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} controlId="validationFormikUsername">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">Zoom Number</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    style={{border: "1px solid #ced4da"}}
                                    type="text"
                                    placeholder="Zoom Number"
                                    aria-describedby="inputGroupPrepend"
                                    name="zoomNumber"
                                    value={this.state.zoomNumber}
                                    onChange={this.handleChange}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Row} controlId="validationFormikUsername">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">Zoom Password</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    style={{border: "1px solid #ced4da"}}
                                    type="text"
                                    placeholder="Zoom Password"
                                    aria-describedby="inputGroupPrepend"
                                    name="zoomPassword"
                                    value={this.state.zoomPassword}
                                    onChange={this.handleChange}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                    <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Cancel</Button>
                    <Button variant="danger" onClick={this.handleSubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

class DetailsModal extends Component {
    state = {
        zoomNumber: "",
        zoomPassword: "",
        error: '',
        alertError: false
    }

    componentDidMount() {
        this.setState({
            zoomNumber: this.props.user.zoomNumber,
            zoomPassword: this.props.user.zoomPassword
        })
    }

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeDetailsModal()
    }

    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    handleSubmit = () => {
        
    }
  
    render() {
        // console.log(this.props)
        const { DetailsModal } = this.props;
        return (
            <Modal
                show={DetailsModal}
                onHide={this.closeModal}
                aria-labelledby="details-modal"
                className="signup"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        User Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} controlId="validationFormikUsername">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">Recieve E-mails</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    style={{border: "1px solid #ced4da"}}
                                    type="text"
                                    placeholder="Zoom Number"
                                    aria-describedby="inputGroupPrepend"
                                    name="zoomNumber"
                                    value={this.props.user.recieveEmails}
                                    onChange={this.handleChange}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                    <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>close</Button>
                    {/* <Button variant="danger" onClick={this.handleSubmit}>Submit</Button> */}
                </Modal.Footer>
            </Modal>
        )
    }
}
