import { showLoading, hideLoading } from 'react-redux-loading-bar';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { userDoc, getMyInvoices, getAllInvoices } from '../utils/firebase';
import { fawaterkPay } from '../utils/fawaterk'

export const CREATE_INVOICE = 'CREATE_INVOICE';
export const GET_MY_INVOICES = 'GET_MY_INVOICES';
export const GET_ALL_INVOICES = 'GET_ALL_INVOICES';

function submitNewInvoice(invoice,error=null) {
    return {
        type: CREATE_INVOICE,
        invoice,
		error
	};
}

export function handleSubmitNewInvoice(productName, price) {
	return (dispatch) => {
        dispatch(showLoading())
        const user = firebase.auth().currentUser;
        if (user && user!==null) {
            return userDoc(user).then((result)=> {
                const displayName = result.displayName === undefined || null ? "unkown" : result.displayName
                const phoneNumber = (result.phoneNumber===undefined || result.phoneNumber===null) ? "unkown" : result.phoneNumber
                let address = "unkown"
                if ((result.address !== undefined || null) && (result.country !== undefined || null)) {
                    address = result.country + " " + result.address
                } else if (result.address !== undefined || null) {
                    address = result.address
                } else if (result.country !== undefined || null) {
                    address = result.country
                }
                // const address = ((result.address === undefined || null) && (result.country === undefined || null)) ? "unkown" : result.address
                return fawaterkPay(productName, price, displayName, result.email, phoneNumber, address, result.uid)
                .then(invoice => {
                    if (invoice.error) {
                        dispatch(hideLoading())
                        return dispatch(submitNewInvoice(null,invoice.error))
                    } else {
                        dispatch(hideLoading())
                        return dispatch(submitNewInvoice(invoice))
                    }
                })
                .catch(error => {
                    dispatch(hideLoading())
                    return dispatch(submitNewInvoice(null, error))
                });
            })
        } else {
            dispatch(hideLoading())
            const result = {
                error: {
                    message: "You have to register a new account or Login first."
                }
            }
            dispatch(submitNewInvoice(null, result.error))
            return new Promise((res, rej) => {
                res(result)
            })
        }
    }
}

function receiveMyInvoices (invoices, error=null) {
    return {
        type: GET_MY_INVOICES,
        invoices,
        error
    }
}

export function handleReceiveMyInvoices() {
    return (dispatch) => {
        dispatch(showLoading())
        return firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                return getMyInvoices(user.uid)
                .then((invoices) => {
                    let result = [];
                    invoices.forEach((doc) => {
                        let inv = {id: doc.id, ...doc.data()}
                        result.push(inv)
                    })
                    dispatch(hideLoading())
                    return dispatch(receiveMyInvoices(result))
                }).catch(error => {
                    dispatch(hideLoading())
                    return dispatch(receiveMyInvoices(null, error))
                })
            }
        })
    }
}

function receiveAllInvoices (invoices, error=null) {
    return {
        type: GET_ALL_INVOICES,
        invoices,
        error
    }
}

export function handleReceiveAllInvoices() {
    return (dispatch) => {
        dispatch(showLoading())
        return getAllInvoices()
        .then((invoices) => {
            let result = [];
            invoices.forEach((doc) => {
                let inv = {id: doc.id, ...doc.data(), user: {}}
                doc.data().user.get()
                .then(us => inv.user = {...us.data()})
                result.push(inv)
            })
            dispatch(hideLoading())
            return dispatch(receiveAllInvoices(result))
        }).catch(error => {
            dispatch(hideLoading())
            return dispatch(receiveAllInvoices(null, error))
        })
    }
}
