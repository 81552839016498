import { RECEIVE_FREE_SESSIONS, SUBMIT_FREE_SESSION, DELETE_FREE_SESSION } from '../actionCreators/freeSession';

export default function freeSession (state = [], action) {
    switch (action.type) {
        case RECEIVE_FREE_SESSIONS :
            return action.freeSessions
        case SUBMIT_FREE_SESSION :
            return action.payload===null ? state : state.concat(action.payload)
        case DELETE_FREE_SESSION :
            return state.filter(free => free.id !== action.id)
        default :
            return state
    }
} 