import React, { Component } from 'react';
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class HomeWorkRecent extends Component {
    state = {
        homeworks: []
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="dsh-head-with-btn">
                    <h3 className="dsh-title">{t('dashboard.Home Work')}</h3>
                </div>
                <div className="dsh-body">
                    {/* <div className="homework-types-wrapper">
                        <Link className="homework-type-box" to="/dashboard/homework/tajweed/home">
                            <span>Tajweed</span>
                        </Link>
                        <Link className="homework-type-box" to="/dashboard/homework/pronunciation/home">
                            <span>
                                Quran correct
                                pronunciation
                            </span>
                        </Link>
                        <Link className="homework-type-box" to="/dashboard/homework/arabic/home">
                            <span>
                                Arabic language
                            </span>
                        </Link>
                        <Link className="homework-type-box" to="/dashboard/homework/memorization/home">
                            <span>
                                Quran memorization
                            </span>
                        </Link>
                    </div> */}
                    <div className="resent-homework-wrapper">
                        <h3 className="rh-title">{t('dashboard.Home Work')}</h3>
                        <ul className="resent-homework list-unstyled">
                            {this.props.classes && this.props.classes!==null && this.props.classes.map(clas => 
                                <React.Fragment key={clas.id}>
                                    {clas.homeworks && clas.homeworks.map(hw =>
                                        <Link key={hw.id} to={`/dashboard/homework/${clas.id}/${hw.id}`}>
                                            <span className="hw-type">{hw.title}</span>
                                            <span className="hw-st-data">
                                                <span className="st-name">{clas.title}</span>
                                            </span>
                                            {clas.teacher
                                                ? <span className="hw-st-data">
                                                    <span className="st-name">{clas.teacher.displayName}</span>
                                                </span>
                                                : null
                                            }
                                        </Link>
                                    )}
                                </React.Fragment>
                            )}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps ({ classes }) {
	return {
        classes
	}
}

export default withTranslation()(connect(mapStateToProps)(HomeWorkRecent));

/*
Commented code may be wanted:
<Link to="/dashboard/homework/tajweed/1">
    <span className="hw-st-data">
        <span className="hw-st-avatar single-st-avatar">
            <img src="/img/st.png" alt="Student Avatar"/>
        </span>
        <span className="st-name">Waseema Karam</span>
    </span>
    <span className="hw-type">Tajweed</span>
</Link>
<Link to="/dashboard/homework/tajweed/2">
    <span className="hw-st-data">
        <span className="hw-st-avatar group-st-avatar">
            <img src="/img/st2.png" alt="Student Avatar"/>
            <img src="/img/st3.png" alt="Student Avatar"/>
        </span>
        <span className="st-name">Group 1</span>
    </span>
    <span className="hw-type">Quran correct pronunciation</span>
</Link>
<Link to="/dashboard/homework/tajweed/3">
    <span className="hw-st-data">
        <span className="hw-st-avatar single-st-avatar">
            <img src="/img/st.png" alt="Student Avatar"/>
        </span>
        <span className="st-name">Waseema Karam</span>
    </span>
    <span className="hw-type">Arabic language</span>
</Link>
*/