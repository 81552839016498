import { showLoading, hideLoading } from 'react-redux-loading-bar';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { signIn, signUp, logout, updateUserDoc, userDoc, deleteCertification, addAppointment, deleteAppointment, changeAppointment, resetPassword, uploadUserAvatar } from '../utils/firebase';

const db = firebase.firestore();
db.settings({ ignoreUndefinedProperties: true })

export const SET_AUTHED_USER = 'SET_AUTHED_USER';
export const NEW_USER = 'NEW_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPLOAD_CERTIFICATION = 'UPLOAD_CERTIFICATION';
export const DELETE_CERTIFICATION = 'DELETE_CERTIFICATION';
export const ADD_APPOINMENT = 'ADD_APPOINMENT';
export const DELETE_APPOINMENT = 'DELETE_APPOINMENT';
export const CHANGE_APPOINMENT = 'CHANGE_APPOINMENT';

function setAuthedUser (user, error=null) {
    return {
        type: SET_AUTHED_USER,
        user,
        error
    }
}

export function handleSetAuthedUser () {
    return (dispatch) => {
        dispatch(showLoading())
        return firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // console.log(user)
                return userDoc(user).then((result)=> {
                    dispatch(hideLoading())
                    return dispatch(setAuthedUser(result))
                })
            } else {
                dispatch(hideLoading())
                return dispatch(setAuthedUser(null))
            }
        })
    }
}

export function handleSignIn (email,password) {
    return (dispatch) => {
        dispatch(showLoading())
        return signIn(email,password)
        .then((userCredential) => {
            // console.log(userCredential)
            dispatch(hideLoading())
            return dispatch(setAuthedUser(userCredential))
        })
        .catch((error) => {
            dispatch(hideLoading())
            return dispatch(setAuthedUser(null,error))
        })
        
    }
}

export function handleNewUser ({email,password,displayName,gender,photoURL,receiveEmails}) {
    return (dispatch) => {
        dispatch(showLoading())
        return signUp(({email,password,displayName,gender,photoURL,receiveEmails}))
        .then((userCredential) => {
            // dispatch(newUser(userCredential,null));
            dispatch(hideLoading())
            return userDoc(userCredential).then((result) => dispatch(setAuthedUser(result)))
        })
        .catch((error) => {
            dispatch(hideLoading())
            return dispatch(setAuthedUser(null,error))
        })
    }
}

export function handleUpdateProfile ({displayName,email,avatarURL,gender,country,timeZone,aboutMe,learn, address}) {
    return (dispatch) => {
        const user = firebase.auth().currentUser; 
        if (displayName!==user.displayName || avatarURL!==user.photoURL) {
            if (avatarURL!==user.photoURL) {
                if (typeof(avatarURL) === "object") {
                    uploadUserAvatar(user.uid,avatarURL).then(url => {
                        user.updateProfile({
                            displayName: displayName,
                            photoURL: url
                        }).catch((error) => userDoc(user).then((result) => {
                            return dispatch(setAuthedUser(result,error))
                        }))
                        db.collection('users').doc(user.uid).set({
                            photoURL: url,
                        }, { merge: true });
                    })
                } else {
                    user.updateProfile({
                        displayName: displayName,
                        photoURL: avatarURL
                    }).catch((error) => userDoc(user).then((result) => {
                        return dispatch(setAuthedUser(result,error))
                    }))
                    db.collection('users').doc(user.uid).set({
                        photoURL: avatarURL,
                    }, { merge: true });
                }
            } else {
                user.updateProfile({
                    displayName: displayName
                }).catch((error) => userDoc(user).then((result) => {
                    return dispatch(setAuthedUser(result,error))
                }))
            }
        }
                    
        if(email!==user.email) {
            return user.updateEmail(email).then(() => {
                return updateUserDoc({displayName,email,avatarURL: user.photoURL,gender,country,timeZone,aboutMe,learn, address})
                .then(()=> userDoc(user).then((result) => {
                    dispatch(setAuthedUser(result))
                    return dispatch(setAuthedUser(result))
                }))
                .catch((error) => userDoc(user).then((result) => {
                    console.log(error);
                    db.collection("users").doc(user.uid).get().then((doc) => user.updateEmail(doc.data().email))
                    return dispatch(setAuthedUser(result,error))
                }))
            }).catch((error) => userDoc(user).then((result) => {
                return dispatch(setAuthedUser(result,error))
            }))
        } else {
            return updateUserDoc({displayName,email,avatarURL: user.photoURL,gender,country,timeZone,aboutMe,learn, address})
            .then(()=> userDoc(user).then((result) => {
                dispatch(setAuthedUser(result))
                return dispatch(setAuthedUser(result))
            }))
            .catch((error) => userDoc(user).then((result) => {
                console.log(error);
                db.collection("users").doc(user.uid).get().then((doc) => user.updateProfile({
                    displayName: doc.data().displayName,
                    photoURL: doc.data().avatarURL
                }))
                return dispatch(setAuthedUser(result,error))
            }))
        }                                           
    }
}

export function handleLogout () {
    return (dispatch) => {
        return logout()
        .then(() => dispatch(setAuthedUser(null)))
    }
}

function submitUploadCertification (url, error=null) {
    return {
        type: UPLOAD_CERTIFICATION,
        url,
        error
    }
}

export function handleSubmitUploadCertification (file) {
    return (dispatch) => {
        dispatch(showLoading())
        const user = firebase.auth().currentUser; 
        const uploadTask = firebase.storage().ref().child(`users/${user.uid}/certifications/${file.name}`).put(file);
        return new Promise((res, rej) => {
            var result = {};
            return uploadTask.on('state_changed', snapshot => {}, error => {
                if (error) {
                    // console.log("Image could not be uploaded: " + error.code+ ".Try reload the page and try again");
                    result.error = error
                    dispatch(submitUploadCertification(null, error))
                    dispatch(hideLoading())
                    return res({result: null, error: {...error}})
                }
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then(function(url) {
                    db.collection("users").doc(user.uid).set({
                        certifications: firebase.firestore.FieldValue.arrayUnion(url)
                    }, {merge: true})
                    result.url = url
                    result.fileName = file.name
                    dispatch(submitUploadCertification(url))
                    dispatch(hideLoading())
                    return res(result)
                });
            }); 
        })
    }
}

function submitDeleteCertification (url, error=null) {
    return {
        type: DELETE_CERTIFICATION,
        url,
        error
    }
}

export function handleSubmitDeleteCertification (url) {
    return (dispatch) => {
        dispatch(showLoading())
        return deleteCertification(url)
        .then(() => {
            dispatch(hideLoading())
            return dispatch(submitDeleteCertification(url))
        })
        .catch((error) => {
            dispatch(hideLoading())
            return dispatch(submitDeleteCertification(null, error))
        });
    }
}

function submitNewAppointment (appointment, error=null) {
    return {
        type: ADD_APPOINMENT,
        appointment,
        error
    }
}

export function handleSubmitNewAppointment(appointment) {
    return (dispatch) => {
        return addAppointment(appointment)
        .then(doc => {
            let app = {id: doc.id, ...appointment}
            return dispatch(submitNewAppointment(app))
        })
        .catch((error) => {
            return dispatch(submitNewAppointment(null, error))
        });
    }
}

function submitDeleteAppointment (appointment, error=null) {
    return {
        type: DELETE_APPOINMENT,
        appointment,
        error
    }
}

export function handleSubmitDeleteAppointment(id) {
    return (dispatch) => {
        return deleteAppointment(id)
        .then(() => {
            return dispatch(submitDeleteAppointment(id))
        })
        .catch((error) => {
            return dispatch(submitDeleteAppointment(null, error))
        });
    }
}

function submitChangeAppointment (appointment, error=null) {
    return {
        type: CHANGE_APPOINMENT,
        appointment,
        error
    }
}

export function handleSubmitChangeAppointment(appointment) {
    return (dispatch) => {
        return changeAppointment(appointment)
        .then(() => {
            return dispatch(submitChangeAppointment(appointment))
        })
        .catch((error) => {
            return dispatch(submitChangeAppointment(null, error))
        });
    }
}

export function handleResetPassword (email) {
    return (dispatch) => {
        return resetPassword(email);
    }
}

/*
Old functions may be wanted :)
function updateUser (user, error=null) {
    return {
        type: UPDATE_USER,
        user,
        error
    }
}

function newUser (user, error=null) {
    return {
        type: NEW_USER,
        user,
        error
    }
}
*/