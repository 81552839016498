import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getTeachingRequests, newTeachingRequest, deleteTeachingRequest } from '../utils/firebase';

export const NEW_REQUEST = 'NEW_REQUEST';
export const RECIEVE_REQUESTS = 'RECIEVE_REQUESTS';
export const DELETE_REQUEST = 'DELETE_REQUEST';

function submitNewRequest(request,error=null) {
    return {
        type: NEW_REQUEST,
        request,
		error
	};
}

export function handleSubmitNewRequest({aboutMe,learn, certifications,clip}) {
	return (dispatch) => {
        dispatch(showLoading())
        return newTeachingRequest({aboutMe,learn, certifications,clip})
        .then(() => {
            const user = firebase.auth().currentUser; 
            let request = {
                aboutMe,
                learn,
                certifications,
                clip,
                displayName: user.displayName,
                email: user.email,
                uid: user.uid
            }
            dispatch(hideLoading())
            return dispatch(submitNewRequest(request))
        }).catch(error => {
            dispatch(hideLoading())
            return dispatch(submitNewRequest(null, error))
        })
    }
}

function recieveTeachingRequests(requests,error=null) {
    return {
        type: RECIEVE_REQUESTS,
        requests,
		error
	};
}

export function handleRecieveTeachingRequests() {
    return (dispatch) => {
        dispatch(showLoading())
        return getTeachingRequests()
        .then((reqs) => {
            let result = [];
            reqs.forEach((doc) => {
                let req = {...doc.data(), displayName: "", email: "", gender: ""}
                doc.data().user.get().then(user => {
                    req.displayName = user.data().displayName;
                    req.email = user.data().email;
                    req.gender = user.data().gender;
                })
                
                result.push(req)
            })
            dispatch(hideLoading())
            dispatch(recieveTeachingRequests(result))
        }).catch((error) => {
            dispatch(hideLoading())
            dispatch(recieveTeachingRequests(null,error))
        })
    }
}

function deleteRequest(uid,error=null) {
    return {
        type: DELETE_REQUEST,
        uid,
		error
	};
}

export function handleDeleteRequest(uid) {
    return (dispatch) => {
        dispatch(showLoading())
        return deleteTeachingRequest(uid)
        .then(() => {
            dispatch(hideLoading())
            return dispatch(deleteRequest(uid))
        }).catch(error => {
            dispatch(hideLoading())
            return dispatch(deleteRequest(null,error))
        })
    }
}