import { GET_CONTACT_US_MESSAGES, SUBMIT_CONTACT_US_MESSAGE, DELETE_CONTACT_US_MESSAGE } from '../actionCreators/conactUs';

export default function contactUs (state = null, action) {
    switch (action.type) {
        case GET_CONTACT_US_MESSAGES :
            return action.contactUsMessages
        case SUBMIT_CONTACT_US_MESSAGE :
            return action
        case DELETE_CONTACT_US_MESSAGE :
            return state.filter(message => message.id !== action.id)
        default :
            return state
    }
} 