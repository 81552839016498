import React , { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class StudentClasses extends Component {
    typeTitle = type => {
        switch (type) {
            case "tajweed" :
                return "Tajweed"
            case "memorization" :
                return "Quran memorization"
            case "arabic" :
                return "Arabic Language"
            case "pronunciation" :
                return "Quran correct pronunciation"
            default :
                return ""
        }
    }

    formatDate = time => {
        const date = time.toDate();
        return (date.toLocaleString('default', { month: 'long' })) + " " + date.getDate()+ ", " +  date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
    }
    
    render() {
        return (
            <React.Fragment>
                {this.props.classes && this.props.classes.map(clas => 
                    <div className="upcoming-classes my-classes" key={clas.id}>
                        <h1 className="st-name">{clas.title}</h1>
                        <Link to={`/dashboard/${clas.id}/meeting`} className="main-btn light-green-btn mr-2 float-right">Join Meeting</Link>
                        <p>
                            <span className="sc-type">{this.typeTitle(clas.learn)}</span>
                            <span className="cls-start">Class start at </span>
                            <span className="cls-date">{this.formatDate(clas.start_at)}</span>
                        </p>
                        <p>
                            <span className="cls-start">Teacher </span>
                            <span className="cls-date mr-4">{clas.teacher.displayName}</span>
                            <span className="cls-date mr-4">{clas.teacher.email}</span>
                            <Link to="#" className="main-icon-btn mr-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                    <path data-name="Path 2107" d="M14.525,0H5.456A5.533,5.533,0,0,0,0,5.859v6.282A5.533,5.533,0,0,0,5.456,18h9.061a5.522,5.522,0,0,0,4-1.773A5.6,5.6,0,0,0,19.989,12.1V5.859A5.557,5.557,0,0,0,14.525,0ZM5.456,1.528h9.052A4,4,0,0,1,17.4,2.816a4.064,4.064,0,0,1,1.072,3v6.327A4.1,4.1,0,0,1,17.4,15.184a4,4,0,0,1-2.894,1.287H5.456a4.018,4.018,0,0,1-3.939-4.331V5.859A4.018,4.018,0,0,1,5.456,1.528Zm10.568,4.11a.755.755,0,0,0-1.066-.119l-4.1,3.3-.118.083A1.379,1.379,0,0,1,9.16,8.818l-4.129-3.3-.093-.064a.755.755,0,0,0-.973.187.768.768,0,0,0,.123,1.074l4.134,3.3.161.118a2.889,2.889,0,0,0,3.416-.114l4.107-3.311.082-.077A.768.768,0,0,0,16.024,5.638Z" fill="#fff" fillRule="evenodd"/>
                                </svg>
                            </Link>
                        </p>
                        <hr/>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

function mapStateToProps ({ classes }) {
	return {
        classes
	}
}

export default connect(mapStateToProps)(StudentClasses);