import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Card, Button, Modal, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import "firebase/storage";

import { handleSubmitDeleteArticle } from '../../../store/actionCreators/articles';

class ListArticles extends Component {
    state = {
        error: "",
        alertError: false,
        openDeleteModal: null
    }

    setOpenDeleteModal = value => this.setState({openDeleteModal: value})

    render() {
        // console.log(this.props)
        return (       
            <React.Fragment>
                <Link to="/admin/articles/new" className="main-btn btn--primary mb-2">Add New Article</Link>
                <div className="row mt-2">
                    {this.props.articles
                        ? this.props.articles.map(blog => 
                            <div className="col-md-6 mt-2" key={blog.id}>
                                <Card>
                                    <OverlayTrigger
                                        placement={"top"}
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <strong>Delete</strong>
                                            </Tooltip>
                                        }
                                    >
                                        <i className="delete-article fas fa-window-close" title="Delete this article" onClick={() => this.setOpenDeleteModal(blog.id)}></i>
                                    </OverlayTrigger>
                                    <h2 className="m-1">
                                        <Link className="btn btn-block text-left" to={`/admin/articles/${blog.id}`}>
                                            <h4>{blog.title}</h4>
                                        </Link>
                                    </h2>
                                    <DeleteModal
                                        article={blog}
                                        deleteModal={this.state.openDeleteModal===blog.id}
                                        closeDeleteModal={() => this.setOpenDeleteModal(null)}
                                        dispatch={this.props.dispatch}
                                    /> 
                                </Card> 
                            </div>
                        ) : null
                    }                       
                </div>
            </React.Fragment>
        )
    }
}
function mapStateToProps ({ articles }) {
	return {
		articles
	}
}

export default connect(mapStateToProps)(ListArticles);

class DeleteModal extends Component {
    state = {
        error: '',
        alertError: false
    }
  
    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeDeleteModal()
    }

    handleDelete = id => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.dispatch(handleSubmitDeleteArticle(id))
        .then((result) => {
            if (result.error===null) {
                this.props.closeDeleteModal()
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }
  
    render() {
        // console.log(this.props)
        const { deleteModal, article } = this.props;
        return (
            <Modal
                show={deleteModal}
                onHide={this.closeModal}
                aria-labelledby="delete-modal"
                className="signup"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Delete "{article.title}" article?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4><Alert variant="warning">Are you sure you want to delete "{article.title}" article?</Alert></h4>
                    <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Cancel</Button>
                    <Button variant="danger" onClick={() => this.handleDelete(article.id)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
