import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import ListArticles from './articles';
import AddArticle from './newArticle';
import EditArticle from './editArticle';

class Articles extends Component {
    render() {
        return (
            <Switch>
                <Route path="/admin/articles" exact component={ListArticles}/>
                <Route path="/admin/articles/new" component={AddArticle}/>  
                <Route path="/admin/articles/:id" component={EditArticle}/>  
            </Switch>
        )
    }
}
    function mapStateToProps ({ articles }) {
        return {
            articles
        }
    }
    
    export default connect(mapStateToProps)(Articles);