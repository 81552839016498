import { ADD_USER_TO_HONOR_BOARD, DELETE_USER_FROM_HONOR_BOARD, GET_HONOR_BOARD_USERS } from '../actionCreators/honorBoard';

export default function honorBoardUsers (state = null, action) {
    switch (action.type) {
        case GET_HONOR_BOARD_USERS :
            return action.users
        case ADD_USER_TO_HONOR_BOARD :
            return action.user===null ? state : state.concat(action.user);
        case DELETE_USER_FROM_HONOR_BOARD :
            return action.uid===null ? state : state.filter(stu => stu.uid !== action.uid)
        default :
            return state
    }
} 

