import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from "react-router-dom";
import { Modal, Alert } from 'react-bootstrap';

import { handleReceiveMyclasses } from '../../store/actionCreators/classes'

import DashboardSidebar from '../../components/Dashboard/DashboardSidebar';
import MainDashboardPage from '../../components/Dashboard/MainDashboardPage';
import SchedulePage from '../../components/Dashboard/SchedulePage';
import ClassroomPage from '../../components/Dashboard/ClassroomPage';
import HomeWorkPage from '../../components/Dashboard/HomeWork/HomeWorkPage';
import ProfilePage from '../../components/Dashboard/ProfilePage';
import NewClass from '../../components/Dashboard/MainDashboardPage/NewClass';
import StartMeeting from '../../components/Dashboard/MainDashboardPage/StartMeeting';
import ZoomMeeting from '../../components/Dashboard/MainDashboardPage/ZoomMeeting';
import StudentClasses from '../../components/Dashboard/MainDashboardPage/StudentClasses';
import InvoicesPage from '../../components/Dashboard/Invoices'
import HomeWorkNewPage from '../../components/Dashboard/HomeWork/HomeWorkNewPage'
import EditClass from '../../components/Dashboard/MainDashboardPage/EditClass';

class Dashboard extends Component {
    state = {
        openSignModal: true,
    }
    componentDidMount() {
        this.props.dispatch(handleReceiveMyclasses())
  	}

    url = () => {
        if (this.props.authedUser && this.props.authedUser.state==="teacher") {
            return (
                <Switch>
                    <Route path="/dashboard/main" component={MainDashboardPage} />
                    <Route path='/dashboard/schedule' component={SchedulePage} />
                    <Route path='/dashboard/new-class' component={NewClass} />
                    <Route path="/dashboard/class/:classId" component={EditClass} exact />
                    <Route path="/dashboard/:classId/new-homework" component={HomeWorkNewPage} />
                    <Redirect from="/dashboard" exact to='/dashboard/main'></Redirect>
                </Switch>
            )
        } if (this.props.authedUser && this.props.authedUser.state==="student") {
            return (
                <Switch>
                    <Route path="/dashboard/classes" component={StudentClasses} />
                    <Redirect from="/dashboard" to='/dashboard/profile'></Redirect>
                </Switch>
            )
        } else {
            if (!this.props.loadingBar.default) {
                const closeSignModal = () => {
                    this.setOpenSignModal(false);
                    this.props.history.goBack();
                }
                return (
                    <SignModal
                        signModal={this.state.openSignModal}
                        closeSignModal={closeSignModal}
                    />
                )
            } else {
                return <img alt="loading" src="./img/45.svg" />
            }
        }
    }

    setOpenSignModal = value => this.setState({openSignModal: value})
    
    render() {
        const { t } = this.props;
        document.title = `${t('header.My Dashboard')} - ${t('home.Hafs Quran')}`;
        
        return (
            <div className="dashboard">
                <div className='dashboard-page dashboard-with-sidebar'>
                    <DashboardSidebar />
                    <Switch>
                        <Route path='/dashboard/classroom' component={ClassroomPage} />
                        <Route path='/dashboard/homework' component={HomeWorkPage} />
                        <Route path='/dashboard/profile' component={ProfilePage} />
                        <Route path='/dashboard/invoices' component={InvoicesPage} />
                        <Route path='/dashboard/:classId/meeting' exact component={StartMeeting} />
                        <Route path='/dashboard/:classId/meeting/:password' component={ZoomMeeting} />
                        {this.url()}
                    </Switch>
                    
                </div>
            </div>
        );
        
    }
}

function mapStateToProps ({ authedUser, classes, loadingBar }) {
	return {
        authedUser,
        classes, 
        loadingBar
	}
}

export default withTranslation()(connect(mapStateToProps)(Dashboard));

const SignModal = props => {

    const closeModal = () => props.closeSignModal()

    return (
        <Modal
            show={props.signModal}
            onHide={closeModal}
            aria-labelledby="sign-modal"
            className="signup"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <h6 className="text-center"><Alert variant="warning">You have to register a user first<br/>
                    Or login if you are already user.
                </Alert></h6>
            </Modal.Body>
        </Modal>
    )
}