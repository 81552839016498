import { GET_VIDEOS, DELETE_VIDEO, ADD_VIDEO } from '../actionCreators/videos';

export default function videos (state = null, action) {
    switch (action.type) {
        case GET_VIDEOS :
            return action.videos
        case DELETE_VIDEO :
            return action.id===null ? state : state.filter(vid => vid.id !== action.id)
        case ADD_VIDEO :
            return state.concat(action.video)
        default :
            return state
    }
} 