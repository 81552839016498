import { GET_ALL_ARTICLES, ADD_ARTICLE, UPDATE_ARTICLE, DELETE_ARTICLE } from '../actionCreators/articles';

export default function articles (state = null, action) {
    switch (action.type) {
        case GET_ALL_ARTICLES :
            return action.articles
        case ADD_ARTICLE :
            return action.article===null ? state : state.concat(action.article)
        case UPDATE_ARTICLE :
            if (action.article===null) return state
            else {
                const article = state.find(art => art.id ===action.article.id)
                article.author = action.article.author
                article.title = action.article.title
                article.content = action.article.content
                article.created_at = action.article.created_at
                return state.filter(art => art.id!==action.article.id).concat(article)
            }
        case DELETE_ARTICLE :
            return action.id===null ? state : state.filter(art => art.id !== action.id)
        default :
            return state
    }
} 

