import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import ListVideos from './listVideos';
import AddVideo from './newVideo';

class Videos extends Component {
    render() {
        // console.log(this.props)
        return (
            <Switch>
                <Route path="/admin/videos" exact component={ListVideos}/>
                <Route path="/admin/videos/new" component={AddVideo}/>
            </Switch>
        )
    }
}

function mapStateToProps ({ videos }) {
    return {
        videos
    }
}
    
export default connect(mapStateToProps)(Videos);