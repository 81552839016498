import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Form, Button, Alert } from 'react-bootstrap';
import { handleNewUser } from '../../../store/actionCreators/authedUser';


const SignupComponent = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const emptyData = {
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
        gender: 'male',
        avatarURL: '/img/av1.svg',
        receiveEmails: true
    }
    const [formData, setFormData] = useState(emptyData);
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState('');
    const [alertError, setAlertError] = useState(false);

    useEffect(() => {
        setFormData(state => ({
            ...state,
            avatarURL: state.gender === 'male' ? '/img/av1.svg' : '/img/f-av1.svg'
        }))
    }, [formData.gender]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(state => ({
            ...state,
            [name]: value
        }));
    };
    
    const handleSubmit = event => {
        event.preventDefault();
        setAlertError(false)
        setError('');
        const form = event.currentTarget;
        // console.log(form.checkValidity())
        setValidated(true);
        const { password, passwordConfirm } = formData;
        if (form.checkValidity() === false && password.length<6 && password!==passwordConfirm) {
            event.stopPropagation();
        }
        if (form.checkValidity() === true && password.length>=6 && password===passwordConfirm) {
            dispatch(handleNewUser({
                ...formData,
                displayName: formData.name,
                photoURL: formData.avatarURL
            }))
            .then((result) => {
                if (result.error===null) {
                    props.closeSignupModal && props.closeSignupModal()
                    setFormData(emptyData);
                    history.push('/dashboard')
                } else {
                    setError(result.error.message);
                    setAlertError(true);
                }
            })
        }
    }

    const genders = [
        {
            value: 'male',
            label: t('auth.Male')
        },
        {
            value: 'female',
            label: t('auth.Female')
        }
    ]

    const avatars = {
        male: [
            "/img/av1.svg",
            "/img/av2.svg",
            "/img/av3.svg",
            "/img/av4.svg",
            "/img/av5.svg",
            "/img/av6.svg",
            "/img/av7.svg",
            "/img/av8.svg",
            "/img/av9.svg",
        ],
        female: [
            "/img/f-av1.svg",
            "/img/f-av2.svg",
            "/img/f-av3.svg",
            "/img/f-av4.svg",
            "/img/f-av5.svg",
            "/img/f-av6.svg",
            "/img/f-av7.svg",
            "/img/f-av8.svg",
            "/img/f-av9.svg",
        ]
    }
    
    return (
        <div className="container-sm signup" style={{ maxWidth: "900px" }}>
            <div className="form-wrapper">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Control 
                            type="email" 
                            placeholder={t('contact.Email address')}
                            required
                            value={formData.email} name='email'
                            onChange={handleChange} 
                            isInvalid={validated && formData.email===''} 
                        />
                        <span className="required-indicator" role="presentation"></span>
                        <Form.Control.Feedback type="invalid">
                            {t('auth.Please enter your email.')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control 
                            type="text" 
                            placeholder={t('contact.Name')}
                            required 
                            value={formData.name} name='name'
                            onChange={handleChange}
                            isInvalid={validated && formData.name===''} 
                        />
                        <span className="required-indicator" role="presentation"></span>
                        <Form.Control.Feedback type="invalid">
                            {t('auth.Please enter your name.')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Control 
                            type="password" 
                            placeholder={t('auth.Password')}
                            required
                            value={formData.password} name='password'
                            onChange={handleChange}
                            isInvalid={(validated && formData.password.length<6) ? true : false}
                        />
                        <span className="required-indicator" role="presentation"></span>
                        <Form.Control.Feedback type="invalid">
                            {t('auth.Please enter your password')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicConfirmPassword">
                        <Form.Control 
                            type="password" 
                            placeholder={t('auth.Confirm password')}
                            required
                            isInvalid={validated && formData.password!==formData.passwordConfirm}
                            value={formData.passwordConfirm} name='passwordConfirm'
                            onChange={handleChange}
                        />
                        <span className="required-indicator" role="presentation"></span>
                        <Form.Control.Feedback type="invalid">
                            {t('auth.Passwords must match')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicTeach">
                        <Form.Label>I can teach *</Form.Label>
                        <Form.Check 
                            type='checkbox'
                            id='pronunciation'
                            label='Quran correct pronunciation'
                        />
                        <Form.Check 
                            type='checkbox'
                            id='arabic'
                            label='Arabic Language'
                        />
                        <Form.Check 
                            type='checkbox'
                            id='memorization'
                            label='Quran memorization'
                        />
                        <Form.Check 
                            type='checkbox'
                            id='tajweed'
                            label='Tajweed'
                        />
                    </Form.Group> */}
                    <Form.Group>
                        <Form.Label>{t('auth.Gender')}</Form.Label>
                        <span className="required-indicator" role="presentation"></span>
                        {genders.map(gender =>
                            <Form.Check key={gender.value}
                                type="radio"
                                label={gender.label}
                                className="mr-5"
                                name="gender"
                                value={gender.value}
                                isInvalid={validated && formData.gender===''}  
                                onChange={handleChange}
                                inline required
                                checked={gender.value === formData.gender}
                            />
                        )}
                    </Form.Group>
                    <div className="avatars">
                        <div className="avatars__title">
                            <span>{t('auth.Select Avatar')}</span>
                        </div>
                        <div className="avatars__group">
                            {avatars[formData.gender].map((avatar, index) =>
                                <div key={avatar}
                                    className={(formData.avatarURL===avatar) ? "avatars__group--icon selected":"avatars__group--icon"}
                                    id={index+1}
                                    onClick={()=> setFormData(state => ({ ...state, avatarURL: avatar }))}
                                >
                                    <img src={avatar} alt="avatar"/>
                                </div>
                            )}
                        </div>
                    </div>
                    <Form.Check
                        type="checkbox"
                        label={t('auth.Do you agree to receive e-mails from us?')}
                        name="send"
                        defaultChecked
                        className="text-left mb-3"
                        onChange={e => setFormData(state => ({ ...state, receiveEmails: e.target.checked }))}
                    />
                    <Button type='submit' className="btn btn--primary">{t('auth.Create account')}</Button>
                </Form>
                <Alert show={alertError} className="m-2" variant='danger'>{error}</Alert>
            </div>
        </div>
    )
}

export default SignupComponent;