import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { handleSubmitContactUsMessage } from '../../store/actionCreators/conactUs';

class ContactusPage extends Component {
    state= {
        name: '',
        email: '',
        subject: '',
        message: '',
        error: '',
        alertSuccess: false,
        alertError: false
    }

    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    handleSubmit = (e) => {
        e.preventDefault();
        // console.log(this.state)
        this.setState({alertSuccess: false, alertError: false, error: ""})
        const { name, email, subject, message } = this.state
        
        this.props.dispatch(handleSubmitContactUsMessage({name,email,subject, message}))
        .then((result) => {
            if (result.error===null) {
                this.setState({alertSuccess: true})
                this.setState(() => ({
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                }))
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }
    
    render() {
        const { t, location: { pathname } } = this.props;
        
        // console.log(this.props);
        if(pathname.includes('contact')) {
            document.title = `${t('header.Contact Us')} - ${t('home.Hafs Quran')}`;
        }
        const { name, email, subject, message, error, alertSuccess, alertError } = this.state;
        return (
            <section className="contact-us">
                <div className="map-container" id='map'>
                    <img src="img/Rectangle 289.png" alt="map"/>
                </div>
                <div className="card">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="right-content">
                                <div className="right-content__container">
                                    <h2 className="right-content__container--title">
                                        {t('contact.Keep in touch Hafs')}
                                    </h2>
                                    <h3>
                                        {t('contact.Join thousands of learners inside Hafs School')}
                                    </h3>
                                    <div className="right-content__container--details">
                                        <div className="right-content__container--group">
                                            <a href="http://www.google.com"><FontAwesomeIcon icon={faMapMarkerAlt} />
                                                {t('contact.Technology innovation & entrepreneurship center')}
                                            </a>
                                        </div>
                                        <div className="right-content__container--group">
                                            <a href="http://www.google.com">
                                                <FontAwesomeIcon icon={faPhone} /> <span className='phone-number'>+201003439443</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="left-content">
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group controlId="formBasicName">
                                        <Form.Control name="name" type="text" placeholder={t('contact.Name')} value={name} onChange={this.handleChange} required />
                                        <span className="required-indicator" role="presentation"></span>
                                        {/* <Form.Label>Name</Form.Label> */}
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control name="email" type="email" placeholder={t('contact.Email address')} value={email} onChange={this.handleChange} required />
                                        <span className="required-indicator" role="presentation"></span>
                                        {/* <Form.Label>Email address</Form.Label> */}
                                    </Form.Group>
                                    <Form.Group controlId="formBasicSubject">
                                        <Form.Control name="subject" type="text" placeholder={t('contact.Subject')} value={subject} onChange={this.handleChange} required />
                                        <span className="required-indicator" role="presentation"></span>
                                        {/* <Form.Label>Subject</Form.Label> */}
                                    </Form.Group>
                                    <Form.Group controlId="formTextarea">
                                        <Form.Control name="message" as="textarea" placeholder={t('contact.Message')} value={message} rows={3} onChange={this.handleChange} required />
                                        <span className="required-indicator" role="presentation"></span>
                                        {/* <Form.Label>Message</Form.Label> */}
                                    </Form.Group>
                                    <Button className="btn btn--primary" type="submit">
                                        {t('contact.Send')}
                                    </Button>
                                </Form>
                                <Alert show={alertSuccess} className="m-2" variant='success'>{t("contact.Your message sent. We'll contact with you soon.")}</Alert>
                                <Alert show={alertError} className="m-2" variant='danger'>{error}</Alert>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="follow-us">
                    <div className="follow-us__title">
                        {t('contact.Follow us')}
                    </div>
                    <div className="follow-us__icons">
                        <a href="https://www.facebook.com/hafsquran.co" target="_blank" rel="noreferrer"><img src="img/fb.svg" alt="facebook"/></a>
                        <a href="https://www.youtube.com/channel/UCLYsjjY5WWTouDgDMvo9sRQ" target="_blank" rel="noreferrer"><img src="img/yt.svg" alt="youtube"/></a>
                        <a href="https://twitter.com/Hafsquran" target="_blank" rel="noreferrer"><img src="img/tw.svg" alt="twitter"/></a>
                        <a href="https://www.instagram.com/hafsquran.co" target="_blank" rel="noreferrer"><img src="img/ins.svg" alt="instgram"/></a>
                        <a href="https://wa.me/201003439443" target="_blank" rel="noreferrer"><img src="img/whatsapp.svg" alt="whatsapp"/></a>
                    </div>
                </div>
            </section>
        )
    }
}

function mapStateToProps ({ authedUser }) {
	return {
		authedUser
	}
}

export default withTranslation()(withRouter(connect(mapStateToProps)(ContactusPage)));