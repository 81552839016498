import { GET_AUDIOS, DELETE_AUDIO, ADD_AUDIO } from '../actionCreators/audios';

export default function audios (state = null, action) {
    switch (action.type) {
        case GET_AUDIOS :
            return action.audios
        case DELETE_AUDIO :
            return action.id===null ? state : state.filter(aud => aud.id !== action.id)
        case ADD_AUDIO :
            return action.audio===null ? state : state.concat(action.audio)
        default :
            return state
    }
} 