import React from 'react';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DashboardSidebar = props => {
    const { t } = useTranslation();
    // console.log(props)
    if (props.authedUser && props.authedUser.state ==="teacher") {
        return (
            <div className="dashboard__sidebar">
                <Nav className="flex-column" >
                    <Nav.Item>
                        <NavLink className="nav-link" to='/dashboard/main'>
                                {t('header.My Dashboard')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="nav-link" to='/dashboard/classroom'>
                                {t('dashboard.Classroom')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="nav-link" to='/dashboard/schedule'>
                                {t('dashboard.Schedule')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="nav-link" to='/dashboard/homework/home'>
                                {t('dashboard.Home Work')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="nav-link" to='/dashboard/profile'>
                            {t('dashboard.My Profile')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="nav-link" to='/dashboard/invoices'>
                            {t('dashboard.Invoices')}
                        </NavLink>
                    </Nav.Item>
                </Nav>
            </div>
        )
    } else {
        return (
            <div className="dashboard__sidebar">
                <Nav className="flex-column" >
                    <Nav.Item>
                        <NavLink className="nav-link" to='/dashboard/classes'>
                                {t('dashboard.My Classes')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="nav-link" to='/dashboard/profile'>
                            {t('dashboard.My Profile')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="nav-link" to='/dashboard/classroom'>
                                {t('dashboard.Classroom')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="nav-link" to='/dashboard/homework'>
                                {t('dashboard.Home Work')}
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="nav-link" to='/dashboard/invoices'>
                            {t('dashboard.Invoices')}
                        </NavLink>
                    </Nav.Item>
                </Nav>
            </div>
        )
    }
}

function mapStateToProps ({ authedUser }) {
	return {
        authedUser
	}
}

export default connect(mapStateToProps)(DashboardSidebar)