import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import ListTeachingRequests from './listTeachingRequests';
import Requeast from './request';

class TeachingRequeasts extends Component {
    render() {
        return (
            <Switch>
                <Route path="/admin/teaching-requests" exact component={ListTeachingRequests}/>
                <Route path="/admin/teaching-requests/:uid" component={Requeast}/>  
            </Switch>
        )
    }
}
    function mapStateToProps ({ teachingRequeasts }) {
        return {
            teachingRequeasts
        }
    }
    
    export default connect(mapStateToProps)(TeachingRequeasts);