import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { Modal, Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

const ArticlesCard = props => {
    const { t } = useTranslation();
    const { article } = props;
    const [openArticleModal, setOpenArticleModal] = useState(null);
    // console.log(props)
    return (
        <div className="articles__card">
            <h3 className="articles__card--title">
                {article.title}
            </h3>
            {article.author ===''
                ? <Link className="articles__card--author" to=""></Link>
                : <React.Fragment><span>{t('articles.By')} </span><Link className="articles__card--author" to="">{article.author}</Link></React.Fragment>
            }
            {/* <div className="articles__card--category">
                <span>News</span>
            </div> */}
            <p>
                {article.description}
            </p>
            {props.match.path === "/"
                ? <button className="btn--view" onClick={() => setOpenArticleModal(article.id)}>{t('articles.Read more')}</button>
                : <Link to={`/articles/${article.id}`} className="btn--view">{t('articles.Read more')}</Link>
            }
            
            <ArticleModal
                article={article}
                articleModal={openArticleModal===article.id}
                closeArticleModal={() => setOpenArticleModal(null)}
            />
        </div>
    )
}

export default withTranslation()(withRouter(ArticlesCard));


const ArticleModal = ({ article, articleModal, closeArticleModal }) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={articleModal}
            onHide={closeArticleModal}
            className="signup"
            size="xl"
            aria-labelledby="our-teachers-modal"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                    {article.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <h2 className="text-center header__content--title mt-4">{article.title}</h2>
                    {article.author!=="" && <p className="lead">{t('articles.by')}: <span>{article.author}</span></p>}
                    <p>{t('articles.Posted on')}: <span id="date">{article.created_at}</span></p>
                    <hr/>
                    <div className="text-justify mb-4">{ReactHtmlParser(article.content)}</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeArticleModal}>close</Button>
            </Modal.Footer>
        </Modal>
    )
}