import { RECIEVE_REQUESTS, NEW_REQUEST, DELETE_REQUEST } from '../actionCreators/teachingRequeasts';

export default function teachingRequeasts (state = null, action) {
    switch (action.type) {
        case RECIEVE_REQUESTS : 
            return action.requests
        case NEW_REQUEST :
            if(action.error===null) {
                return state
            } else {
                if (state.find(re => re.uid === action.request.uid)) {
                    return state;
                } else {
                    return state.concat(action.request)
                }
            }
        case DELETE_REQUEST :
            return action.uid===null ? state : state.filter(req => req.uid !== action.uid)
        default :
            return state
    }
} 