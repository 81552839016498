import React, { Component } from 'react';
import { Nav, Navbar, Button, NavDropdown, Spinner } from 'react-bootstrap';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import LoginModal from '../modals/Login';
import SignupModal from '../modals/Signup';
import { handleLogout } from '../../store/actionCreators/authedUser';

class Navigation extends Component {
    state = {
      openLoginModal: false,
      openSignupModal: false
    }

    // componentDidMount() {
    //   document.addEventListener('scroll', this.handleScroll, { passive: true })
    // }
  
    // componentWillUnmount() {
    //   document.removeEventListener('scroll', this.handleScroll)
    // }
  
    // handleScroll(event) {
    //   // console.log(event)
    // }
    // let listener = null document.getElementsById('zmmtg-root')
    // const [scrollState, setScrollState] = useState("top");
    // const [openLoginModal, setOpenLoginModal] = useState(false);
    // const [openSignupModal, setOpenSignupModal] = useState(false);
  
    // useEffect(() => {
    //   console.log(window.pageYOffset)
    //   listener = document.addEventListener("scroll", e => {
    //     var scrolled = document.scrollingElement.scrollTop
    //     if (scrolled >= 500) {
    //       if (scrollState !== "fixed") {
    //         setScrollState("fixed")
    //       }
    //     } else {
    //       if (scrollState !== "top") {
    //         setScrollState("top")
    //       }
    //     }
    //   })
    //   if (scrollState === "top") {
    //     document.getElementById('navbar').classList.remove("fixed");
    //   }else {
    //     document.getElementById('navbar').classList.add("fixed");
    //   }
    //   return () => {
    //     document.removeEventListener("scroll", listener)
    //   }
    // }, [scrollState]);
    setOpenLoginModal = value => this.setState({openLoginModal: value})
    setOpenSignupModal = value => this.setState({openSignupModal: value})
    
    logout = () => {
      this.props.dispatch(handleLogout())
    }

    handleChangeLang = (lang/*:'en'|'ar'*/) => {
      this.props.i18n.changeLanguage(lang);
    }
    
    render() {
      const { t, i18n } = this.props;
      const displayName = this.props.authedUser !== null && this.props.authedUser.displayName
      // console.log(this.state)
      return (
      <React.Fragment>
        <Navbar className={this.props.history.location.pathname==="/" ? "transparent container" : "container"} id="navbar" collapseOnSelect expand="lg" >
            <a className="navbar-brand" href={process.env.REACT_APP_PUBLIC_URL}>
                <img
                    src="/img/logo.png"
                    className="d-inline-block align-top"
                    alt="Hafs logo"
                    width="70px"
                />
            </a>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                  <a className="nav__item nav-link" exact href={process.env.REACT_APP_PUBLIC_URL}>{t('header.Home')}</a>
                  <NavLink className="nav__item nav-link" to="/articles">{t('header.Articles')}</NavLink>
                  <NavLink className="nav__item nav-link" to="/pricing">{t('header.Pricing')}</NavLink>
                  <a href={process.env.REACT_APP_PUBLIC_URL+"/about-us"} className="nav__item nav-link">{t('header.About')}</a>
                  {/* <NavLink className="nav__item nav-link" to="/games">Games</NavLink>
                  <NavLink className="nav__item nav-link" to="/find-teacher">Find Teacher</NavLink> */}
                  <a href={process.env.REACT_APP_PUBLIC_URL+"/contact-us"} className="nav__item nav-link">{t('header.Contact Us')}</a>
              </Nav>
              <Nav className="navbar-action">
                <div className="d-flex justify-content-center">
                  {this.props.loadingBar.default
                    ? <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    : this.props.authedUser === null
                      ? (<div>
                        <Button className="btn btn--primary mr-3" onClick={() => this.setOpenLoginModal(true)}>{t('header.Login')}</Button>
                        <Button className="btn btn--primary" onClick={() => this.setOpenSignupModal(true)}>{t('header.Sign Up')}</Button>
                      </div>) : (
                        <NavDropdown title={displayName} id="collasible-nav-dropdown">
                          <Link className="dropdown-item" to="/dashboard">{t('header.My Dashboard')}</Link>
                          <Link className="dropdown-item" to="/dashboard/profile">{t('header.Edit Profile')}</Link>
                          <NavDropdown.Divider />
                          <button onClick={this.logout} className="dropdown-item logout">{t('header.Log out')}</button>
                        </NavDropdown>
                      )
                  }
                  <Button className="btn btn--primary ml-2 mr-2" onClick={() => this.handleChangeLang(i18n.language?.slice(0, 2) === "ar" ? "en" : "ar")} style={{fontSize: "22px"}}>
                    {i18n.language?.slice(0, 2) === "ar" ? "En" : "ع"}
                  </Button>
                </div>
              </Nav>
            </Navbar.Collapse>
            
            </Navbar>
            <LoginModal loginModal={this.state.openLoginModal} closeLoginModal={() => this.setOpenLoginModal(false)} />
            <SignupModal signupModal={this.state.openSignupModal} closeSignupModal={() => this.setOpenSignupModal(false)} />
        </React.Fragment>
    )
                }
}

function mapStateToProps ({ authedUser, loadingBar, language }) {
	return {
        authedUser, loadingBar, language
	}
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Navigation)));