import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Alert } from 'react-bootstrap';
import { handleSubmitAdminNewClass } from '../../store/actionCreators/classes';

class NewClass extends Component {
    state = {
        teacher: '',
        learn: "",
        title: "",
        description: "",
        date: "",
        time: "",
        alertError: false,
        error:"",
    }

    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }
    
    handleSubmit = async(e) => {
        e.preventDefault();
        this.setState({
            alertError: false,
            error: ""
        })
        const { learn, title, description, date, time, teacher } = this.state;
        const user = this.props.users && this.props.users.find(tec => tec.uid === teacher)
        const obj = {
            learn,
            title,
            description,
            date,
            start_at: date + " " + time,
            uid: teacher,
            zoomNumber: user.zoomNumber
        }
        try {
            await this.props.dispatch(handleSubmitAdminNewClass(obj));
            this.props.history.push('/admin/classes');
        } catch(error) {
            this.setState({
                error: error.message,
                alertError: true
            })
        }
    }
    render() {
        const { title, description, date, time, error, alertError, teacher } = this.state;
   
        return (
            <div className="dashboard-content-page p-0">      
                <div className="profile__main">  
                    <Form onSubmit={this.handleSubmit}>
                        <div className="teaching-info">
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Class Title: </Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="title" 
                                    required 
                                    value={title}
                                    name="title"
                                    onChange={this.handleChange}
                                />
                                <span className="required-indicator" role="presentation"></span>
                            </Form.Group>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Teacher: </Form.Label>
                                <Form.Control as="select"
                                    aria-label="Default select example"
                                    name="teacher"
                                    required
                                    value={teacher}
                                    onChange={this.handleChange}
                                >
                                    <option>Select Teacher</option>
                                    {this.props.users && this.props.users.filter(u => u.state === "teacher").map((user, index) =>
                                        <option key={index} value={user.uid}>{user.displayName}</option>
                                    )}
                                </Form.Control>
                                <span className="required-indicator" role="presentation"></span>
                            </Form.Group>
                        </div>
                        <div className="teaching-info">
                            <h3>Teaching Info</h3>
                            <Form.Group>
                                <Form.Label>I will learn: </Form.Label>
                                <span className="required-indicator" role="presentation"></span>
                                <div>
                                    <Form.Check 
                                        name="learn"
                                        type='radio'
                                        value='pronunciation'
                                        label='Quran correct pronunciation'
                                        onChange={this.handleChange}
                                        required
                                    />
                                    <Form.Check 
                                        name="learn"
                                        type='radio'
                                        value='arabic'
                                        label='Arabic Language'
                                        onChange={this.handleChange}
                                        required
                                    />
                                    <Form.Check
                                        name="learn"
                                        type='radio'
                                        value='memorization'
                                        label='Quran memorization'
                                        onChange={this.handleChange}
                                        required
                                    />
                                    <Form.Check 
                                        name="learn"
                                        type='radio'
                                        value='tajweed'
                                        label='Tajweed'
                                        onChange={this.handleChange}
                                        required
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="about-me">
                            <h3>Class Description</h3>
                            <Form.Group controlId="formBasicAboutme">
                                <Form.Control 
                                    as="textarea" 
                                    name="description"
                                    placeholder="Class Description" 
                                    rows={5} 
                                    value={description}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </div>
                        <div className="teaching-info">
                            <h3>You want the Class to start at:</h3>
                            <Form.Group className="about-me">
                                <div>
                                    <span className="required-indicator" role="presentation"></span>
                                    <input className="mr-2 ml-2" value={date} type="date" onChange={this.handleChange} name="date" required />
                                    <input className="mr-2 ml-2" value={time} type="time" onChange={this.handleChange} name="time" required />
                                </div>
                            </Form.Group>
                        </div>
                        <Button type="submit" className="btn btn--primary">Submit</Button>
                    </Form>
                    <Alert show={alertError} className="m-2" variant='danger'>{error}</Alert>
                </div>
            </div>     
        )
    }
}

function mapStateToProps ({ classes, users }) {
	return {
        classes,
        users
	}
}

export default connect(mapStateToProps)(NewClass);