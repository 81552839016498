import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Button, Badge, Alert } from "react-bootstrap";

import { handleSubmitHomeworkAnswer } from '../../../../store/actionCreators/classes';

class HomeWorkSinglePageStudent extends Component {
    state = {
        answers: {
        },
        alertError: false,
        error:""
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            alertError: false,
            error: ""
        })
        let marks = 0;
        let answers = []
        const homework = this.props.homework;
        homework.questions.forEach(qu => {
            if (homework.questions[homework.questions.indexOf(qu)].rightAnswer === Number(this.state.answers[homework.questions.indexOf(qu)])) {
                marks++
            }
            answers.push(Number(this.state.answers[homework.questions.indexOf(qu)]))
        })
        // console.log(answers)
        const {classId, homeworkId} = this.props.match.params
        this.props.dispatch(handleSubmitHomeworkAnswer(classId, homeworkId,marks*10,answers))
        .then(result => {
            if (result.error!== null) {
                this.setState({
                    error: result.error.message,
                    alertError: true
                })
            }
        })
    }

    handleChange = (e) => {
        // console.log(e.target.name, ": ", e.target.value)
        this.setState({
            answers:{
                ...this.state.answers,
                [e.target.name]: e.target.value
            }
        })
    }

    handleChecked = (qu,ans) => this.props.homework && this.props.homework.myAnswers===null
        ? null
        : this.props.homework.myAnswers.answers[this.props.homework.questions.indexOf(qu)]===qu.answers.indexOf(ans)
    
    render() {
        // const homework = this.props.classes && this.props.classes.find(cls => cls.id===this.props.match.params.classId).homeworks.find(hw => hw.id===this.props.match.params.homeworkId)
        const { t, homework } = this.props;
        const fullMark = homework && homework.questions.length*10;
        // console.log(this.props)
        return (
            <React.Fragment>
                <div className="dsh-body">
                    <h2>{homework.title}</h2>
                    {homework.myAnswers===null
                        ? null
                        : <h2><Badge variant="secondary">{homework && homework.myAnswers.marks} / {fullMark}</Badge><br/><br/></h2>
                    }
                    <Form className="hwq-items" onSubmit={this.handleSubmit}>
                        {
                            homework && homework.questions.map(qu =>
                                <div key={qu.id} className="hwq-item">
                                    <p className="hwq-title">{qu.question}</p>
                                    {qu.image !== "" && <img className="hwq-img" src={qu.image} alt="Question" />}
                                    <fieldset disabled={homework.myAnswers!==null}>
                                        <Form.Group className="hwq-answers">
                                            {qu.answers.map(ans => 
                                                <Form.Check key={ans} type="radio" label={ans}
                                                    name={homework.questions.indexOf(qu)}
                                                    value={"" || qu.answers.indexOf(ans)}
                                                    checked={this.handleChecked(qu,ans)}
                                                    onChange={this.handleChange} required
                                                />
                                            )}
                                            
                                            {homework.myAnswers===null
                                                ? null
                                                : qu.rightAnswer===homework.myAnswers.answers[this.props.homework.questions.indexOf(qu)]
                                                    ? <p className="hwq-correct-answer">{t('dashboard.Right answer')}</p>
                                                    : <p className="hwq-correct-answer"><span className='alert-danger'>{t('dashboard.Wrong answer')}</span><br/>{t('dashboard.Right answer')}:  {qu.answers[qu.rightAnswer]}</p>
                                            }
                                        </Form.Group>
                                    </fieldset>
                                </div>
                            )
                        }
                        <Button disabled={homework.myAnswers!==null} type="submit" className="main-btn border-btn-primary">{t('dashboard.Submit')}</Button>
                    </Form>
                    <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps ({ classes }, props) {
	const homework = classes!==null && classes.find(cls => cls.id===props.match.params.classId).homeworks.find(hw => hw.id===props.match.params.homeworkId)
    return {
        homework,
        // homework
	}
}

export default withTranslation()(connect(mapStateToProps)(HomeWorkSinglePageStudent));