import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';


class InvoicesPage extends Component {
    componentDidMount() {
        
    }

    formatDate = time => {
        const date = time.toDate();
        return (date.toLocaleString('default', { month: 'long' })) + " " + date.getDate()+ ", " +  date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
    }
    
    render() {
        // console.log(this.props)
        return (
            <Table className="text-center p-2" responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Payment Method</th>
                        <th>Due Date</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.invoices
                        ? this.props.invoices.map(inv =>
                            <tr key={inv.id}>
                                <td>{this.props.invoices.indexOf(inv)+1}</td>
                                <td>{inv.user.displayName}</td>
                                <td>{inv.user.email}</td>
                                <td>{inv.invoice_id}</td>
                                <td>{inv.invoice_status}</td>
                                <td>{inv.payment_method}</td>
                                <td>{this.formatDate(inv.timestamp)}</td>
                            </tr>
                        ) : null
                    }
                </tbody>
            </Table>
        )
    }
}

function mapStateToProps ({ invoices }) {
	return {
		invoices
	}
}

export default connect(mapStateToProps)(InvoicesPage);
