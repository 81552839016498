import { CREATE_INVOICE, GET_MY_INVOICES, GET_ALL_INVOICES } from '../actionCreators/invoices';

export default function invoices (state = null, action) {
    switch (action.type) {
        case CREATE_INVOICE :
            return action.invoice
        case GET_MY_INVOICES :
            return action.invoices;
        case GET_ALL_INVOICES :
            return action.invoices;
        default :
            return state
    }
} 