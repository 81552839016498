import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';

import { handleDeleteAudio } from '../../../store/actionCreators/audios';

class ListAudios extends Component {
    state = {
        error: "",
        alertError: false,
        openDeleteModal: null
    }

    setOpenDeleteModal = value => this.setState({openDeleteModal: value})

    render() {
        // console.log(this.props)
        return (
            <div className="tab-audio">
                <Link to="/admin/audios/new" className="main-btn btn--primary mb-2">Add New Audio</Link>
                <div className="audio-list">
                    <div className="audio-item">
                        <div className="audio__card m-2">
                            {this.props.audios!==null && this.props.audios.map(audio => (
                                <div className="position-relative mb-4" key={audio.id}>
                                    <OverlayTrigger
                                        placement={"top"}
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <strong>Delete</strong>
                                            </Tooltip>
                                        }
                                    >
                                        <i className="delete-article far fa-times-circle" style={{background: "#fff", top: "10px"}} title="Delete this article" onClick={() => this.setOpenDeleteModal(audio.id)}></i>
                                    </OverlayTrigger>
                                    <h1 className="audio-track-name">{audio.title}</h1>
                                    <div className="audio-wrapper-item">
                                        <div className="audio-icon-box">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23.488" height="29.36" viewBox="0 0 23.488 29.36">
                                                <path data-name="Path 2111" d="M11.926,0h-.364A6.426,6.426,0,0,0,5.211,6.5v6.7a6.425,6.425,0,0,0,6.352,6.5h.364a6.425,6.425,0,0,0,6.352-6.5V6.5A6.426,6.426,0,0,0,11.926,0Zm-.364,2.209h.364A4.24,4.24,0,0,1,16.118,6.5v6.7a4.239,4.239,0,0,1-4.191,4.286h-.364A4.239,4.239,0,0,1,7.371,13.2V6.5A4.24,4.24,0,0,1,11.563,2.209Zm10.845,9.9a1.093,1.093,0,0,1,1.08,1.1A11.91,11.91,0,0,1,12.825,25.17v3.086a1.079,1.079,0,0,1-2.151.15l-.01-.15V25.17A11.909,11.909,0,0,1,0,13.21a1.093,1.093,0,0,1,1.08-1.1,1.093,1.093,0,0,1,1.08,1.1,9.693,9.693,0,0,0,9.584,9.8,9.693,9.693,0,0,0,9.584-9.8A1.093,1.093,0,0,1,22.408,12.1Z" fill="#004e6e" fillRule="evenodd"></path>
                                            </svg>
                                        </div>
                                        <AudioPlayer
                                            src={audio.src}
                                            onPlay={e => console.log("onPlay")}
                                            layout= 'horizontal'
                                        />
                                    </div>
                                    <DeleteModal
                                        audio={audio}
                                        deleteModal={this.state.openDeleteModal===audio.id}
                                        closeDeleteModal={() => this.setOpenDeleteModal(null)}
                                        dispatch={this.props.dispatch}
                                    /> 
                                </div>
                            ))}
                        </div>
                    </div> 
                </div>
            </div>
        )
    }
}

function mapStateToProps ({ audios }) {
    return {
        audios
    }
}
    
export default connect(mapStateToProps)(ListAudios);

class DeleteModal extends Component {
    state = {
        error: '',
        alertError: false
    }
  
    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeDeleteModal()
    }

    handleDelete = (id,src) => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.dispatch(handleDeleteAudio(id,src))
        .then((result) => {
            if (result.error===null) {
                this.props.closeDeleteModal()
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }
  
    render() {
        // console.log(this.props)
        const { deleteModal, audio } = this.props;
        return (
            <Modal
                show={deleteModal}
                onHide={this.closeModal}
                aria-labelledby="delete-modal"
                className="signup"
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">Delete Audio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4><Alert variant="warning">Are you sure you want to delete "{audio.title}" audio?</Alert></h4>
                    <AudioPlayer
                        src={audio.src}
                        onPlay={e => console.log("onPlay")}
                        layout= 'horizontal'
                    />
                    <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Cancel</Button>
                    <Button variant="danger" onClick={() => this.handleDelete(audio.id,audio.src)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
