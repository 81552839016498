import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import ListAudios from './listAudios';
import AddAudio from './newAudio';

class Audios extends Component {
    render() {
        // console.log(this.props)
        return (
            <Switch>
                <Route path="/admin/audios" exact component={ListAudios}/>
                <Route path="/admin/audios/new" component={AddAudio}/>
            </Switch>
        )
    }
}

function mapStateToProps ({ audios }) {
    return {
        audios
    }
}
    
export default connect(mapStateToProps)(Audios);