import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getInitialData } from '../utils/firebase';
import { getVideos } from './videos';
import { getArticles } from './articles';
import { getAudios } from './audios';

export function handleInitialData () {
  return (dispatch) => {
    dispatch(showLoading())
    return getInitialData()
        .then(({ articles, videos, audios }) => {
            let Aresult = [];
			articles.forEach((doc) => {
				let vid = {id: doc.id, ...doc.data()}
				Aresult.push(vid)
			})
            let Vresult = [];
			videos.forEach((doc) => {
				let vid = {id: doc.id, ...doc.data()}
				Vresult.push(vid)
			})
            let Dresult = [];
			audios.forEach((doc) => {
				let aud = {id: doc.id, ...doc.data()}
				Dresult.push(aud)
			})
            dispatch(getArticles(Aresult))
            dispatch(getVideos(Vresult))
            dispatch(getAudios(Dresult))
            dispatch(hideLoading())
        })
    }
} 