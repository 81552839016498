import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { handleReceiveMyInvoices } from '../../store/actionCreators/invoices';

class InvoicesPage extends Component {
    componentDidMount() {
        this.props.dispatch(handleReceiveMyInvoices())
    }

    formatDate = time => {
        const date = time.toDate();
        return (date.toLocaleString('default', { month: 'long' })) + " " + date.getDate()+ ", " +  date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
    }
    
    render() {
        const { t } = this.props;
        return (
                <Table className="text-center p-2" responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('dashboard.ID')}</th>
                            <th>{t('dashboard.Status')}</th>
                            <th>{t('dashboard.Payment Method')}</th>
                            <th>{t('dashboard.Due Date')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.invoices
                            ? this.props.invoices.map(inv =>
                                <tr key={inv.id}>
                                    <td>{this.props.invoices.indexOf(inv)+1}</td>
                                    <td>{inv.invoice_id}</td>
                                    <td>{inv.invoice_status}</td>
                                    <td>{inv.payment_method}</td>
                                    <td>{this.formatDate(inv.timestamp)}</td>
                                </tr>
                            ) : null
                        }
                    </tbody>
                </Table>
        )
    }
}

function mapStateToProps ({ invoices }) {
	return {
		invoices
	}
}

export default withTranslation()(connect(mapStateToProps)(InvoicesPage));
