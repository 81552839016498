import React, { Component } from 'react';
import { connect } from 'react-redux';
import LeftPanel from "./quran/components/LeftPanel/LeftPanel";
import RightPanel from "./quran/components/RightPanel/RightPanel";
import {
    fetchSurahs,
    fetchRecitations,
    fetchTextEditions,
    fetchTranslations
  } from "./quran/scripts/scripts";

class Quran extends Component {
    styles = {
        marginLeft: "0px",
        marginRight: 0,
        width: "100%"
    };

    componentWillMount() {
        fetchSurahs(this.props);
        fetchTranslations(this.props);
        fetchTextEditions(this.props);
        fetchRecitations(this.props);
    }

    render() {
        return (
            <div className="quran row" style={this.styles}>
                <div className="teacher-tools col-md-9 p-2">
                    <RightPanel />
                </div>
                <div className="tools-options-menu col-md-3 p-2">
                    <LeftPanel />
                </div>
            </div>
        )
    }
}
export default connect()(Quran);