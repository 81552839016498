/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-unused-state */
import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
	Scheduler,
	Toolbar,
	MonthView,
	WeekView,
	DayView,
	ViewSwitcher,
	DateNavigator,
	Appointments,
	AppointmentTooltip,
	AppointmentForm,
	DragDropProvider,
	EditRecurrenceMenu,
} from '@devexpress/dx-react-scheduler-material-ui';
import { connectProps } from '@devexpress/dx-react-core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import LocationOn from '@material-ui/icons/LocationOn';
import Notes from '@material-ui/icons/Notes';
import Close from '@material-ui/icons/Close';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Create from '@material-ui/icons/Create';

import { Alert } from 'react-bootstrap';

import { handleSubmitNewAppointment, handleSubmitDeleteAppointment } from '../../../store/actionCreators/authedUser';

const containerStyles = theme => ({
  	container: {
		width: theme.spacing(68),
		padding: 0,
		paddingBottom: theme.spacing(2),
  	},
	content: {
		padding: theme.spacing(2),
		paddingTop: 0,
	},
	header: {
		overflow: 'hidden',
		paddingTop: theme.spacing(0.5),
	},
	closeButton: {
		float: 'right',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 2),
	},
	button: {
		marginLeft: theme.spacing(2),
	},
	picker: {
		marginRight: theme.spacing(2),
		'&:last-child': {
		marginRight: 0,
		},
		width: '50%',
	},
	wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(1, 0),
	},
	icon: {
		margin: theme.spacing(2, 0),
		marginRight: theme.spacing(2),
	},
	textField: {
		width: '100%',
	},
});

class AppointmentFormContainerBasic extends React.PureComponent {
  	constructor(props) {
    	super(props);

		this.state = {
			appointmentChanges: {},
		};

		this.getAppointmentData = () => {
			const { appointmentData } = this.props;
			return appointmentData;
		};
		this.getAppointmentChanges = () => {
			const { appointmentChanges } = this.state;
			return appointmentChanges;
		};

		this.changeAppointment = this.changeAppointment.bind(this);
		this.commitAppointment = this.commitAppointment.bind(this);
  	}

  	changeAppointment({ field, changes }) {
		const nextChanges = {
			...this.getAppointmentChanges(),
			[field]: changes,
		};
		this.setState({
			appointmentChanges: nextChanges,
		});
	}

  	commitAppointment(type) {
    	const { commitChanges } = this.props;
		const appointment = {
			...this.getAppointmentData(),
			...this.getAppointmentChanges(),
		};
		if (type === 'deleted') {
			commitChanges({ [type]: appointment.id });
		} else if (type === 'changed') {
			commitChanges({ [type]: { [appointment.id]: appointment } });
		} else {
			commitChanges({ [type]: appointment });
		}
		this.setState({
			appointmentChanges: {},
		});
  	}

  	render() {
    	const { classes, visible, visibleChange, appointmentData, cancelAppointment, target, onHide, } = this.props;
    	const { appointmentChanges } = this.state;

		const displayAppointmentData = { ...appointmentData, ...appointmentChanges, };

		const isNewAppointment = appointmentData.id === undefined;
		const applyChanges = isNewAppointment
			? () => this.commitAppointment('added')
			: () => this.commitAppointment('changed');

		const textEditorProps = field => ({
			variant: 'outlined',
			onChange: ({ target: change }) => this.changeAppointment({
				field: [field], changes: change.value,
			}),
			value: displayAppointmentData[field] || '',
			label: field[0].toUpperCase() + field.slice(1),
			className: classes.textField,
		});

		const pickerEditorProps = field => ({
			className: classes.picker,
			// keyboard: true,
			ampm: false,
			value: displayAppointmentData[field],
			onChange: date => this.changeAppointment({
				field: [field], changes: date ? date.toDate() : new Date(displayAppointmentData[field]),
			}),
			inputVariant: 'outlined',
			format: 'DD/MM/YYYY HH:mm',
			onError: () => null,
		});

		const cancelChanges = () => {
			this.setState({
				appointmentChanges: {},
			});
			visibleChange();
			cancelAppointment();
		};

    	return (
			<AppointmentForm.Overlay
				visible={visible}
				target={target}
				fullSize
				onHide={onHide}
			>
        		<div>
          			<div className={classes.header}>
						<IconButton
						className={classes.closeButton}
						onClick={cancelChanges}
						>
              				<Close color="action" />
            			</IconButton>
          			</div>
          			<div className={classes.content}>
            			<div className={classes.wrapper}>
              				<Create className={classes.icon} color="action" />
							<TextField
								{...textEditorProps('title')}
							/>
            			</div>
            			<div className={classes.wrapper}>
              				<CalendarToday className={classes.icon} color="action" />
              				<MuiPickersUtilsProvider utils={MomentUtils}>
                				<KeyboardDateTimePicker
									label="Start Date"
									{...pickerEditorProps('startDate')}
								/>
								<KeyboardDateTimePicker
									label="End Date"
									{...pickerEditorProps('endDate')}
								/>
              				</MuiPickersUtilsProvider>
            			</div>
            			<div className={classes.wrapper}>
							<LocationOn className={classes.icon} color="action" />
							<TextField
								{...textEditorProps('location')}
							/>
						</div>
            			<div className={classes.wrapper}>
              				<Notes className={classes.icon} color="action" />
							<TextField
								{...textEditorProps('notes')}
								multiline
								rows="6"
							/>
            			</div>
          			</div>
          			<div className={classes.buttonGroup}>
            			{!isNewAppointment && (
							<Button
								variant="outlined"
								color="secondary"
								className={classes.button}
								onClick={() => {
								visibleChange();
								this.commitAppointment('deleted');
								}}
							>
								Delete
							</Button>
            			)}
						<Button
							variant="outlined"
							color="primary"
							className={classes.button}
							onClick={() => {
								visibleChange();
								applyChanges();
							}}
						>
              				{isNewAppointment ? 'Create' : 'Save'}
            			</Button>
          			</div>
        		</div>
      		</AppointmentForm.Overlay>
    	);
  	}
}

const AppointmentFormContainer = withStyles(containerStyles, { name: 'AppointmentFormContainer' })(AppointmentFormContainerBasic);

const styles = theme => ({
	addButton: {
		position: 'absolute',
		bottom: theme.spacing(1) * 3,
		right: theme.spacing(1) * 4,
	},
});

/* eslint-disable-next-line react/no-multi-comp */
class SchedulePage extends React.PureComponent {
  	state = {
		data: [],
		confirmationVisible: false,
		editingFormVisible: false,
		deletedAppointmentId: undefined,
		editingAppointment: undefined,
		previousAppointment: undefined,
		addedAppointment: {},
		isNewAppointment: false,
		error: "",
		alertAddError: false,
		alertDeleteError: false,
		alertEditError: false,
  	};

	componentDidMount() {
		this.setState({data: this.props.authedUser.appointments})
	}

    appointmentForm = connectProps(AppointmentFormContainer, () => {
        const { editingFormVisible, editingAppointment, data, addedAppointment, isNewAppointment, previousAppointment } = this.state;
  
        const currentAppointment = data.filter(appointment => editingAppointment && appointment.id === editingAppointment.id)[0] || addedAppointment;
        const cancelAppointment = () => {
          	if (isNewAppointment) {
				this.setState({
					editingAppointment: previousAppointment,
					isNewAppointment: false,
				});
          	}
        };
  
        return {
			visible: editingFormVisible,
			appointmentData: currentAppointment,
			commitChanges: this.commitChanges,
			visibleChange: this.toggleEditingFormVisibility,
			onEditingAppointmentChange: this.onEditingAppointmentChange,
			cancelAppointment,
        };
    });

	componentDidUpdate() {
		this.appointmentForm.update();
	}

	onEditingAppointmentChange = (editingAppointment) => {
		this.setState({ editingAppointment });
	}

	onAddedAppointmentChange = (addedAppointment) => {
			this.setState({ addedAppointment });
		const { editingAppointment } = this.state;
		if (editingAppointment !== undefined) {
			this.setState({
				previousAppointment: editingAppointment,
			});
		}
		this.setState({ editingAppointment: undefined, isNewAppointment: true });
	}

	setDeletedAppointmentId = (id) => {
		this.setState({ deletedAppointmentId: id });
	}

	toggleEditingFormVisibility = () => {
		const { editingFormVisible } = this.state;
		this.setState({
			editingFormVisible: !editingFormVisible,
		});
	}

	toggleConfirmationVisible = () => {
		const { confirmationVisible } = this.state;
		this.setState({ confirmationVisible: !confirmationVisible });
	}

  	commitDeletedAppointment = () => {
		this.setState({ alertDeleteError: false, error: "" })
		const { data, deletedAppointmentId } = this.state;
		this.props.dispatch(handleSubmitDeleteAppointment(deletedAppointmentId))
        .then((result) => {
            if(result.error === null) {
				const nextData = data.filter(appointment => appointment.id !== deletedAppointmentId);
				this.setState({ data: nextData, deletedAppointmentId: null })
				this.toggleConfirmationVisible();
            } else {
                this.setState({error: result.error.message, alertDeleteError: true})
            }
        })
  	}

  	commitChanges = ({ added, changed, deleted }) => {
        this.setState({alertAddError: false, alertDeleteError: false, error: "" })
		let { data } = this.state;
		if (added) {
        
			this.props.dispatch(handleSubmitNewAppointment(added))
			.then((result) => {
				if(result.error === null) {
					// const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
					// data = [...data, result.appointment];
					this.setState({data: [...data, result.appointment]})
				} else {
					this.setState({error: result.error.message, alertAddError: true})
					// this.setDeletedAppointmentId(added);
				}
			})
      	}
		/*
      	if (changed) {
            data.map(appointment => {
                if (changed[appointment.id]) {
                    this.props.dispatch(handleSubmitChangeAppointment({...changed[appointment.id]}))
                    .then((result) => {
                        if(result.error === null) {
                            const { title, notes, startDate, endDate } = changed[appointment.id]
                            appointment.title = title
                            appointment.notes = notes
                            appointment.startDate = startDate
                            appointment.endDate = endDate
                            // let app = changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment;
                            // newData.push(app)
                            // data[appointment.id] = changed[appointment.id]
                            // this.setState({ data })
                        } else {
                            // newData = data
                            this.setState({error: result.error.message, alertAddError: true})
                        }
                    })
                } else {
                    // newData = data
                }
            })
            this.setState({data})
      	}
		*/
		if (deleted !== undefined) {
			// console.log(deleted)
			this.toggleConfirmationVisible();
			this.setDeletedAppointmentId(deleted);
		}
    	//   this.setState({data}) 
  	}

  	render() {
		const { data, confirmationVisible, editingFormVisible, error, alertAddError, alertDeleteError } = this.state;
		const { classes } = this.props;
		// console.log(this.props)
		if (this.props.loadingBar.default) return (
			<div className="text-center m-auto">
				<img alt="loading" src="/img/45.svg" />
			</div>
    	)
    	return (
			<div className='dashboard-content-page p-0'>  
				<div className="schedule__main">
					<div className="row schedule-row justify-content-center">
      					<Alert show={alertAddError} className="m-2" variant='danger'>{error}</Alert>
						<Paper>
							<Scheduler
								data={data}
								height={660}
							>
								<ViewState />
								<EditingState
									onCommitChanges={this.commitChanges}
									onEditingAppointmentChange={this.onEditingAppointmentChange}
									onAddedAppointmentChange={this.onAddedAppointmentChange}
								/>
								<WeekView cellDuration={60}/>
								<MonthView />
								<DayView />
							
								<EditRecurrenceMenu />
								<Appointments />
								<AppointmentTooltip
									// showOpenButton
									showCloseButton
									showDeleteButton
								/>
								<Toolbar />
								<DateNavigator />
								<ViewSwitcher />
								<AppointmentForm
									overlayComponent={this.appointmentForm}
									visible={editingFormVisible}
									onVisibilityChange={this.toggleEditingFormVisibility}
								/>
								<DragDropProvider />
							</Scheduler>

							<Dialog
								open={confirmationVisible}
								onClose={this.cancelDelete}
							>
								<DialogTitle>
									Delete Appointment
								</DialogTitle>
								<DialogContent>
									<DialogContentText>
										Are you sure you want to delete this appointment?
									</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button onClick={this.toggleConfirmationVisible} color="primary" variant="outlined">
										Cancel
									</Button>
									<Button onClick={this.commitDeletedAppointment} color="secondary" variant="outlined">
										Delete
									</Button>
								</DialogActions>
								<Alert show={alertDeleteError} className="m-2" variant='danger'>{error}</Alert>
							</Dialog>

							<Fab
								color="secondary"
								className={classes.addButton}
								onClick={() => {
									this.setState({ editingFormVisible: true });
									this.onEditingAppointmentChange(undefined);
									this.onAddedAppointmentChange({
										startDate: new Date().setHours(new Date().getHours()),
										endDate: new Date().setHours(new Date().getHours() + 1),
									});
								}}
							>
								<AddIcon />
							</Fab>
						</Paper>
					</div>
				</div>
			</div>
    	);
  	}
}

function mapStateToProps ({ authedUser, loadingBar }) {
	return {
		authedUser,
        loadingBar
	}
}

export default compose(connect(mapStateToProps,null),withStyles(styles, { name: 'EditingDemo' }))(SchedulePage);
