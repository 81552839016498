import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Button, Alert } from 'react-bootstrap';
import { handleAddAudio, handleUploadAudio } from '../../../store/actionCreators/audios';

class AddAudio extends Component {
    state = {
        type: "url",
        alertError: false,
        error: '',
        title: "",
        src: '',
        file: ""
    }

    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    handleConvert = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
        switch (e.target.value) {
            case "file" :
                return this.setState({src: ""});
            case "url" :
                return document.getElementById("audioFile").value = null;
            default :
                return this.setState({src: ""});
        }
    }

    handleUpload = file => {
        this.setState({
            file
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState(() => ({
            alertError: false,
            error: '',
            src: ''
        }))
        document.getElementById("audioFile").value = null
        const { type, src, file, title } = this.state;
        if (type === "url") {
            this.props.dispatch(handleAddAudio(src,title))
            .then((result) => {
                if (result.error===null) {
                    this.props.history.push('/admin/audios')
                } else {
                    this.setState(() => ({
                        error: result.error.message,
                        alertError: true,
                    }))
                }
            })
        } else {
            this.props.dispatch(handleUploadAudio(file,title))
            .then((result) => {
                if (result.error) {
                    this.setState(() => ({
                        error: result.error.message,
                        alertError: true,
                    }))
                } else {
                    this.props.history.push('/admin/audios')
                }
            })
        }
        
    }

    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <h2>Adding new Audio</h2>
                <Form onSubmit={this.handleSubmit} style={{}}>
                    <Form.Group>
                        <Form.Control type="text" name="title" value={this.state.title} placeholder="Title" onChange={this.handleChange} required />
                        <span className="required-indicator" role="presentation"></span>
                    </Form.Group>
                    <Form.Group>
                        <Form.Row className="align-items-center">
                            <Col sm={3} className="my-1">
                                <Form.Check defaultChecked name="type" value="url" inline label="Mp3 Link" type="radio" id='inline-radio-3' onChange={this.handleConvert} required />
                            </Col>
                            <Col className="my-1">
                                <Form.Label htmlFor="inlineFormInputUrl" srOnly>
                                    Url
                                </Form.Label>
                                <Form.Control disabled={this.state.type!=="url"} type="text" name="src" value={this.state.src} placeholder="Link for Audio" onChange={this.handleChange} required />
                                <span className="required-indicator" role="presentation"></span>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                    <Form.Group>
                        <Form.Row className="align-items-center">
                            <Col sm={3} className="my-1">
                                <Form.Check name="type" value="file" inline label="Upload Mp3 file" type="radio" id='inline-radio-3' onChange={this.handleConvert} />
                            </Col>
                            <Col className="my-1">
                                <Form.Label htmlFor="inlineFormInputFile" srOnly>
                                    File
                                </Form.Label>
                                <Form.Control disabled={this.state.type!=="file"} type="file" name="file" id="audioFile" placeholder="Link for Audio" onChange={(e) => this.handleUpload(e.target.files[0])} accept="audio/*" required />
                                <span className="required-indicator" role="presentation"></span>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                    <Button className="btn btn--primary mt-2" type="submit" >Submit</Button>
                </Form>
                <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
            </React.Fragment>
        )
    }
}

function mapStateToProps ({ audios }) {
	return {
		audios
	}
}

export default connect(mapStateToProps)(AddAudio);