import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getContactUsMessages, addContactUsMessage, deleteContactUsMessage } from '../utils/firebase';


export const GET_CONTACT_US_MESSAGES = 'GET_CONTACT_US_MESSAGES';
export const SUBMIT_CONTACT_US_MESSAGE = 'SUBMIT_CONTACT_US_MESSAGE';
export const DELETE_CONTACT_US_MESSAGE = 'DELETE_CONTACT_US_MESSAGE';

export function getMessages (contactUsMessages, error=null) {
    return {
        type: GET_CONTACT_US_MESSAGES,
        contactUsMessages,
        error
    }
}

export function handleReceiveContactUsMessages () {
    return (dispatch) => {
        dispatch(showLoading())
        return getContactUsMessages()
        .then((messages) => {
            let result = [];
			messages.forEach((doc) => {
				let mess = {id: doc.id, ...doc.data()}
				result.push(mess)
			})
			dispatch(hideLoading())
            return dispatch(getMessages(result))
        })
        .catch((error) => {
            dispatch(hideLoading())
            return dispatch(getMessages(null,error))
        })
    }
}

function submitContactUsMessage(payload,error=null) {
    return {
        type: SUBMIT_CONTACT_US_MESSAGE,
        payload,
		error
	};
}

export function handleSubmitContactUsMessage({name,email,subject, message}) {
	return (dispatch) => {
        dispatch(showLoading())
        return addContactUsMessage({name,email,subject, message})
        .then((docRef) => {
            dispatch(hideLoading())
            return dispatch(submitContactUsMessage(docRef))
        })
		.catch((error) => {
            dispatch(hideLoading())
            return dispatch(submitContactUsMessage(null,error))
        })
    }
}

function removeContactUsMessage(id,error=null) {
    return {
        type: DELETE_CONTACT_US_MESSAGE,
        id,
		error
	};
}

export function handleDeleteContactUsMessage(id) {
	return (dispatch) => {
        dispatch(showLoading())
        return deleteContactUsMessage(id)
        .then(() => {
            dispatch(hideLoading())
            return dispatch(removeContactUsMessage(id))
        })
		.catch((error) => {
            dispatch(removeContactUsMessage(null,error))
            dispatch(hideLoading())
            return getContactUsMessages()
            .then((messages) => {
                let result = [];
                messages.forEach((doc) => {
                    let goal = {id: doc.id, ...doc.data()}
                    result.push(goal)
                })
                return dispatch(getMessages(result,error)) 
            }) 
        })
        
    }
}