import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Alert } from 'react-bootstrap';
import { handleSubmitEditClass } from '../../../store/actionCreators/classes';
import { getClass } from '../../../store/utils/firebase';
import { useHistory, useLocation, useParams } from 'react-router-dom';

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
function formatDate(date) {
    return (
        [
            [
                date.getFullYear(),
                padTo2Digits(date.getMonth() + 1),
                padTo2Digits(date.getDate()),
            ].join('-'),
            [
                padTo2Digits(date.getHours()),
                padTo2Digits(date.getMinutes()),
                padTo2Digits(date.getSeconds()),
            ].join(':')
        ]
    );
  }

const EditClass = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const { classId } = useParams();

    const [formData, setFormData] = useState({
        learn: "",
        title: "",
        description: "",
        date: "",
        time: ""
    });
    const [error, setError] = useState("");
    
    const { learn, title, description, date, time } = formData;

    useEffect(() => {
        getClass(classId).then(result => {
            const f = formatDate(result.start_at.toDate())
            setFormData({
                learn: result.learn,
                title: result.title,
                description: result.description,
                date: f[0],
                time: f[1]
            })
        })
    }, [classId]);

    const handleChange = (e) => {
        setFormData((state) => {
            return {
                ...state,
                [e.target.name]: e.target.value
            }
        })
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        const obj = {
            id: classId,
            learn,
            title,
            description,
            start_at: date + " " + time
        }
        dispatch(handleSubmitEditClass(obj))
        .then(result => {
            if (result.error!== null) {
                setError(result.error.message);
            } else {
                history.replace(pathname.replace(`${classId}`, '').replace('/class/', ''))
            }
        })
        .catch((error) => {
            if(error.message) {
                setError(error.message);
            }
        });
    }
    
    return (
        <div className="dashboard-content-page p-0">      
            <div className="profile__main">  
                <Form onSubmit={handleSubmit}>
                    <div className="teaching-info">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>Class Title: </Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="title" 
                                required 
                                value={title}
                                name="title"
                                onChange={handleChange}
                            />
                            <span className="required-indicator" role="presentation"></span>
                        </Form.Group>
                    </div>
                    <div className="teaching-info">
                        <h3>Teaching Info</h3>
                        <Form.Group>
                            <Form.Label>I will learn: </Form.Label>
                            <span className="required-indicator" role="presentation"></span>
                            <div>
                                <Form.Check 
                                    name="learn"
                                    type='radio'
                                    value='pronunciation'
                                    label='Quran correct pronunciation'
                                    onChange={handleChange}
                                    required
                                    checked={learn === 'pronunciation'}
                                />
                                <Form.Check 
                                    name="learn"
                                    type='radio'
                                    value='arabic'
                                    label='Arabic Language'
                                    onChange={handleChange}
                                    required
                                    checked={learn === 'arabic'}
                                />
                                <Form.Check
                                    name="learn"
                                    type='radio'
                                    value='memorization'
                                    label='Quran memorization'
                                    onChange={handleChange}
                                    required
                                    checked={learn === 'memorization'}
                                />
                                <Form.Check 
                                    name="learn"
                                    type='radio'
                                    value='tajweed'
                                    label='Tajweed'
                                    onChange={handleChange}
                                    required
                                    checked={learn === 'tajweed'}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="about-me">
                        <h3>Class Description</h3>
                        <Form.Group controlId="formBasicAboutme">
                            <Form.Control 
                                as="textarea" 
                                name="description"
                                placeholder="Class Description" 
                                rows={5} 
                                value={description}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </div>
                    <div className="teaching-info">
                        <h3>You want the Class to start at:</h3>
                        <Form.Group className="about-me">
                            <div>
                                <span className="required-indicator" role="presentation"></span>
                                <input className="mr-2 ml-2" value={date} type="date" onChange={handleChange} name="date" required />
                                <input className="mr-2 ml-2" value={time} type="time" onChange={handleChange} name="time" required />
                            </div>
                        </Form.Group>
                    </div>
                    <Button type="submit" className="btn btn--primary">Submit</Button>
                </Form>
                <Alert show={error!==''} className="m-2" variant='danger'>{error}</Alert>
            </div>
        </div>     
    )
}

export default EditClass;