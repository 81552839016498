import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import imageCompression from 'browser-image-compression';

// import { createZoomNum } from './zoom';

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: "hafsquran-1f075.firebaseapp.com",
    projectId: "hafsquran-1f075",
    storageBucket: "hafsquran-1f075.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

function generateUID () {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

export function signIn (email,password) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
}  

export function signUp ({email,password,displayName,gender,photoURL,receiveEmails}) {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(function(user) {
        console.log("User successfully created:", user.user.uid, user.user.email);
        
        let u = firebase.auth().currentUser;
        u.updateProfile({
            displayName: displayName,
            photoURL: photoURL
        });

        db.collection('users').doc(user.user.uid).set({
            uid: user.user.uid,
            email: user.user.email,
            displayName: displayName,
            gender: gender,
            photoURL: photoURL,
            state:"student",
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            admin: false,
            certifications: [],
            receiveEmails
        }, { merge: true });
        
        return new Promise((res, rej) => {
            res(u)
        })
    });   
}


export function userDoc (user) {
    let x = {}
    return db.collection("users").doc(user.uid).get().then((doc) => {
        user.getIdTokenResult().then(idTokenResult => { 
            x.admin = (idTokenResult.claims && idTokenResult.claims.admin) ? true : false 
        })
        if (doc.exists) {
            x.uid= user.uid;
            x.displayName= user.displayName;
            x.email= user.email;
            x.emailVerified= user.emailVerified;
            x.phoneNumber= user.phoneNumber;
            x.photoURL= user.photoURL;    
            x.creationTime= user.metadata.creationTime;
            x.lastSignInTime= user.metadata.lastSignInTime;  

            let appointments = [];
            db.collection("users").doc(user.uid).collection("appointments").get().then(apps => {
                apps.forEach((app) => {
                    const { allDay, title, startDate, endDate, location, notes } = app.data()
                    let appointment = {
                        id: app.id,
                        allDay,
                        title,
                        startDate: new Date(startDate.seconds*1000),
                        endDate: new Date(endDate.seconds*1000),
                        location,
                        notes
                    }
                    appointments.push(appointment)
                })
            })
            x.appointments = appointments

            x.gender= doc.data().gender;
            x.country= doc.data().country;
            x.aboutMe= doc.data().aboutMe;
            x.timeZone= doc.data().timeZone; 
            x.learn= doc.data().learn;   
            x.state= doc.data().state; 
            x.certifications= doc.data().certifications;   
            return new Promise((res, rej) => res(x))
        }
    }).catch((error) => {
        return new Promise((res, rej) => res(error))
    });  
}

export function updateUserDoc({displayName,email,avatarURL,gender,country,timeZone,aboutMe,learn, address}) {
    const user = firebase.auth().currentUser; 
    return db.collection("users").doc(user.uid).set({
        displayName,
        email,
        gender,
        photoURL: avatarURL,
        country,
        timeZone,
        aboutMe,
        learn,
        address
    }, { merge: true })
}

export function logout() {
    return firebase.auth().signOut()
}

export function freeSession({name,email,timeZone,availability,learn,send,whatsapp}) {
    const date = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    return db.collection("freeSession").add({      
        created_at: date,
        name,
        email,
        timeZone,
        availability,
        learn,
        send,
        whatsapp,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
    })
}

export function getFreeSessions() {
    return db.collection("freeSession").orderBy('created_at', 'desc').get()
}

export function deleteFreeSession(id) {
    return db.collection('freeSession').doc(id).delete()
}

export function getAllArticles() {
    return db.collection("articles").orderBy('timestamp', 'desc').get()
}

export function getVideos() {
    return db.collection("videos").orderBy('timestamp', 'desc').get()
}

export function getAudios() {
    return db.collection("audios").orderBy('timestamp', 'desc').get()
}

export function getInitialData() {
    return Promise.all([
        db.collection("articles").orderBy('created_at', 'desc').get(),
        db.collection("videos").orderBy('timestamp', 'desc').get(),
        db.collection("audios").orderBy('timestamp', 'desc').get()
    ]).then(([articles, videos, audios]) => ({
        articles,
        videos,
        audios
    }))
}

export function getContactUsMessages() {
    return db.collection("conactUsMesaages").orderBy('timestamp', 'desc').get()
}

export function addContactUsMessage({name,email,subject,message}) {
    const user = firebase.auth().currentUser!==null ? db.doc(`users/${firebase.auth().currentUser.uid}`) : null; 
    return db.collection("conactUsMesaages").add({
        name,
        email,
        subject,
        message,
        user,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
    })
}

export function deleteContactUsMessage(id) {
    return db.collection('conactUsMesaages').doc(id).delete()
}

export function getMyClasses(uid) {
    return db.collection("classes").where("uid","==",  uid).orderBy('timestamp', 'desc').get()
}

export function getClassStudents(id) {
    return db.collection("classes").doc(id).get().then((doc) => doc.data().students)
}

export function getClassHomeworks(id) {
    return db.collection("classes").doc(id).collection("homeworks").get();
}

export function getClass(id) {
    return db.collection("classes").doc(id).get().then((doc) => {
        if (doc.exists) {
            return doc.data();
        } else {
            // doc.data() will be undefined in this case
            throw new Error("No such document!");
        }
    });
}

export function getHomeworkQuestions(classId, homeworkId) {
    return db.collection("classes").doc(classId).collection("homeworks").doc(homeworkId).collection("questions").get();
}

export function getMyHomeworkAnswers(classId, homeworkId) {
    const user = firebase.auth().currentUser
    return db.collection("classes").doc(classId).collection("homeworks").doc(homeworkId).collection("studentsAnswers").doc(user.uid).get();
}

export function getOneHomework(classId,homeworkId) {
    return db.collection("classes").doc(classId).collection("homeworks").doc(homeworkId).get()
}

export function getStudentClasses() {
    const user = firebase.auth().currentUser
    return db.collection("classes").where('students', 'array-contains', db.collection("users").doc(user.uid)).orderBy('timestamp', 'desc').get()
}

export function getClassTeacher(teacher) {
    return teacher.get()
}

export function answerHomework(classId,homeworkId,marks,answers) {
    const user = firebase.auth().currentUser
    return db.collection("classes").doc(classId).collection("homeworks").doc(homeworkId).collection("studentsAnswers").doc(user.uid).set({
        uid: user.uid,
        student: db.doc(`users/${user.uid}`),
        marks,
        answers,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
    })
}

export function addHomework(classId,title) {
    return db.collection("classes").doc(classId).collection("homeworks").add({
        title,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
    })
}

export function addQuestionsToHomework(classId,homeworkId,questions) {
    console.log(questions)
    return questions.forEach(async (doc) => {
        let imgUrl = "";
        if (doc.image !== "") {
            const uploadTask = firebase.storage().ref().child(`classes/${classId}/${homeworkId}/${doc.image.name}`).put(doc.image)
            imgUrl = await uploadTask.then(result => uploadTask.snapshot.ref.getDownloadURL().then(url => url))
        }
        return db.collection("classes").doc(classId).collection("homeworks").doc(homeworkId).collection("questions").add({
            question: doc.question,
            rightAnswer: Number(doc.rightAnswer),
            answers: [
                doc[0],
                doc[1],
                doc[2],
                doc[3],
            ],
            image: imgUrl
        });
    })
}

export function addClass(learn,title,description,start_at) {
    const user = firebase.auth().currentUser
    return db.collection("classes").add({
        learn,
        title,
        description,
        start_at: firebase.firestore.Timestamp.fromDate(new Date(start_at)),
        teacher: db.doc(`users/${user.uid}`),
        uid: user.uid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
    })
}

export function adminAddClass(obj) {
    return db.collection("classes").add({
        ...obj,
        teacher: db.doc(`users/${obj.uid}`),
        start_at: firebase.firestore.Timestamp.fromDate(new Date(obj.start_at)),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

export async function editClass({ id, start_at, ...obj }) {
    const docRef = db.collection("classes").doc(id);
    await docRef.update({
        ...obj,
        start_at: firebase.firestore.Timestamp.fromDate(new Date(start_at))
    });
    return docRef.get().then((doc) => {
        if (doc.exists) {
            return {
                id: doc.id,
                ...doc.data()
            };
        } else {
            // doc.data() will be undefined in this case
            throw new Error("No such document!");
        }
    });
}

export function getAllUsers() {
    return firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        return fetch(process.env.REACT_APP_API_BASEURL+"/firebase/users", {
            method: "GET",
            headers: {
               "Content-Type": "application/json",
               Authorization: idToken
            }
        }).then(response => response.json())
    })
}

export function getAllClasses() {
    return db.collection("classes").orderBy('timestamp', 'desc').get()
}

export function getUserByEmail(email) {
    return db.collection("users").where("email","==",  email).get()
}

export function getUserByUid(uid) {
    return db.collection("users").doc(uid).get()
}

export function addStudentToClass(classId,uid) {
    const classRef = db.collection("classes").doc(classId);
    return classRef.update({
        students: firebase.firestore.FieldValue.arrayUnion(db.doc(`users/${uid}`))
    });
}

export function deleteClass(classId) {
    return db.collection('classes').doc(classId).delete()
}

export function getMyInvoices(uid) {
    // console.log(uid)
    return db.collection("invoices").where("uid","==",  uid).orderBy('timestamp', 'desc').get()
}

export function getAllInvoices() {
    return db.collection("invoices").orderBy('timestamp', 'desc').get()
}

export function uploadArticleImage(file) {
    const uploadTask = firebase.storage().ref().child(`articles/${file.name}-${generateUID()}`).put(file);
    return uploadTask.on('state_changed', snapshot => {
    }, error => {
        if (error) {
            console.log("Image could not be uploaded: " + error.code+ ".Try reload the page and try again");
            return new Promise((res, rej) => {
                res(error)
            })
        }
    }, () => {
        return uploadTask.snapshot.ref.getDownloadURL().then(function(url) {
            const result = {
                url,
                fileName: file.name,
                title: file.name
            }
            return new Promise((res, rej) => {
                res(result)
            })
        });
    });
}

const formatDate = () => {
    const date = new Date();
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2))
}

export function addArticle(author,title,content,created_at) {
    const user = firebase.auth().currentUser
    return db.collection("articles").add({
        title,
        content,
        author,
        created_at: created_at==="" ? formatDate() : created_at,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        user: db.doc(`users/${user.uid}`),
        uid: user.uid
    }) 
}

export function updateArticle(id,author,title,content,created_at) {
    const articleRef = db.collection('articles').doc(id);
    return articleRef.update({
        author,
        title,
        content,
        created_at
    })
}

export function deleteArticle(id) {
    return db.collection('articles').doc(id).delete()
}

export function uploadCertification(file) {
    const user = firebase.auth().currentUser; 
    const uploadTask = firebase.storage().ref().child(`users/${user.uid}/certifications/${file.name}`).put(file);
    return uploadTask.on('state_changed', snapshot => {
    }, error => {
        if (error) {
            console.log("Image could not be uploaded: " + error.code+ ".Try reload the page and try again");
            return new Promise((res, rej) => {
                res(error)
            })
        }
    }, () => {
        return uploadTask.snapshot.ref.getDownloadURL().then(function(url) {
            db.collection("users").doc(user.uid).set({
                certifications: firebase.firestore.FieldValue.arrayUnion(url)
            }, {merge: true})
            const result = {
                url,
                fileName: file.name,
                title: file.name
            }
            return new Promise((res, rej) => {
                res(result)
            })
        });
    });
}

export function deleteCertification(url) {
    const user = firebase.auth().currentUser; 
    db.collection("users").doc(user.uid).set({
        certifications: firebase.firestore.FieldValue.arrayRemove(url)
    }, {merge: true})
    return firebase.storage().refFromURL(url).delete()
}

export function newTeachingRequest({aboutMe,learn, certifications, clip}) {
    const user = firebase.auth().currentUser; 
    db.collection("users").doc(user.uid).set({
        aboutMe,
        learn,
    }, {merge: true})
    return db.collection("teachingRequests").doc(user.uid).set({
        aboutMe,
        learn,
        certifications,
        clip,
        uid: user.uid,
        user: db.doc(`users/${user.uid}`),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    }, {merge: true})
}

export function getTeachingRequests() {
    return db.collection("teachingRequests").orderBy('timestamp', 'desc').get() 
}

export function deleteTeachingRequest(uid) {
    return db.collection("teachingRequests").doc(uid).delete()
}

export function addTeacher(uid,zoomNu,zoomPass) {
    return db.collection("users").doc(uid).set({
        state: "teacher",
        zoomNumber: zoomNu,
        zoomPassword: zoomPass
    }, {merge: true})
}

export function deleteVideo(id) {
    return db.collection("videos").doc(id).delete()
}

export function deleteAudio(id,src) {
    if (src.includes("hafsquran-1f075.appspot.com")) {
        Promise.config({ cancellation: true });
        return Promise.all([
            firebase.storage().refFromURL(src).delete(),
            db.collection("audios").doc(id).delete()
        ])
    } else {
        return db.collection("audios").doc(id).delete()
    }
}

export function uploadAudio(file,title) {
    const uploadTask = firebase.storage().ref().child(`audios/${file.name}`).put(file)
    return uploadTask
    .then(result => {
        return uploadTask.snapshot.ref.getDownloadURL().then(url => {
            return db.collection("audios").add({
                src: url,
                title,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(doc => {
                return new Promise((res, rej) => res({id: doc.id,title, src: url}))
            }).catch(error => {
                firebase.storage().refFromURL(url).delete()
                return new Promise((res, rej) => {
                    res({error})
                })
            })
            
        })
    })
    .catch(error => {
        return new Promise((res, rej) => {
            res({error})
        })
    })
}

export function uploadHomeworkQuestionImage(file, classId, hwId, qid) {
    const uploadTask = firebase.storage().ref().child(`classes/${classId}/${hwId}/${qid}/${file.name}`).put(file)
    return uploadTask
    .then(result => {
        return uploadTask.snapshot.ref.getDownloadURL().then(url => {
            return url
        })
    })
    .catch(error => {
        return new Promise((res, rej) => {
            res({error})
        })
    })
}

export function addVideo(src,title) {
    return db.collection("videos").add({
        src,
        title,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    }) 
}

export function addAudio(src,title) {
    return db.collection("audios").add({
        src,
        title,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    }) 
}

export function addAppointment(appointment) {
    const user = firebase.auth().currentUser; 
    return db.collection("users").doc(user.uid).collection("appointments").add(appointment)
}

export function deleteAppointment(id) {
    const user = firebase.auth().currentUser; 
    return db.collection('users').doc(user.uid).collection("appointments").doc(id).delete()
}

export function changeAppointment(appointment) {
    const user = firebase.auth().currentUser; 
    const { id, title, allDay, startDate, endDate, location, notes } = appointment;
    return db.collection('users').doc(user.uid).collection("appointments").doc(`${id}`).set({
        title,
        allDay,
        startDate,
        endDate,
        location,
        notes
    }, {merge: true})
}

export function setZoom(uid, zoomNo, zoomPass) {
    return db.collection("users").doc(uid).set({
        state: "teacher",
        zoomNumber: zoomNo,
        zoomPassword: zoomPass
    }, {merge: true})
}

export function removeStudentFromClass(classId,uid) {
    const classRef = db.collection("classes").doc(classId);
    return classRef.update({
        students: firebase.firestore.FieldValue.arrayRemove(db.doc(`users/${uid}`))
    });
}

export function resetPassword(email) {
    return firebase.auth().sendPasswordResetEmail(email)
}

export function getHonorBoardUsers() {
    return db.collection("honorBoard").orderBy("points", "desc").get().then(data => {
        let honorBoardUsers = [];
        
        data.forEach(async (doc) => {
            await getUserByUid(doc.data().uid).then(user => {
                honorBoardUsers.push({
                    ...user.data(),
                    points: doc.data().points
                })
            })
        })
        return honorBoardUsers;
    })
}

export function addUserToHonorBoard(uid,points) {
    return db.collection("honorBoard").doc(uid).set({
        uid,
        points
    });
}

export function deleteUserFromHonorBoard(uid) {
    return db.collection("honorBoard").doc(uid).delete();
}

export function getOurTeachers() {
    return db.collection("homePage").doc("ourTeachers").get().then(async data => {
        const users = data.data().teachers;
        let outTeachers = []
        for (let uid of users) {
            uid && await getUserByUid(uid).then(user => {
                outTeachers.push(user.data())
            })
        }
        return outTeachers
    })
}

export async function addUserToOurTeachers(uid) {
    await db.collection("users").doc(uid).update({
        state: "teacher",
    });
    const classRef = db.collection("homePage").doc("ourTeachers");
    return classRef.update({
        teachers: firebase.firestore.FieldValue.arrayUnion(uid)
    });
}

export async function deleteUserFromOurTeachers(uid) {
    await db.collection("users").doc(uid).update({
        state: "student",
    });
    const classRef = db.collection("homePage").doc("ourTeachers");
    return classRef.update({
        teachers: firebase.firestore.FieldValue.arrayRemove(uid)
    });
}

export async function uploadUserAvatar(uid, file) {

    const imageFile = file;
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 200,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options); // function to resize the image
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  
      const uploadTask = firebase.storage().ref().child(`users/${uid}/${uid}-avatar`).put(compressedFile)
        return await uploadTask
        .then(result => {
            return uploadTask.snapshot.ref.getDownloadURL().then(url => {
                return url
            })
        })
        .catch(error => {
            return new Promise((res, rej) => {
                res({error})
            })
        })
    } catch (error) {
        console.log(error);
    }
  
}

/*
Old functions may be needed :)
export function isUser() {
    return firebase.auth().onAuthStateChanged((user) => {
        // [START_EXCLUDE silent]
        // [END_EXCLUDE]
        if (user) {
            return user
        } else {
            return null
        }
    })
}

function updateName({displayName,avatarURL}) {
    return firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            return user.updateProfile({
                displayName: displayName,
                photoURL: avatarURL
            }).then(() => {
                console.log("Dispaly name and photo url updated")
            })
            .catch((error) => {
                console.log(error)
                return error
            })
        }
    })
}

function updateEmail(email) {
    return firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            return user.updateEmail(email).then(() => {
                console.log("Email updated")
            })
            .catch((error) => {
                console.log(error)
                return error
            })
        }
    })
}

function updateProfile({displayName,email,avatarURL,gender,country,timeZone,aboutMe}) {
    return firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            if (displayName!==user.displayName || avatarURL!==user.photoURL) {
                user.updateProfile({
                    displayName: displayName,
                    photoURL: avatarURL
                }).catch((error) => {
                    console.log(error)
                    return error
                })
            }
            
            if(email!==user.email) {
                return user.updateEmail(email).then(() => {
                    console.log('Profile has been updated')
                    return db.collection("users").doc(user.uid).set({
                        displayName: displayName,
                        email: email,
                        gender: gender,
                        avatarURL: avatarURL,
                        country: country,
                        timeZone: timeZone,
                        aboutMe: aboutMe
                    }).catch((error) => {
                        console.log(error);
                        return error
                    })
                }).catch((error) => {
                    console.log(error)
                    return 
                })
            } else {
                return db.collection("users").doc(user.uid).set({
                    displayName: displayName,
                    gender: gender,
                    avatarURL: avatarURL,
                    country: country,
                    timeZone: timeZone,
                    aboutMe: aboutMe
                })
                .catch((error) => {
                    console.log(error);
                    return error
                })
            }           
        }
    })
}

export function handleImageUpload(uid, file) {
    const uploadTask = firebase.storage().ref().child(`users/${uid}/${uid}-avatar`).put(file)
    return uploadTask
    .then(result => {
        return uploadTask.snapshot.ref.getDownloadURL().then(url => {
            return url
        })
    })
    .catch(error => {
        return new Promise((res, rej) => {
            res({error})
        })
    })
}

export function getHonorBoardUsers() {
    return db.collection("homePage").doc("honorBoard").get().then(async data => {
        const users = data.data().students;
        let honorBoardUsers = []
        for (let uid of users) {
            uid && await getUserByUid(uid).then(user => {
                honorBoardUsers.push(user.data())
            })
        }
        return honorBoardUsers
    })
}
*/
