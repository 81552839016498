import { GET_MY_CLASSES, SUBMIT_HOMEWORK_ANSWER, ADD_HOMEWORK, ADD_CLASS, GET_All_CLASSES, ADD_STUDENT_TO_CLASS, DELETE_CLASS, REMOVE_STUDENT_FROM_CLASS, EDIT_CLASS } from '../actionCreators/classes';

export default function classes (state = null, action) {
    switch (action.type) {
        case GET_MY_CLASSES :
            return action.classes
        case SUBMIT_HOMEWORK_ANSWER :
            state.find(cls => cls.id===action.classId).homeworks.find(hw => hw.id===action.homeworkId).myAnswers=action.studentAnswer
            return [...state]
        case ADD_HOMEWORK :
            action.homework && state.find(cls => cls.id===action.classId).homeworks.push(action.homework)
            return [...state];
        case ADD_CLASS :
            return action.clas===null ? state : state.concat(action.clas);
        case EDIT_CLASS :
            if(action.clas && action.clas!==null && state && state !== null) {
                const index = state.findIndex(cls => cls.id===action.clas.id)
                if(index > -1) {
                    state[index] = {
                        ...state[index],
                        ...action.clas
                    }
                    return [...state]
                }
            }
            return [...state];
        case GET_All_CLASSES :
            return action.classes
        case ADD_STUDENT_TO_CLASS : 
            if (action.student===null || state.find(cls => cls.id===action.classId).students.find(sto => sto.email===action.email)) {
                if(state.find(cls => cls.id===action.classId).students.find(sto => sto.email===action.email)) {
                    action.error = {message: `User with Email" ${action.email}" is already in this class!`}
                }
                return state
            } else {
                state.find(cls => cls.id===action.classId).students.push(action.student)
                return [...state]; 
            }
        case REMOVE_STUDENT_FROM_CLASS : 
            if (action.uid === null || action.classId === null) {
                return state
            } else {
                const students = state.find(cls => cls.id===action.classId).students.filter(st => st.uid !== action.uid)
                state.find(cls => cls.id===action.classId).students = students
                return [...state]; 
            }
        case DELETE_CLASS :
            return action.clas===null ? state : state.filter(clas => clas.id !== action.clas.id)
            
        default :
            return state
    }
} 