// External dependencies
import React from 'react';

// import Header from './../../components/Header';
import ContactusPage from './../../components/ContactusPage';

const Contactus = () => (
        <ContactusPage />
);

export default Contactus;