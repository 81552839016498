import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Alert } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import firebase from "firebase/app";
import "firebase/storage";
import $ from 'jquery'; 

import { handleSubmitUpdateArticle } from '../../../store/actionCreators/articles';

class EditArticle extends Component {
    state = {
        loading: "block",
        title: "",
        author: "",
        content: "",
        created_at: "",
        alertError: false,
        error: ''
    }

    componentDidMount() {
        const { title, created_at, author, content } = this.props.article;
        this.setState({
            title,
            author,
            content,
            created_at
        })
    }

    loaded = value => {
        this.setState(() => ({
            loading: value
        }))
    }

    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    handleEditorChange = (content, editor) => {
        this.setState({ content })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState(() => ({
            alertError: false,
            error: ''
        }))

        const { author,title,content,created_at } = this.state
        const id = this.props.article.id
        if (content ==="") return this.setState(() => ({
            error: 'The Article content can not be empty!',
            alertError: true,
        }))
        this.props.dispatch(handleSubmitUpdateArticle(id,author,title,content,created_at))
        .then((result) => {
            if (result.error===null) {
                // this.props.history.push('/dashboard')
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }

    render() {
        const { title, created_at, author, content } = this.state;
        // console.log(this.state)
        return (
            <React.Fragment>
            <Form onSubmit={this.handleSubmit} style={{textAlign: "center"}}>
                <Form.Group>
                    <Form.Control type="text" name="title" value={title} placeholder="Title" onChange={this.handleChange} required />
                    <span className="required-indicator" role="presentation"></span>
                </Form.Group>
                <Form.Group>
                    <Form.Control type="text" name="author" value={author} placeholder="Author" onChange={this.handleChange} />
                </Form.Group>
                <Form.Group>
                    <Form.Control type="date" name="created_at" value={created_at} placeholder="created at" onChange={this.handleChange} required />
                </Form.Group>
                <img style={{display: this.state.loading}} src="/img/45.svg" alt="loading" />
                <input name="image" type="file" id="upload" style={{display: "none"}} accept="image/*" />
                <Editor
                    apiKey='onq1mfykla6p7ynfc2izz0vjbeoav1qc2ioqq83uv3z2quga'
                    onInit={() => this.loaded("none")}
                    initialValue={content}
                    init={{
                        height: 500,
                        skin: 'snow',
                        menubar: false,
                        paste_data_images: true,
                        image_advtab: true,
                        image_title: true,
                        plugins: [
                            "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                            "searchreplace wordcount visualblocks visualchars code fullscreen",
                            "insertdatetime media nonbreaking save table directionality",
                            "emoticons template paste"
                        ],
                        toolbar1: "undo redo | fontselect fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | forecolor backcolor formatpainter | bullist numlist outdent indent | link image media | emoticons | preview",
                        file_picker_callback: function(callback, value, meta) {
                            if (meta.filetype === 'image') {
                                $('#upload').trigger('click');
                                $('#upload').on('change', () => {
                                    var file = document.getElementById("upload").files[0];
                                    const uploadTask = firebase.storage().ref().child(`articles/${file.name}-${generateUID()}`).put(file);
                                    function generateUID () {
                                        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                                    }
                                    return uploadTask.on('state_changed', snapshot => {}, error => {
                                        if (error) {
                                            console.log("Image could not be uploaded: " + error.code+ ".Try reload the page and try again");
                                        }
                                    }, () => {
                                        uploadTask.snapshot.ref.getDownloadURL().then(function(url) {
                                            callback(url, {alt: file.name}, {title: file.name});
                                        });
                                    });
                                    
                                })
                                
                            }
                        }

                    }}
                    onEditorChange={this.handleEditorChange}
                />
                <Button className="btn btn--primary mt-2" type="submit" >Submit</Button>
            </Form>
            <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
            </React.Fragment>
        )
    }
}
function mapStateToProps ({articles}, props) {
	return {
        article: articles!== null && articles.find(art => art.id === props.match.params.id)
	};
}

export default connect(mapStateToProps)(EditArticle);