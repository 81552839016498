import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { getAllUsers, addTeacher, getUserByUid, setZoom } from '../utils/firebase';

export const GET_USERS = 'GET_USERS';
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';
export const SET_ZOOM = 'SET_ZOOM';

function AllUsers (users, error=null) {
    return {
        type: GET_USERS,
        users,
        error
    }
}

export function handleGetAllUsers() {
    return (dispatch) => {
        dispatch(showLoading())
        
        return getAllUsers()
        .then(users => {
            let result = [];
            users.map(u => {
                let user= {...u}
                
                getUserByUid(u.uid)
                .then(doc => {
                    const { gender, address, aboutMe, country, learn, state, zoomNumber, zoomPassword, certifications } = doc.data();
                    user.gender = gender;
                    user.address = address ? address : "";
                    user.aboutMe = aboutMe ? aboutMe : "";
                    user.country = country ? country : "";
                    user.learn = learn ? learn : [];
                    user.state = state;
                    user.zoomNumber = zoomNumber ? zoomNumber : "";
                    user.zoomPassword = zoomPassword ? zoomPassword : "";
                    user.certifications = certifications ? certifications : [];
                })
                
                return result.push(user)
            })
            dispatch(hideLoading())
            return dispatch(AllUsers(result))
        }).catch(error => {
            return dispatch(AllUsers([], error))
        })
    }
}

function addUserTeacher (uid, zoomNumber, zoomPassword, state, error=null) {
    return {
        type: UPDATE_USER_STATE,
        uid,
        zoomNumber,
        zoomPassword,
        state,
        error
    }
}

export function handleAddUserTeacher(uid, zoomNu, zoomPass) {
    return (dispatch) => {
        return addTeacher(uid, zoomNu, zoomPass)
        .then(() => {
            return dispatch(addUserTeacher(uid, zoomNu, zoomPass, "teacher"))
            
        }).catch(error => {
            return dispatch(addUserTeacher(uid, null, null, null, error))
        })
    }
}

function submitSetZoom (uid, zoomNumber, zoomPassword, error=null) {
    return {
        type: SET_ZOOM,
        uid,
        zoomNumber,
        zoomPassword,
        error
    }
}

export function handleSubmitSetZoom (uid, zoomNo, zoomPass, error=null) {
    return (dispatch) => {
        return setZoom(uid, zoomNo, zoomPass)
        .then(() => {
            return dispatch(submitSetZoom(uid, zoomNo, zoomPass))
        }).catch(error => {
            return dispatch(submitSetZoom(uid, null, null, error))
        })
    }
}