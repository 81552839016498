import React , { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { Spinner } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { Redirect } from 'react-router';

class ArticleDetails extends Component {
    render() {
        // console.log(this.props)
        const { article, t } = this.props;
        if(article === undefined) return <Redirect to="/"></Redirect>
        if (article !== null) {
            return (
                <div className="container">
                    <h1 className="text-center header__content--title mt-4">{article.title}</h1>
                    {article.author!=="" && <p className="lead">{t('articles.by')}: <span>{article.author}</span></p>}
                    <p>{t('articles.Posted on')}: <span id="date">{article.created_at}</span></p>
                    <hr/>
                    <div className="text-justify mb-4">{ReactHtmlParser(article.content)}</div>
                </div>
            )
        } else {
            return (
                <Spinner className="text-center pt-4 m-auto d-block" animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            )
        }
        
    }
}

function mapDispatchToProps ({articles}, props) {
	return {
        article: articles!== null && articles.find(art => art.id === props.id)
	};
}

export default withTranslation()(connect(mapDispatchToProps)(ArticleDetails));