import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { deleteVideo, addVideo } from '../utils/firebase';

export const GET_VIDEOS = 'GET_VIDEOS';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const ADD_VIDEO = 'ADD_VIDEO';

export function getVideos (videos, error=null) {
    return {
        type: GET_VIDEOS,
        videos,
        error
    }
}

function removeVideo (id, error=null) {
    return {
        type: DELETE_VIDEO,
        id,
        error
    }
}

export function handleDeleteVideo(id) {
    return (dispatch) => {
        dispatch(showLoading())
        return deleteVideo(id)
        .then(() => {
            dispatch(hideLoading())
            return dispatch(removeVideo(id))
        }).catch(error => {
            dispatch(hideLoading())
            return dispatch(removeVideo(null,error))
        })
    }
}

function newVideo (video, error=null) {
    return {
        type: ADD_VIDEO,
        video,
        error
    }
}

export function handleAddVideo(src) {
    return (dispatch) => {
        dispatch(showLoading())
        return addVideo(src)
        .then(doc => {
            let vid ={id:doc.id, src}
            dispatch(hideLoading())
            return dispatch(newVideo(vid))
        }).catch(error => {
            dispatch(hideLoading())
            return dispatch(newVideo(null,error))
        })
    }
}

/*
export function handleReceiveVideos () {
    return (dispatch) => {
        dispatch(showLoading())
        return getVideos()
        .then((videos) => {
            let result = [];
			videos.forEach((doc) => {
				let vid = {id: doc.id, ...doc.data()}
				result.push(vid)
			})
			dispatch(hideLoading())
            return dispatch(getArticles(result))
        })
        .catch((error) => {
            dispatch(hideLoading())
            return dispatch(getArticles(null,error))
        })
    }
}
*/