import { GET_USERS, UPDATE_USER_STATE, SET_ZOOM } from '../actionCreators/users';

export default function users (state = null, action) {
    switch (action.type) {
        case GET_USERS :
            return action.users
        case UPDATE_USER_STATE :
            if (action.state===null) {
                return state
            } else {
                state.find(user => user.uid === action.uid).state = action.state
                return state
            }
        case SET_ZOOM :
            if (action.zoomNumber !== null) {
                const user = state.find(u => u.uid===action.uid)
                user.zoomNumber = action.zoomNumber;
                user.zoomPassword = action.zoomPassword
            }
            return state
        default :
            return state
    }
} 