import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Form, Alert } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import { handleSubmitFreeSession } from './../../store/actionCreators/freeSession';
import Timezones from '../../constants/timezone';

const FreeSessionPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        timeZone: '',
        availability: '',
        learn: [],
        whatsapp: '',
        send: true,
    });
    const [error, setError] = useState('');

    useEffect(() => {
        document.title = `${t('home.Free Session')} - ${t('home.Hafs Quran')}`;
    }, [t]);


    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const toggleChecked = e => {
        const value = e.target.value;
        const checked = e.target.checked;

        setFormData(state => ({
            ...state,
            learn: checked ? [...state.learn, value] : state.learn.filter(i => i !== value)
        }));
    }

    const toggleSend = e => {
        const checked = e.target.checked;
        setFormData(state => ({
            ...state,
            send: checked
        }));
    }

    const handleSubmit = event => {
        event.preventDefault();
        setError('');
        dispatch(handleSubmitFreeSession(formData))
        .then((result) => {
            if (result.error === null) {
                history.replace('/free-session-done')
            } else {
                setError(result.error.message);
            }
        })
    }

    return (
        <section className="free-session">
            <div className="row m-0 align-items-stretch">
                <div className="col-lg-6 p-0">
                    <div className="free-session__container">
                        <div className="free-session__title">
                            {t('free.Want to have a free session?')} <br />{t('free.Sign up to be a part of our Quran community.')}
                        </div>
                        <p>{t("free.It's easier than you think!")}</p>
                    </div>
                    <Form onSubmit={handleSubmit} className="free-session__container">
                        <Form.Group controlId="formBasicName">
                            <Form.Label>{t('contact.Name')}</Form.Label>
                            <Form.Text className="text-muted">
                                {t("free.Put the child's name if it is your kid.")}
                            </Form.Text>
                            <div style={{ position: 'relative' }}>
                                <Form.Control 
                                    type="text" 
                                    className="mb-3" 
                                    name="name"
                                    placeholder={t('free.Type your answer')}
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                                <span className="required-indicator" role="presentation"></span>
                            </div>
                            
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>{t('contact.Email address')}</Form.Label>
                            <div style={{ position: 'relative' }}>
                                <Form.Control 
                                    type="text" 
                                    className="mb-3" 
                                    name="email"
                                    placeholder="email@example.com"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                <span className="required-indicator" role="presentation"></span>
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formBasicWhatsapp">
                            <Form.Label>{t('contact.WhatsApp Number')}</Form.Label>
                            <div style={{ position: 'relative' }}>
                                <PhoneInput
                                    international
                                    placeholder="Enter phone number"
                                    value={formData.whatsapp}
                                    onChange={value => setFormData(state => ({ ...state, whatsapp: value }))}
                                    defaultCountry='EG'
                                    className='' style={{ direction: 'ltr' }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formBasicTimezone">
                            <Form.Label>{t('home.Time zone')}</Form.Label>
                            <div style={{ position: 'relative' }}>
                                <Form.Control 
                                    type="text" 
                                    className="mb-3" 
                                    name="timeZone"
                                    placeholder={t('free.Type your answer')}
                                    as="select" 
                                    value={formData.timeZone}
                                    onChange={handleChange}
                                    required
                                >
                                    <option></option>
                                    {Timezones.map(tz => (
                                        <option key={tz.value} value={tz.value}>{tz.label}</option>
                                    ))}
                                </Form.Control>
                                <span className="required-indicator" role="presentation"></span>
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formBasicAvailability">
                            <span className="required-indicator" role="presentation" style={{top: "-5px",bottom: "auto"}}></span>
                            <Form.Label>{t("free.When You're available")}</Form.Label>
                            <Form.Check
                                type="radio"
                                label={t("week.Saturday")}
                                name="availability"
                                value="saturday"
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label={t("week.Sunday")}
                                name="availability"
                                value="sunday"
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label={t("week.Monday")}
                                name="availability"
                                value="monday"
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label={t("week.Tuesday")}
                                name="availability"
                                value="tuesday"
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label={t("week.Wednesday")}
                                name="availability"
                                value="wednesday"
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label={t("week.Thursday")}
                                name="availability"
                                value="thursday"
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label={t("week.Friday")}
                                name="availability"
                                value="friday"
                                className="mb-3"
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicLearn">
                            <Form.Label>{t('free.What do you need to learn?')}</Form.Label>
                            <Form.Check 
                                type='checkbox'
                                name="learn"
                                value='pronunciation'
                                label={t('dashboard.Quran correct pronunciation')}
                                onChange={toggleChecked}
                            />
                            <Form.Check 
                                type='checkbox'
                                name="learn"
                                value='arabic'
                                label={t('home.Arabic language')}
                                onChange={toggleChecked}
                            />
                            <Form.Check 
                                type='checkbox'
                                name="learn"
                                value='memorization'
                                label={t('dashboard.Quran memorization')}
                                onChange={toggleChecked}
                            />
                            <Form.Check 
                                type='checkbox'
                                name="learn"
                                value='tajweed'
                                label={t('home.Tajweed')}
                                className='mb-3'
                                onChange={toggleChecked}
                            />
                            <Form.Label>{t('free.Send Emails to you?')}</Form.Label>
                            <Form.Check
                                type="checkbox"
                                label={t('free.I consent to receive emails')}
                                name="send"
                                defaultChecked
                                className="mb-3"
                                onChange={toggleSend}
                            />
                            <Button type="submit" disabled={formData.learn.length===0} className='btn btn--primary'>
                                Submit
                            </Button>
                            <Alert show={error !== ''} className="text-center m-2" variant='danger'>{error}</Alert>
                        </Form.Group>
                    </Form>
                </div>
                <div className="col-lg-6 p-0">
                    <img src="img/Group 390.png" alt="free session" style={{ minHeight: '100%', objectFit: 'cover' }} />
                </div>
            </div>
        </section>
    )
}

export default FreeSessionPage;