import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { addArticle, updateArticle, deleteArticle } from '../utils/firebase';

export const GET_ALL_ARTICLES = 'GET_ALL_ARTICLES';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const ADD_ARTICLE = 'ADD_ARTICLE';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';

export function getArticles (articles, error=null) {
    return {
        type: GET_ALL_ARTICLES,
        articles,
        error
    }
}

function submitNewArticle(article, error=null) {
	return {
        type: ADD_ARTICLE,
        article,
        error
	};
}

export function handleSubmitNewArticle(author,title,content,created_at) {
    return (dispatch) => {
        dispatch(showLoading())
        return addArticle(author,title,content,created_at)
        .then(arti => {
            let article = {
                id: arti.id,
                author,
                title,
                content,
                created_at,
            }
            dispatch(hideLoading())
            return dispatch(submitNewArticle(article))
        }).catch((error) => {
            console.error("Error adding document: ", error);
            dispatch(hideLoading())
            return dispatch(submitNewArticle(null,error))
        })
    } 
}

function submitUpdateArticle(article, error=null) {
	return {
        type: UPDATE_ARTICLE,
        article,
        error
	};
}

export function handleSubmitUpdateArticle(id,author,title,content,created_at) {
    return (dispatch) => {
        dispatch(showLoading())
        return updateArticle(id,author,title,content,created_at)
        .then(() => {
            let article = {
                id,
                author,
                title,
                content,
                created_at
            }
            dispatch(hideLoading())
            return dispatch(submitUpdateArticle(article))
        }).catch((error) => {
            dispatch(hideLoading())
            return dispatch(submitUpdateArticle(null,error))
        })
    } 
}

function submitDeleteArticle(id, error=null) {
	return {
        type: DELETE_ARTICLE,
        id,
        error
	};
}

export function handleSubmitDeleteArticle(id) {
    return (dispatch) => {
        dispatch(showLoading())
        return deleteArticle(id)
        .then(() => {
            dispatch(hideLoading())
            return dispatch(submitDeleteArticle(id))
        }).catch((error) => {
            dispatch(hideLoading())
            return dispatch(submitDeleteArticle(null,error))
        })
    } 
}

/*
export function handleReceiveArticles () {
    return (dispatch) => {
        dispatch(showLoading())
        return getAllArticles()
        .then((articles) => {
            let result = [];
			articles.forEach((doc) => {
				let artcl = {id: doc.id, ...doc.data()}
				result.push(artcl)
			})
			dispatch(hideLoading())
            return dispatch(getArticles(result))
        })
        .catch((error) => {
            dispatch(hideLoading())
            return dispatch(getArticles(null,error))
        })
    }
}

function uploadImage (result, error=null) {
    return {
        type: UPLOAD_IMAGE,
        result,
        error
    }
}

export function handleUploadArticleImage (file) {
    return (dispatch) => { 
        const uploadTask = firebase.storage().ref().child(`articles/${file.name}-${generateUID()}`).put(file);
        return new Promise((res, rej) => {
            var result = {};
            return uploadTask.on('state_changed', snapshot => {}, error => {
                if (error) {
                    console.log("Image could not be uploaded: " + error.code+ ".Try reload the page and try again");
                    result.error = error
                    dispatch(uploadImage(null, error))
                    return res({result: null, error: {...error}})
                }
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then(function(url) {
                    result.url = url
                    result.fileName = file.name
                    dispatch(uploadImage(result))
                    return res(result)
                });
            }); 
        })
    }
}
*/
