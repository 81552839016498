import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Component from './modals/Signup/Component';

const SignupPage = () => {
    const { t } = useTranslation();
    const authedUser = useSelector(state => state.authedUser);

    useEffect(() => {
        document.title = `${t('auth.Sign up as student')} - ${t('home.Hafs Quran')}`;
    }, [t]);

    if(authedUser!==null) {
        return <Redirect to="/dashboard"></Redirect>
    }
    return (
        <div className="articles__wrapper">
            <div className="main-hafs-header signup-header">
                <div className="hafs-header-overlay">
                    <div className="hafs-header-content">
                        <h1>{t('auth.Sign up as student')}</h1>
                    </div>
                </div>
            </div>
            <Component />
        </div>  
    )
}

export default SignupPage;