import React , { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Modal, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { handleSubmitAddStudentToClass, handleDeleteClass, handleSubmitRemoveStudentFromClass } from '../../store/actionCreators/classes';

class Classes extends Component {
    state = {
        error: "",
        alertError: false,
        inputs: {},
        students: {},
        openDeleteClassModal: null,
        openDeleteStudentModal: null
    }

    typeTitle = type => {
        switch (type) {
            case "tajweed" :
                return "Tajweed"
            case "memorization" :
                return "Quran memorization"
            case "arabic" :
                return "Arabic Language"
            case "pronunciation" :
                return "Quran correct pronunciation"
            default :
                return ""
        }
    }

    formatDate = time => {
        const date = time.toDate();
        return (date.toLocaleString('default', { month: 'long' })) + " " + date.getDate()+ ", " +  date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
    }

    handleSearch = (e,index) => {
        this.setState(() => ({
            alertError: false,
            error: "",
        }))
        let { inputs, students } = this.state;
        inputs[index] = e.target.value.toLowerCase();
        this.setState({ inputs });
        const filteredData = this.props.users.filter(value => {
            const searchStr = this.state.inputs[index];
            const nameMatches = value.email.toLowerCase().includes(searchStr);
            const daysMatches = value.displayName && value.displayName.toString().includes(searchStr);
        
            return nameMatches || daysMatches;
        });
        // console.log(filteredData);
        if (inputs[index] === "") {
            students[index] = []
        } else {
            students[index] = filteredData
            this.setState({ students });
            this.renderSearchList(index)
        }
    }

    chooseStudent = (index,email) => {
        let { inputs, students } = this.state;
        students[index] = []
        inputs[index] = email;
        this.setState({ inputs,students });
    }

    renderSearchList = (index) => {
        return this.state.students[index] && this.state.students[index].map(stu => {
            return (
                <div key={stu.uid} className="upc-st-box" onClick={() => this.chooseStudent(index,stu.email)}>
                    <div className="upc-st-info">
                        <div className="st-vector">
                            <img className="img-fluid" src={stu.photoURL} alt="Student Vector"/>
                        </div>
                        <div className="upc-details">
                            <h5 className="mb-0">
                                {stu.displayName}<br/>
                                <small>{stu.email}</small>
                            </h5>
                        </div>
                    </div>
                </div>
            )
        })
    }

    handleSubmit = (e,classId,index) => {
        e.preventDefault();
        this.setState(() => ({
            alertError: false,
            error: "",
        }))
        const email = this.state.inputs[index]
        this.props.dispatch(handleSubmitAddStudentToClass(classId,email))
        .then((result) => {
            if (result.error===null) {
                let { inputs, students } = this.state;
                students[index] = []
                inputs[index] = "";
                this.setState({ inputs,students });
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
                window.scrollTo({top: 0, behavior: 'smooth'}) 
            }
        })
    }

    setOpenDeleteClassModal = value => this.setState({openDeleteClassModal: value})
    setOpenDeleteStudentModal = value => this.setState({openDeleteStudentModal: value})

    render() {
        // console.log(this.props)
        return (
            <React.Fragment>
                <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                <div className="dsh-head-with-btn">
                    <Link to="/admin/new-class" className="main-btn btn--primary mb-2">Add New Clas</Link> 
                </div>
                <hr />
                {this.props.classes && this.props.classes.map(clas =>
                    <div className="upcoming-classes" key={clas.id}>
                        <Button variant="danger" className="float-right" onClick={() => this.setOpenDeleteClassModal(clas.id)}>Delete Class</Button>
                        <Link to={`/admin/classes/${clas.id}`} className="main-btn btn--primary mr-2 float-right">Edit</Link>
                        <h1 className="st-name">{clas.title}</h1>
                        <p>
                            <span className="sc-type">{this.typeTitle(clas.learn)}</span>
                            <span className="cls-start">Class start at </span>
                            <span className="cls-date">{this.formatDate(clas.start_at)}</span>
                        </p>
                        <p>
                            <span className="cls-start">Teacher </span>
                            <span className="cls-date mr-4">{clas.teacher.displayName}</span>
                            <span className="cls-date mr-4">{clas.teacher.email}</span>
                            <Link to="#" className="main-icon-btn mr-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                    <path data-name="Path 2107" d="M14.525,0H5.456A5.533,5.533,0,0,0,0,5.859v6.282A5.533,5.533,0,0,0,5.456,18h9.061a5.522,5.522,0,0,0,4-1.773A5.6,5.6,0,0,0,19.989,12.1V5.859A5.557,5.557,0,0,0,14.525,0ZM5.456,1.528h9.052A4,4,0,0,1,17.4,2.816a4.064,4.064,0,0,1,1.072,3v6.327A4.1,4.1,0,0,1,17.4,15.184a4,4,0,0,1-2.894,1.287H5.456a4.018,4.018,0,0,1-3.939-4.331V5.859A4.018,4.018,0,0,1,5.456,1.528Zm10.568,4.11a.755.755,0,0,0-1.066-.119l-4.1,3.3-.118.083A1.379,1.379,0,0,1,9.16,8.818l-4.129-3.3-.093-.064a.755.755,0,0,0-.973.187.768.768,0,0,0,.123,1.074l4.134,3.3.161.118a2.889,2.889,0,0,0,3.416-.114l4.107-3.311.082-.077A.768.768,0,0,0,16.024,5.638Z" fill="#fff" fillRule="evenodd"/>
                                </svg>
                            </Link>
                        </p>
                        <Form className="input-group mt-2" onSubmit={(e) => this.handleSubmit(e,clas.id,this.props.classes.indexOf(clas))}>
                            <Form.Control type="email" className="form-control" value={this.state.inputs[this.props.classes.indexOf(clas)] || ""} name="search" placeholder="Search users by Email or Name to add to this class" aria-label="Search" aria-describedby="basic-addon2" onChange={(e) => this.handleSearch(e,this.props.classes.indexOf(clas))} />
                            <Form.Group className="input-group-append">
                                <button type="submit" className="input-group-text">Add</button>
                            </Form.Group>
                            <div className="list">
                                {this.renderSearchList(this.props.classes.indexOf(clas))}
                            </div>
                        </Form>
                        <span className="cls-start">Students</span>
                        {clas.students.filter(s => s).map(stu => 
                            <div key={stu.uid} className="upc-st-box">
                                <div className="upc-st-info">
                                    <div className="st-vector">
                                        <img className="img-fluid" src={stu.photoURL} alt="Student Vector"/>
                                    </div>
                                    <div className="upc-details">
                                        <h5 className="mb-0">
                                            {stu.displayName}
                                        </h5>
                                    </div>
                                </div>
                                <div className="upc-actions">
                                    <Link to="#" className="main-icon-btn mr-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                            <path data-name="Path 2107" d="M14.525,0H5.456A5.533,5.533,0,0,0,0,5.859v6.282A5.533,5.533,0,0,0,5.456,18h9.061a5.522,5.522,0,0,0,4-1.773A5.6,5.6,0,0,0,19.989,12.1V5.859A5.557,5.557,0,0,0,14.525,0ZM5.456,1.528h9.052A4,4,0,0,1,17.4,2.816a4.064,4.064,0,0,1,1.072,3v6.327A4.1,4.1,0,0,1,17.4,15.184a4,4,0,0,1-2.894,1.287H5.456a4.018,4.018,0,0,1-3.939-4.331V5.859A4.018,4.018,0,0,1,5.456,1.528Zm10.568,4.11a.755.755,0,0,0-1.066-.119l-4.1,3.3-.118.083A1.379,1.379,0,0,1,9.16,8.818l-4.129-3.3-.093-.064a.755.755,0,0,0-.973.187.768.768,0,0,0,.123,1.074l4.134,3.3.161.118a2.889,2.889,0,0,0,3.416-.114l4.107-3.311.082-.077A.768.768,0,0,0,16.024,5.638Z" fill="#fff" fillRule="evenodd"/>
                                        </svg>
                                    </Link>
                                    <Button variant="danger" size="sm" className="" onClick={() => this.setOpenDeleteStudentModal(clas.id+stu.uid)}>Delete Student</Button>
                                </div>
                                
                            <DeleteStudentModal
                                clas={clas}
                                student={stu}
                                deleteStudentModal={this.state.openDeleteStudentModal===clas.id+stu.uid}
                                closeDeleteStudentModal={() => this.setOpenDeleteStudentModal(null)}
                                dispatch={this.props.dispatch}
                            />
                            </div>
                        )} <hr/>
                        <DeleteClassModal
                            clas={clas}
                            deleteClassModal={this.state.openDeleteClassModal===clas.id}
                            closeDeleteClassModal={() => this.setOpenDeleteClassModal(null)}
                            dispatch={this.props.dispatch}
                        />
                    </div>
                )}
            </React.Fragment>
        )
    }
}

function mapDispatchToProps ({users,classes}) {
	return {
		users,
        classes
	};
}

export default connect(mapDispatchToProps)(Classes);

class DeleteClassModal extends Component {
    state = {
        error: '',
        alertError: false
    }

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeDeleteClassModal()
    }

    handleDelete = (clas) => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.dispatch(handleDeleteClass(clas))
        .then((result) => {
            if (result.error===null) {
                this.props.closeDeleteModal()
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }
  
    render() {
        // console.log(this.props)
        const { deleteClassModal, clas } = this.props;
        return (
            <Modal
                show={deleteClassModal}
                onHide={this.closeModal}
                aria-labelledby="delete-modal"
                className="signup"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Delete "{clas.title}" Class?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <Alert variant="warning">Are you sure you want to delete "{clas.title}" Class?</Alert>
                    <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Cancel</Button>
                    <Button variant="danger" onClick={() => this.handleDelete(clas)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

class DeleteStudentModal extends Component {
    state = {
        error: '',
        alertError: false
    }

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeDeleteStudentModal()
    }

    handleDelete = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        const { clas, student, closeDeleteStudentModal } = this.props;
        this.props.dispatch(handleSubmitRemoveStudentFromClass(clas.id, student.uid))
        .then((result) => {
            if (result.error===null) {
                closeDeleteStudentModal()
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }
  
    render() {
        // console.log(this.props)
        const { deleteStudentModal, clas, student } = this.props;
        return (
            <Modal
                show={deleteStudentModal}
                onHide={this.closeModal}
                aria-labelledby="delete-modal"
                className="signup"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Delete "{student.displayName}" ?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4><Alert variant="warning">Are you sure you want to delete "{student.displayName}" from Class "{clas.title}"?</Alert></h4>
                    <Alert show={this.state.alertError} className="m-2" variant='danger'>{this.state.error}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Cancel</Button>
                    <Button variant="danger" onClick={this.handleDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
