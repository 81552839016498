import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Button, Alert, Modal } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faVolumeUp, faVideo } from '@fortawesome/free-solid-svg-icons';

import Timezones from '../../../constants/timezone';
import Countries from '../../../constants/countries';

import { handleUpdateProfile, handleSubmitUploadCertification, handleSubmitDeleteCertification } from '../../../store/actionCreators/authedUser';

class ProfilePage extends Component {
    state = {
        validated: false,
        displayName: '',
        email: '',
        password: '',
        gender: '',
        country: '',
        timeZone: '',
        avatarURL: '',
        avatar: "",
        avatarD: "",
        aboutMe: '',
        address: '',
        alertError: false,
        alertSuccess: false,
        error: '',
        alertUploadError: false,
        uploadError: '',
        learn: [],
        certifications: [],
        openCertificationModal: null
    }

    componentDidMount() {
        if(this.props.authedUser !== null) {
            this.setState({
                displayName: this.props.authedUser.displayName,
                email: this.props.authedUser.email,
                gender: this.props.authedUser.gender,
                country: this.props.authedUser.country,
                timeZone: this.props.authedUser.timeZone,
                avatarURL: this.props.authedUser.photoURL,
                aboutMe: this.props.authedUser.aboutMe,
                learn: this.props.authedUser.learn === undefined ? [] : this.props.authedUser.learn,
                certifications: this.props.authedUser.certifications === undefined ? [] : this.props.authedUser.certifications,
            }); 
        }
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render   
        if (nextProps.authedUser !== null) {
            this.setState({
                displayName: nextProps.authedUser.displayName,
                email: nextProps.authedUser.email,
                gender: nextProps.authedUser.gender,
                country: nextProps.authedUser.country,
                timeZone: nextProps.authedUser.timeZone,
                avatarURL: nextProps.authedUser.photoURL,
                aboutMe: nextProps.authedUser.aboutMe,
                learn: nextProps.authedUser.learn === undefined ? [] : nextProps.authedUser.learn,
                certifications: nextProps.authedUser.certifications === undefined ? [] : nextProps.authedUser.certifications,
            });   
        }   
    }

    setAvatarURL = value => this.setState({
        avatarURL: value,
        avatarD: "",
        avatar: ""
    })

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            this.setState({
                avatar: event.target.files[0],
                avatarURL: ""
            })
            reader.onload = (e) => {
                this.setState({avatarD: e.target.result})
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    toggleChecked = e => {
        const value = e.target.value;
        const checked = e.target.checked;

        if(checked) {
            this.setState(() => ({
                learn: [...this.state.learn, value]
            }))
        } else {
            let learn = this.state.learn
            let newLearn = learn.filter(i => i!==value)
            this.setState(() => ({
                learn: newLearn
            }))
        }
    }

    upload = e => {
        this.setState({alertUploadError: false, uploadError: ""})
        const file = e.target.files[0];
        // console.log(file)
        this.props.dispatch(handleSubmitUploadCertification(file))
        .then((result) => {
            if(result.error === null) {
                this.setState({alertSuccess: true})
            } else {
                this.setState({uploadError: result.error.customData.serverResponse, alertUploadError: true})
            }
        })
    }

    setOpenCertificationModal = value => this.setState({openCertificationModal: value})

    handleSubmit = event => {
        event.preventDefault();
        this.setState({alertSuccess: false, alertError: false, error: ""})
        event.stopPropagation();
        this.setState({validated: true})
        const {displayName, email, gender, timeZone, country, aboutMe, learn, address} = this.state;
        const avatarURL = this.state.avatar === "" ? this.state.avatarURL : this.state.avatar
        // console.log(displayName,email,gender,country,timeZone,avatarURL,aboutMe)
        this.props.dispatch(handleUpdateProfile({displayName,email,avatarURL,gender,country,timeZone,aboutMe,learn, address}))
        .then((result) => {
            if(result.error === null) {
                this.setState({alertSuccess: true})
            } else {
                this.setState({error: result.error.message, alertError: true})
                window.scrollTo({top: 0, behavior: 'smooth'})
            }
        }) 
    }

    render() {
        // console.log(typeof(this.state.avatar))
        const { t } = this.props;
        const {displayName, email, gender, timeZone, country, aboutMe, avatarURL, learn, address, certifications, alertSuccess, alertError, error, alertUploadError, uploadError, avatarD} = this.state;
        return (
            <div className="dashboard-content-page p-0">      
                <div className="profile__main">       
                    <h2 className='mb-5'>Profile</h2>
                    <Alert show={alertSuccess} className="m-2" variant='success'>Saved</Alert>
                    <Alert show={alertError} className="m-2" variant='danger'>{error}</Alert>
                    <Form noValidate onSubmit={this.handleSubmit}>
                        <div className="avatar-group">
                        <h3>{t('dashboard.Your Avatar')}</h3>
                            <div className="row align-items-center justify-content-between">
                                <div className="col-md-6">
                                    {(gender) === 'male' && (
                                        <div className="avatars__group">
                                            <div 
                                                className={(avatarURL==='/img/av1.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='1'
                                                onClick={()=> this.setAvatarURL('/img/av1.svg')}
                                            >
                                                <img src="/img/av1.svg" alt="avatar 1"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/av2.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='2'
                                                onClick={()=> this.setAvatarURL('/img/av2.svg')}
                                            >
                                                <img src="/img/av2.svg" alt="avatar 2"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/av3.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='3'
                                                onClick={()=> this.setAvatarURL('/img/av3.svg')}
                                            >
                                                <img src="/img/av3.svg" alt="avatar 3"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/av4.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='4'
                                                onClick={()=> this.setAvatarURL('/img/av4.svg')}
                                            >
                                                <img src="/img/av4.svg" alt="avatar 4"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/av5.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='5'
                                                onClick={()=> this.setAvatarURL('/img/av5.svg')}
                                            >
                                                <img src="/img/av5.svg" alt="avatar 5"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/av6.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='6'
                                                onClick={()=> this.setAvatarURL('/img/av6.svg')}
                                            >
                                                <img src="/img/av6.svg" alt="avatar 6"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/av7.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='7'
                                                onClick={()=> this.setAvatarURL('/img/av7.svg')}
                                            >
                                                <img src="/img/av7.svg" alt="avatar 7"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/av8.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='8'
                                                onClick={()=> this.setAvatarURL('/img/av8.svg')}
                                            >
                                                <img src="/img/av8.svg" alt="avatar 8"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/av9.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='9'
                                                onClick={()=> this.setAvatarURL('/img/av9.svg')}
                                            >
                                                <img src="/img/av9.svg" alt="avatar 9"/>
                                            </div>
                                        </div>
                                    )}
                                    {(gender) === 'female' && (
                                        <div className="avatars__group">
                                            <div 
                                                className={(avatarURL==='/img/f-av1.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='1'
                                                onClick={()=> this.setAvatarURL('/img/f-av1.svg')}
                                            >
                                                <img src="/img/f-av1.svg" alt="avatar 1"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/f-av2.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='2'
                                                onClick={()=> this.setAvatarURL('/img/f-av2.svg')}
                                            >
                                                <img src="/img/f-av2.svg" alt="avatar 2"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/f-av3.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='3'
                                                onClick={()=> this.setAvatarURL('/img/f-av3.svg')}
                                            >
                                                <img src="/img/f-av3.svg" alt="avatar 3"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/f-av4.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='4'
                                                onClick={()=> this.setAvatarURL('/img/f-av4.svg')}
                                            >
                                                <img src="/img/f-av4.svg" alt="avatar 4"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/f-av5.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='5'
                                                onClick={()=> this.setAvatarURL('/img/f-av5.svg')}
                                            >
                                                <img src="/img/f-av5.svg" alt="avatar 5"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/f-av6.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='6'
                                                onClick={()=> this.setAvatarURL('/img/f-av6.svg')}
                                            >
                                                <img src="/img/f-av6.svg" alt="avatar 6"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/f-av7.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='7'
                                                onClick={()=> this.setAvatarURL('/img/f-av7.svg')}
                                            >
                                                <img src="/img/f-av7.svg" alt="avatar 7"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/f-av8.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='8'
                                                onClick={()=> this.setAvatarURL('/img/f-av8.svg')}
                                            >
                                                <img src="/img/f-av8.svg" alt="avatar 8"/>
                                            </div>
                                            <div 
                                                className={(avatarURL==='/img/f-av9.svg') ? "avatars__group--icon selected":"avatars__group--icon"}
                                                id='9'
                                                onClick={()=> this.setAvatarURL('/img/f-av9.svg')}
                                            >
                                                <img src="/img/f-av9.svg" alt="avatar 9"/>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-6 text-center upload-image">
                                    <h6 className='mt-4'>{t('dashboard.Or Upluad your image')}:</h6>
                                    
                                    <div className="">
                                        <img src={avatarD === "" ? avatarURL : avatarD} alt="avatar"/>
                                    </div>
                                    
                                    <Form.Group className="abs-btn">
                                        <label className="main-btn border-btn-primary">
                                            <input type="file" className="d-none" name="image" accept="image/*" onChange={this.onImageChange} />
                                            {t('dashboard.Upload Image')}
                                        </label>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        <div className="info-group">
                            <h3>{t('dashboard.Personal Info')}</h3>
                            <Form.Group controlId="formBasicName">
                                <Form.Control 
                                    type="text" 
                                    placeholder={t('contact.Name')}
                                    required 
                                    value={displayName}
                                    name="displayName"
                                    onChange={this.handleChange}
                                    isInvalid={this.state.validated && displayName===''} 
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('auth.Please enter your name.')}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control 
                                    type="email" 
                                    name="email"
                                    placeholder={t('contact.Email address')}
                                    value={email} 
                                    onChange={this.handleChange}
                                    isInvalid={this.state.validated && email===''} 
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('auth.Please enter your email.')}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Control 
                                    type="password" 
                                    name="password"
                                    placeholder={t('auth.Password')}
                                    // isInvalid={this.state.validated && password===''}  
                                    // value={password}
                                    onChange={this.handleChange}
                                    disabled
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('auth.Please enter your password')}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formBasicGender">
                                <Form.Label className='mr-5'>{t('auth.Gender')}</Form.Label>
                                <Form.Check
                                    type="radio"
                                    label={t('auth.Male')}
                                    name="gender"
                                    value="male"
                                    className="mr-5"
                                    id="male"
                                    isInvalid={this.state.validated && gender===''}  
                                    onChange={this.handleChange}
                                    inline
                                    checked={gender==='male'}
                                />
                                <Form.Check
                                    type="radio"
                                    name="gender"
                                    label={t('auth.Female')}
                                    value="female"
                                    id="female"
                                    isInvalid={this.state.validated && gender===''}  
                                    onChange={this.handleChange}
                                    inline
                                    checked={gender==='female'}
                                />
                            </Form.Group>
                        </div>
                        <div className="location-group">
                            <h3>{t('dashboard.Location')}</h3>
                            <Form.Group controlId="formBasicCountry">
                                <Form.Control 
                                    type="text" 
                                    name="country"
                                    className="mb-3" 
                                    placeholder={t('dashboard.Country')} 
                                    as="select" 
                                    value={country}
                                    onChange={this.handleChange}
                                    required
                                >
                                    <option></option>
                                    {Countries.map( c => (
                                        <option key={c.code} value={c.code}>{c.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formBasicTimezone">
                                <Form.Control 
                                    type="text" 
                                    name="timeZone"
                                    className="mb-3" 
                                    placeholder={t('home.Time zone')} 
                                    as="select" 
                                    value={timeZone}
                                    onChange={this.handleChange}
                                    required
                                >
                                    <option></option>
                                    {Timezones.map( tz => (
                                        <option key={tz.value} value={tz.value}>{tz.label}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formBasicAddress">
                                <Form.Control 
                                    type="text" 
                                    placeholder={t('dashboard.Address')} 
                                    required 
                                    value={address}
                                    name="address"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </div>
                        <div className="teaching-info">
                            {(this.props.authedUser && this.props.authedUser.state==='teacher')
                                ? <h3>{t('dashboard.Teaching Info')}</h3>
                                : <h3>{t('dashboard.Learning Info')}</h3>
                            }
                            <Form.Group>
                                {(this.props.authedUser && this.props.authedUser.state==='teacher')
                                    ? <Form.Label>{t('home.I Can Teach')}: </Form.Label>
                                    : <Form.Label>{t('home.I want to learn')}: </Form.Label>
                                }
                                <div>
                                    <Form.Check 
                                        name="learn"
                                        type='checkbox'
                                        value='pronunciation'
                                        label={t('dashboard.Quran correct pronunciation')}
                                        onChange={this.toggleChecked}
                                        checked={learn.includes("pronunciation")}
                                    />
                                    <Form.Check 
                                        name="learn"
                                        type='checkbox'
                                        value='arabic'
                                        label={t('home.Arabic Language')}
                                        onChange={this.toggleChecked}
                                        checked={learn.includes("arabic")}
                                    />
                                    <Form.Check
                                        name="learn"
                                        type='checkbox'
                                        value='memorization'
                                        label={t('dashboard.Quran memorization')}
                                        onChange={this.toggleChecked}
                                        checked={learn.includes("memorization")}
                                    />
                                    <Form.Check 
                                        name="learn"
                                        type='checkbox'
                                        value='tajweed'
                                        label={t('home.Tajweed')}
                                        onChange={this.toggleChecked}
                                        checked={learn.includes("tajweed")}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                            
                        {(this.props.authedUser && this.props.authedUser.state==='teacher' && (
                            <React.Fragment>
                            {/* <div className="recitation-sample">
                                <h3>Recitation Sample</h3>
                                <p>You didn't add recitation yet , you can upload audio or video</p>
                                <div className="recitation-sample__new mr-3">
                                    <FontAwesomeIcon icon={faVolumeUp}></FontAwesomeIcon>
                                    <span>Audio</span>
                                </div>
                                <div className="recitation-sample__new">
                                    <FontAwesomeIcon icon={faVideo}></FontAwesomeIcon>
                                    <span>Video</span>
                                </div>
                            </div> */}
                            <div className="certifications">
                                <h3>{t("dashboard.Certifications")}</h3>
                                {!certifications.length && <p>{t("dashboard.You didn't add certifications yet")}</p>}
                                
                                <div className="">
                                    <label htmlFor="file-input">
                                        <img src="/img/cert.svg" alt="upload certification" />
                                    </label>
                                    <input name="certification" id="file-input" style={{display: "none"}} type="file" accept="image/*" onChange={this.upload} />
                                </div>
                                <div className="gallery">
                                    {certifications.map(cert => 
                                        <React.Fragment key={cert}>
                                        <button onClick={() => this.setOpenCertificationModal(cert)}>
                                            <img src={cert} alt="certification" />
                                        </button>
                                        <CertificationModal
                                            certification={cert}
                                            certificationModal={this.state.openCertificationModal===cert}
                                            closeCertificationModal={() => this.setOpenCertificationModal(null)}
                                            dispatch={this.props.dispatch}
                                        />
                                        </React.Fragment>
                                    )}
                                    <Alert show={alertUploadError} className="m-2" variant='danger'>{uploadError}</Alert>
                                </div>
                            </div>
                            </React.Fragment>
                        ))}
                        
                        
                        <div className="about-me">
                            <h3>{t('dashboard.About me')}</h3>
                            <Form.Group controlId="formBasicAboutme">
                                <Form.Control 
                                    as="textarea" 
                                    name="aboutMe"
                                    placeholder={t('dashboard.About me')}
                                    rows={5} 
                                    value={aboutMe}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </div>
                        <Button type="submit" className="btn btn--primary">{t('dashboard.Save Changes')}</Button>
                    </Form>
                    <Alert show={alertSuccess} className="m-2" variant='success'>Saved</Alert>
                    <Alert show={alertError} className="m-2" variant='danger'>{error}</Alert>
                </div>
            </div>
        )
    }
}


function mapStateToProps ({ authedUser }) {
	return {
		authedUser: authedUser
	}
}

export default withTranslation()(connect(mapStateToProps)(ProfilePage));

class CertificationModal extends Component {
    state = {
        error: '',
        alertError: false
    }
  
    handleChange = (e) => {
        this.setState(() => ({
            [e.target.name]: e.target.value
        }))
    }

    closeModal = () => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.closeCertificationModal()
    }

    handleDeleteCertification = url => {
        this.setState(() => ({
            alertError: false,
            error: ''
        }))
        this.props.dispatch(handleSubmitDeleteCertification(url))
        .then((result) => {
            if (result.error===null) {
                this.props.closeCertificationModal()
            } else {
                this.setState(() => ({
                    error: result.error.message,
                    alertError: true,
                }))
            }
        })
    }
  
    render() {
        // console.log(this.props)
        const { certificationModal, certification } = this.props;
        return (
            <Modal
                show={certificationModal}
                onHide={this.closeModal}
                aria-labelledby="certification-modal"
                className="text-center signup"
                size="xl"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <img src={certification} alt="certification" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Close</Button>
                    <Button variant="danger" onClick={() => this.handleDeleteCertification(certification)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
