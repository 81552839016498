import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ZoomMtg } from '@zoom/meetingsdk';


const ZoomMeeting = (props) => {
  const { password } = useParams();
  	const clas = props.classes && props.classes.find(cls => cls.id === props.match.params.classId);

    const fetchZoom = useCallback(async() => {
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();
      // loads language files, also passes any error messages to the ui
      ZoomMtg.i18n.load('en-US');

      ZoomMtg.generateSDKSignature({
        meetingNumber: clas!==null && clas.teacher.zoomNumber,
        sdkKey: process.env.REACT_APP_ZOOM_API_KEY,
        sdkSecret: process.env.REACT_APP_ZOOM_API_SECRET,
        role: Number(props.authedUser && props.authedUser.state ==="teacher"),
        success: (signature) => {
          console.log('signature', signature)
          ZoomMtg.init({
            leaveUrl: '/dashboard',
            isSupportAV: true,
            patchJsMedia: true,
            disableInvite: !(props.authedUser && props.authedUser.state ==="teacher"),
            // disableInvite: true,
            success: (success) => {
              console.log('init success', success)
              document.getElementById('zmmtg-root').style.display = 'block';
              ZoomMtg.join({
                signature: signature,
                meetingNumber: clas!==null && clas.teacher.zoomNumber,
                userName: props && props.authedUser.displayName,
                sdkKey: process.env.REACT_APP_ZOOM_API_KEY,
                userEmail: clas!==null && clas.teacher.email,
                passWord: password,
                tk: '',
                zak: '',
                success: (success) => {
                  console.log('join success', success)
                },
                error: (error) => {
                  console.log('join error', error)
                }
              })
            },
            error: (error) => {
              console.log('init error', error)
            }
          })
        },
        error: (error) => {
          console.log('generateSignature error', error)
        }
      });
    }, [clas, password, props])

    useEffect(() => {
      fetchZoom();
    }, [fetchZoom]);

		return (
			<></>
    )
}

function mapStateToProps ({ classes, authedUser }) {
	return {
    	classes, authedUser
	}
}

export default connect(mapStateToProps)(ZoomMeeting);