import React from 'react';

const Games = () => (
    <section className="games">
        <h2 className="games__title">
            games
        </h2>
        <div className="games__container">
            <img src="img/Group 107.svg" alt="games"/>
            <p>We didn't add any game yet <br />Wait our games SOON !</p>
        </div>
    </section>
)

export default Games;